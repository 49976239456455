import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import FontFaceObserver from 'fontfaceobserver';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from 'app';
import { ErrorFallbackPage } from 'app/containers/ErrorFallbackPage';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';

// material-ui
import { SENTRY_DSN, SENTRY_ENV, USE_MOCK } from 'utils/constants';

// sentry
import * as Sentry from '@sentry/react';

import 'sanitize.css';

import { createBrowserHistory } from 'history';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const segoeObserver = new FontFaceObserver('Segoe UI Regular', {});

// When Inter is loaded, add a font-family using Inter to the body
segoeObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

if (!USE_MOCK) {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: ['Non-Error promise rejection captured'],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={() => <ErrorFallbackPage />}>
    <Provider store={store}>
      <HelmetProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </HelmetProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
