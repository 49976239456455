/**
 *
 * Asynchronously loads the component for RootThirdParty
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RootThirdParty = lazyLoad(
  () => import('./index'),
  module => module.RootThirdParty,
);
