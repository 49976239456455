/**
 *
 * IconAside
 *
 */
import { PageName } from 'types/user';
import { AsideRiskBoard } from 'app/components/Icons/svg/AsideRiskBoard';
import { AsideThirdParty } from 'app/components/Icons/svg/AsideThirdParty';
import { AsideAggregationRisk } from 'app/components/Icons/svg/AsideAggregationRisk';

interface Props {
  icon: PageName;
}

export function IconAside(props: Props) {
  switch (props.icon) {
    case 'risk_board':
      return <AsideRiskBoard />;
    case 'third_party':
      return <AsideThirdParty />;
    case 'aggregation_risk':
      return <AsideAggregationRisk />;
    default:
      return null;
  }
}
