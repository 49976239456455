/**
 *
 * IconStatus
 *
 */
import React from 'react';

interface Props {
  icon: string;
}

export function IconStatus(props: Props) {
  const { icon } = props;
  switch (icon) {
    case 'Compliant':
    case 'COMPLETED':
      return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="url(#paint0_linear)" />
          <path d="M6 9.75L8.5 12l4-6" stroke="#fff" />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="4.683"
              y1="-.116"
              x2="10.996"
              y2="19.012"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#108596" />
              <stop offset="1" stopColor="#158A6D" />
            </linearGradient>
          </defs>
        </svg>
      );
    case 'STARTED':
      return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#827F81" />
          <path d="M9 4v6.75L12 13" stroke="#fff" />
        </svg>
      );
    case 'FAILED':
      return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#BF0330" />
          <path
            d="M9.93 4.598L9.7 10.387h-1.5l-.217-5.79H9.93zm-.961 8.537c-.313 0-.57-.094-.774-.281a.916.916 0 01-.304-.692c0-.281.101-.512.304-.691.207-.18.465-.27.774-.27.312 0 .568.092.767.276.2.18.3.408.3.685 0 .281-.1.514-.3.697-.195.184-.45.276-.767.276z"
            fill="#fff"
          />
        </svg>
      );
    case 'TIMED_OUT':
      return (
        <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 0l10 16H0L10 0z" fill="#F2994A" />
          <path
            d="M10.93 5.598l-.229 5.789h-1.5l-.217-5.79h1.946zm-.961 8.537c-.313 0-.57-.094-.774-.281a.916.916 0 01-.304-.692c0-.281.101-.512.304-.691.207-.18.465-.27.774-.27.312 0 .568.092.767.276.2.18.3.408.3.685 0 .281-.1.514-.3.697-.195.184-.45.276-.767.276z"
            fill="#fff"
          />
        </svg>
      );
    case 'CREATED':
      return (
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill="#827F81" d="M3 7h10v2H3z" />
        </svg>
      );
    case 'Insight':
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="12.999" r="13" fill="#827F81" />
          <path d="M12 11h2v8h-2v-8zM12 7h2v2h-2z" fill="#fff" />
        </svg>
      );
    case 'Non-compliant':
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13" cy="12.999" r="13" fill="#BF0330" />
          <path
            d="M8.051 9.464L9.465 8.05l8.486 8.485-1.415 1.414-8.485-8.485z"
            fill="#fff"
          />
          <path
            d="M16.536 8.05l1.415 1.414-8.486 8.485-1.414-1.414 8.485-8.485z"
            fill="#fff"
          />
        </svg>
      );
    case 'LOW':
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="5.5" r="5.5" fill="#FFC72B" />
        </svg>
      );
    case 'MEDIUM':
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="5.5" r="5.5" fill="#F2994A" />
        </svg>
      );
    case 'HIGH':
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="5.5" r="5.5" fill="#EB5757" />
        </svg>
      );
    case 'CRITICAL':
      return (
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.5" cy="5.5" r="5.5" fill="#1A1719" />
        </svg>
      );
    default:
      return null;
  }
}
