import { faker } from '@faker-js/faker';
import {
  APIPayloadRiskHunter,
  APIResponseRiskHunter,
} from 'api/riskHunter/getRiskHunterAPI/index';
import { buildMockFilterData } from 'api/mockFilterData';
import {
  DominoBankData,
  DominoDimensionData,
  DominoDimensionType,
  DominoDimensionValueData,
  DominoScopeData,
  IRelation,
} from 'types/domino';
import { getRandomItemFromArray } from 'api/utils';

const { string, company, image } = faker;

const count = 2;

export const buildScopes = (_num: number): DominoScopeData => {
  return {
    id: string.uuid(),
    name: company.name(),
    party_id: string.uuid(),
  };
};

const scopes = Array.from(
  Array(Math.floor(Math.random() * count) + 1).keys(),
).map(count => buildScopes(count));

const buildValues = (_num: number): DominoDimensionValueData => {
  const type = getRandomItemFromArray([
    'party',
    'party.tp',
    'party.fp',
    'location',
    'business_unit',
    'risk_domain',
    'person',
    'rm',
    'cve',
    'software_package',
  ]) as DominoDimensionType;

  return {
    id: string.uuid(),
    type,
    label: company.name(),
    icon: null,
    scope_ids: scopes.map(scope => scope.id),
  };
};

export const buildStaticDimensions = (
  count: number,
  params: APIPayloadRiskHunter,
): DominoDimensionData => {
  if (count === 0) {
    const buildAggregatorValues = (): DominoDimensionValueData => {
      const type =
        params.aggregators.length > 1
          ? (params.relation as DominoDimensionType)
          : (getRandomItemFromArray([
              'party',
              'party.tp',
              'party.fp',
              'location',
              'business_unit',
              'risk_domain',
              'person',
              'rm',
              'cve',
              'software_package',
            ]) as DominoDimensionType);

      return {
        id: string.uuid(),
        type,
        label: company.name(),
        icon: null,
        scope_ids: scopes.map(scope => scope.id),
      };
    };

    const values = Array.from(
      Array(Array(params.aggregators.length).keys()).keys(),
    ).map(buildAggregatorValues);

    return {
      id: 'aggregators',
      label: 'Aggregators',
      searchable: false,
      order_by: ['label', 'scopes_count'],
      default_order: { direction: 1, field: 'label' },
      values,
    };
  }

  const values = Array.from(
    Array(Math.floor(Math.random() * 3) + 1).keys(),
  ).map(count => buildValues(count));

  return {
    id: string.uuid(),
    label: 'Third Parties',
    searchable: true,
    values,
    order_by: ['label', 'scopes_count'],
    default_order: { direction: 1, field: 'label' },
  };
};

export const buildDynamicDimensions = (count: number): DominoDimensionData => {
  if (count === 0) {
    const values = Array.from(Array(4).keys()).map(count => buildValues(count));

    return {
      id: 'business_units',
      label: 'Business units',
      searchable: false,
      default_order: {
        field: 'scopes_count',
        direction: -1,
      },
      order_by: ['label', 'scopes_count'],
      values,
    };
  }

  if (count === 1) {
    return {
      id: 'risk_domains',
      label: 'Risk domains',
      searchable: false,
      default_order: {
        field: 'scopes_count',
        direction: -1,
      },
      order_by: ['label', 'scopes_count'],
      values: [],
    };
  }

  const values = Array.from(Array(100).keys()).map(count => buildValues(count));

  return {
    id: 'scope_types',
    label: 'Relationship types',
    searchable: false,
    default_order: {
      field: 'scopes_count',
      direction: -1,
    },
    order_by: ['label', 'scopes_count'],
    values,
  };
};

export const relations: IRelation[] = [
  {
    id: 'rel_a_and_b',
    icon: 'a_and_b',
    tooltip: 'Show only shared third parties',
  },
  {
    id: 'rel_a_and_not_b',
    icon: 'a_and_not_b',
    tooltip: 'Show {a1} aggregated third parties, excluding shared ones',
  },
  {
    id: 'rel_not_a_and_b',
    icon: 'not_a_and_b',
    tooltip: 'Show {a2} aggregated third parties, excluding shared ones',
  },
];

export const mock: (
  params: APIPayloadRiskHunter,
) => APIResponseRiskHunter = params => {
  const static_dimensions = (params: APIPayloadRiskHunter) =>
    Array.from(Array(2).keys()).map(count =>
      buildStaticDimensions(count, params),
    );

  const dynamic_dimensions = () =>
    Array.from(Array(3).keys()).map(count => buildDynamicDimensions(count));

  const bank: DominoBankData = {
    label: company.name(),
    icon: image.url(),
  };

  return {
    scopes,
    static_dimensions: static_dimensions(params),
    dynamic_dimensions: dynamic_dimensions(),
    bank,

    relations,
    //for description of aggregators relation
    filter_data: buildMockFilterData(),
  };
};
