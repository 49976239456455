/**
 *
 * FilterMenuItem
 *
 */
import React from 'react';
import { IFilterItem } from 'app/containers/Filter/types';
import { useFilter } from './FilterContext';
import { deepCompare } from 'utils/deepCompare';
import { MenuItem, Typography } from '@mui/material';

interface FilterMenuItemProps {
  item: IFilterItem;
  handleClose: () => void;
}

export const FilterMenuItem = ({ item, handleClose }: FilterMenuItemProps) => {
  const { onChangeFilter, currentFilter } = useFilter();
  const isCurrent = Boolean(deepCompare(item.definition, currentFilter));
  const handleClick = () => {
    onChangeFilter(item.definition);
    handleClose();
  };
  return (
    <MenuItem onClick={handleClick} selected={isCurrent}>
      <Typography variant="body2" color="inherit">
        {item.label}
      </Typography>
    </MenuItem>
  );
};
