/**
 *
 * ErrorBlock
 *
 */
import { BlockCrashError } from 'types/errors';
import { useDispatch } from 'react-redux';
import { actions } from '../Root/slice';
import { useTranslation } from 'react-i18next';
import { StyledErrorBlock } from './styled';
import { Box, Link, Typography } from '@mui/material';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/Icon';

interface IErrorBlockProps {
  error: BlockCrashError;
  onSendFeedbackClick?: () => void;
}

export function ErrorBlock({ error, onSendFeedbackClick }: IErrorBlockProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleSendFeedback = () => {
    if (onSendFeedbackClick) {
      onSendFeedbackClick();
    }

    dispatch(actions.sendFeedback({ transactionId: error.transactionId }));
  };

  return (
    <StyledErrorBlock>
      <Box m="0 1rem 0 0">
        <Icon name="sad_face" color="#EB5757" />
      </Box>
      <Box>
        <Typography gutterBottom variant="body2">
          {t(translations.components.errorBlock.title)}
        </Typography>
        <Link
          style={{ cursor: 'pointer' }}
          variant="body2"
          onClick={handleSendFeedback}
        >
          {t(translations.components.errorBlock.sendFeedbackTitle)}
        </Link>
      </Box>
    </StyledErrorBlock>
  );
}
