import { actions as actionsRoot } from 'app/containers/Root/slice';
import { put } from 'redux-saga/effects';
import { auth0 } from 'utils/auth0';

export function* doLogoutSaga() {
  try {
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  } catch (e: any) {
    yield put(
      actionsRoot.addDefaultErrorAlert({
        transactionId: e.transactionId,
      }),
    );
  }
}
