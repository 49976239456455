import { IInsightIndicatorCell } from 'app/containers/AbstractTable/types';
import { StyledInsightIndicatorIconBox } from 'app/containers/AbstractTable/styled';
import { Icon } from 'app/components/Icon';
import React from 'react';

export const CellInsightIndicator = ({ data }: IInsightIndicatorCell) => {
  const { icon } = data;

  return (
    <StyledInsightIndicatorIconBox>
      <Icon name={icon} />
    </StyledInsightIndicatorIconBox>
  );
};
