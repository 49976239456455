/**
 *
 * EditFilterActions
 *
 */
import React from 'react';
import { useFilter } from './FilterContext';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Button } from 'app/components/Buttons/Button';
import { translations } from 'locales/translations';

export function EditFilterActions() {
  const { t } = useTranslation();

  const { closeEditFilter, doClearAllFields, addField, hideApplyActions } =
    useFilter();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      m="1rem 0 0 0"
      p="0 3rem"
      className="paddingConfig"
    >
      <Box>
        <Button
          color="primary"
          size="small"
          onClick={addField}
          startIcon={<Add />}
          test_id="button_filter_add"
        >
          {t(translations.components.filter.addFilter)}
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={doClearAllFields}
          style={{ margin: '0 0 0 1.5rem' }}
          test_id="button_filter_clear_all"
        >
          {t(translations.components.filter.clearAll)}
        </Button>
      </Box>
      {!hideApplyActions && (
        <DialogActions style={{ padding: 0 }}>
          <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={closeEditFilter}
            test_id="button_filter_cancel"
          >
            {t(translations.components.filter.cancel)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            test_id="button_filter_apply"
          >
            {t(translations.components.filter.apply)}
          </Button>
        </DialogActions>
      )}
    </Box>
  );
}
