import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledActions } from './styled';
import { Button } from 'app/components/Buttons/Button';
import { translations } from 'locales/translations';
import { useNotificationCenter } from './Context';

export const Actions = () => {
  const { t } = useTranslation();

  const { closeSettings, settings, saveSettings } = useNotificationCenter();

  const { loading, error } = settings;

  return (
    <StyledActions>
      <Button variant="contained" color="secondary" onClick={closeSettings}>
        {t(translations.notificationSettings.cancel)}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={Boolean(error)}
        loading={loading}
        onClick={saveSettings}
      >
        {t(translations.notificationSettings.save)}
      </Button>
    </StyledActions>
  );
};
