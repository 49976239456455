import { PopupViewBlockLinkButton } from '../types';
import React from 'react';
import { AggregatorViewButton } from '../../../components/Inputs/AggregatorViewButton';
import { Box } from '@mui/material';

export const BlockLinkButton = ({
  object_id,
  link_type,
}: PopupViewBlockLinkButton) => (
  <Box m="1rem 0">
    <AggregatorViewButton
      noMargin
      to={object_id}
      label={object_id}
      type={link_type}
    />
  </Box>
);
