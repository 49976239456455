import React from 'react';

export const AsideThirdParty = () => (
  <svg
    width="50"
    height="50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 23V13h10v10H13zm1-9h8v8h-8v-8zM27 23V13h10v10H27zm1-9h8v8h-8v-8zM13 27v10h10V27H13zm9 1h-8v8h8v-8zM27 37V27h10v10H27zm1-9h8v8h-8v-8z"
    />
    <path className="colored" d="M27 13h10v10H27z" />
  </svg>
);
