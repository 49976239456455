import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockFourthParty } from 'app/containers/RootInsights/types';

const { datatype, company } = faker;

export const insightsItemDataBlockFourthPartyMock: () => IInsightsItemDataBlockFourthParty =
  () => ({
    id: 'IInsightsItemDataBlockFourthParty',
    type: 'fourth-party',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Fourth Party',
    name: company.name(),
    entity_id: datatype.uuid(),
  });
