import React, { FC } from 'react';
import MUIIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { MuiProps } from 'types/common';

export type IconButtonProps = MuiIconButtonProps & MuiProps;

export const StyledButton = styled(MUIIconButton)``;

export const IconButton: FC<IconButtonProps> = props => {
  return <StyledButton {...props} />;
};
