import { faker } from '@faker-js/faker';
import { APIResponseScopeOptionsList } from 'api/thirdParties/getScopeOptionsListAPI/index';
import { SelectOptionType } from 'app/components/Inputs/Select_old';

const rowsCount = 10;

const { random } = faker;

const buildScope = (count: number): SelectOptionType => {
  return {
    value: `${count}_scope`,
    label: random.word(),
  };
};

const scopes = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildScope);

export const mock: APIResponseScopeOptionsList = {
  scopes,
};
