import { TableCellProps } from '../types';
import { StyledTableCell } from '../styled';
import { TableCellPopup } from '../TableCellPopup';
import { TableCellBody } from './TableCellBody';
import { useTableCell } from './useTableCell';
import { Box } from 'app/components/Box';
import { Popover } from 'app/components/Popover';

export const TableCell = (props: TableCellProps) => {
  const { cell, index } = props;

  const { popup, type } = cell;

  const {
    is_small,
    colSpan,
    anchorEl,
    isPopoverOpen,
    handlePopoverOpen,
    handlePopoverClose,
    popupBorderTop,
  } = useTableCell(props);

  if (popup) {
    return (
      <StyledTableCell
        className="with-popup"
        is_small={is_small}
        colSpan={colSpan}
        data-test={`table_cell_${index}_${type}`}
      >
        <Box onClick={handlePopoverOpen}>
          <TableCellBody {...props} />
        </Box>
        <Popover
          id="cell-popover"
          open={isPopoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          PaperProps={{
            style: {
              borderTop: popupBorderTop,
            },
          }}
        >
          <TableCellPopup popup={popup} onClose={handlePopoverClose} />
        </Popover>
      </StyledTableCell>
    );
  }

  return (
    <StyledTableCell
      is_empty={type === 'empty'}
      is_small={is_small}
      colSpan={colSpan}
      data-test={`table_cell_${index}_${type}`}
    >
      <TableCellBody {...props} />
    </StyledTableCell>
  );
};
