/**
 *
 * CellRiskDomain
 *
 */

import React from 'react';
import { IRiskDomainCell } from '../../types';
import { RiskDomainsScopesBox } from '../../../../components/RiskDomainsScopesBox';
import { Typography } from '@mui/material';

export const CellRiskDomain = ({ data }: IRiskDomainCell) => {
  const { name, description, scopes } = data.risk_domain;
  return (
    <>
      <Typography variant="body2" gutterBottom>
        <strong>{name}</strong>
      </Typography>
      {description && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {description}
        </Typography>
      )}
      {!!scopes?.length && <RiskDomainsScopesBox scopes={scopes || []} />}
    </>
  );
};
