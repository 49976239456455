import { styled } from '@mui/material/styles';

interface StyledHeaderBoxProps {
  isRed?: boolean;
}

export const HeaderBox = styled('header', {
  shouldForwardProp: prop => prop !== 'isRed',
})<StyledHeaderBoxProps>`
  background: ${({ theme, isRed }) =>
    isRed ? 'red' : theme.layout.header.background};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  height: ${({ theme }) => theme.layout.header.height};
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const LogoBox = styled('a')`
  transition: 0.3s;
  height: ${({ theme }) => theme.layout.aside.width};
  width: ${({ theme }) => theme.layout.aside.width};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  & > div {
    &:nth-of-type(1) {
      flex-grow: 1;
      margin: 0 1rem 0 0;
    }
  }
`;
