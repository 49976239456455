import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';

interface StyledTabsProps {
  removeDivider?: boolean;
}

export const StyledTabs = styled(Tabs, {
  shouldForwardProp: prop => prop !== 'removeDivider',
})<StyledTabsProps>`
  .MuiTabs-scroller {
    ${({ removeDivider, theme }) =>
      !removeDivider && `border-bottom: 1px solid ${theme.palette.divider};`}
  }

  .MuiTabs-indicator {
    height: 3px;
    background: ${({ theme }) => theme.palette.primary.dark};
  }

  .MuiTabs-flexContainer {
    & > * {
      ${({ removeDivider, theme }) =>
        removeDivider && `border-bottom: 1px solid ${theme.palette.divider};`}
      &:before {
        content: '';
        position: absolute;
        top: 0.8rem;
        bottom: 0.8rem;
        right: 0;
        width: 1px;
        background: ${({ theme }) => theme.palette.divider};
      }

      &:last-of-type {
        &:before {
          display: none;
        }
      }
    }
  }
`;

interface StyledTabProps {
  component?: string;
}

export const StyledTab = styled(Tab)<StyledTabProps>`
  text-transform: none;
  font-size: 1rem;
  min-width: 10rem;
  position: relative;
  color: ${({ theme }) => theme.palette.text.primary};
`;
