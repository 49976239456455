/**
 *
 * Arrow
 *
 */
import React from 'react';
import { styled } from '@mui/material/styles';

interface StyledArrowProps {
  up?: boolean;
}

const StyledArrow = styled('span', {
  shouldForwardProp: prop => prop !== 'up',
})<StyledArrowProps>`
  margin: -3px 0 0 0;
  svg {
    transition: 0.3s;
    ${({ up }) => up && `transform: rotate(180deg);`}
  }
`;

export function Arrow(props: StyledArrowProps) {
  return (
    <StyledArrow className="arrow" {...props}>
      <svg
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.2071 0h1v10l-.5.5-.5-.5V0z" fill="#1A1719" />
        <path
          d="M6.7071 6l.7071.7071L4.2072 10l-.5-1 3-3zM.7071 6L0 6.7071 3.2071 10l.5-1-3-3z"
          fill="#1A1719"
        />
      </svg>
    </StyledArrow>
  );
}
