import React from 'react';
import { NotificationField } from 'app/containers/Root/Notifications/Form/types';
import { FieldCheckbox } from './FieldCheckbox';
import { FieldCheckboxWithSelect } from './FieldCheckboxWithSelect';
import { FieldGroup } from './FieldGroup';
import { FieldMultiTag } from './FieldMultiTag';
import { FieldSeparator } from './FieldSeparator';
import { useSelector } from 'react-redux';
import { selectEventForm } from 'app/containers/Root/Notifications/selectors';

type EventFiledProps = NotificationField;

export const EventFiled = (props: EventFiledProps) => {
  const form = useSelector(selectEventForm);

  if (!form) {
    return null;
  }

  const { type } = props;

  switch (type) {
    case 'multi_tag':
      return <FieldMultiTag {...props} />;
    case 'checkbox':
      return <FieldCheckbox {...props} />;
    case 'checkbox_with_select':
      return <FieldCheckboxWithSelect {...props} />;
    case 'group':
      return <FieldGroup {...props} />;
    case 'separator':
      return <FieldSeparator />;
    default:
      return null;
  }
};
