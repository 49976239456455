/**
 *
 * HiddenInput
 *
 */
import React from 'react';

interface Props {
  value: any;
  onChange: (e) => void;
  type?: 'select' | 'text';
}

export function HiddenInput({ value, onChange, type = 'text' }: Props) {
  return (
    <input
      type={type}
      value={value}
      required
      style={{
        // make it invisible
        opacity: 0,
        // avoid letting click events target the hidden input
        pointerEvents: 'none',
        // position the input so the validation message will target the correct location
        // (fake input is already position: relative)
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
      // added in response to issue comments
      aria-hidden={true}
      tabIndex={-1}
      onChange={onChange}
    />
  );
}
