import React from 'react';

export const EditFilterIcon = () => (
  <svg
    width="15"
    height="12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 12"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.94999 9C4.71836 7.85888 3.70948 7 2.5 7 1.11929 7 0 8.11929 0 9.5 0 10.8807 1.11929 12 2.5 12c1.20948 0 2.21836-.8589 2.44999-2H14.5c.2761 0 .5-.22386.5-.5s-.2239-.5-.5-.5H4.94999zM1 9.5c0 .8284.67157 1.5 1.5 1.5S4 10.3284 4 9.5C4 8.67157 3.32843 8 2.5 8S1 8.67157 1 9.5zM10.05 2c.2316-1.14112 1.2405-2 2.45-2C13.8807 0 15 1.11929 15 2.5S13.8807 5 12.5 5c-1.2095 0-2.2184-.85888-2.45-2H.5C.223858 3 0 2.77614 0 2.5S.223858 2 .5 2h9.55zm3.95.5c0 .82843-.6716 1.5-1.5 1.5S11 3.32843 11 2.5 11.6716 1 12.5 1s1.5.67157 1.5 1.5z"
      fill="#1A1719"
    />
  </svg>
);
