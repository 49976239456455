import React from 'react';
import { Box } from '@mui/material';
import { EventFiled } from './EventFiled';
import { IAbstractFieldGroup } from 'app/containers/Root/Notifications/Form/types';

interface FieldGroupProps extends IAbstractFieldGroup {}

export const FieldGroup = ({ fields_list }: FieldGroupProps) => (
  <Box>
    {fields_list?.map(field => (
      <Box key={field.id} m="1rem 0">
        <EventFiled {...field} />
      </Box>
    ))}
  </Box>
);
