import { faker } from '@faker-js/faker';
import { APIResponseSearch } from 'api/searchAPI/index';
import { SearchResult } from 'types/search';

const { datatype, company, internet, address } = faker;

const count = 10;

export const buildResult = (num: number, query: string): SearchResult => {
  if (num === 0) {
    return {
      id: datatype.uuid(),
      type: 'party',
      party_id: datatype.uuid(),
      label: `${query}`,
      date: new Date().toDateString(),
      party_info: { domain: internet.url(), location: address.city() },
    };
  }
  return {
    id: datatype.uuid(),
    type: 'party',
    party_id: datatype.uuid(),
    label: `${query} ${company.name()}`,
    date: new Date().toDateString(),
    party_info: { domain: internet.url(), location: address.city() },
  };
};

export const results = (query: string) =>
  Array.from(Array(Math.floor(Math.random() * count) + 1).keys()).map(count =>
    buildResult(count, query),
  );

export const mock: (query: string) => APIResponseSearch = query => ({
  results: results(query),
});
