/**
 *
 * ShowSelected
 *
 */
import React from 'react';
import { StyledCheckBoxWrapper } from './styled';
import { CheckBox } from '../Inputs/CheckBox';
import { useHistory } from 'react-router-dom';
import { parseSearch } from '../../hooks/usePageParams/utils';

interface ShowSelectedProps {
  onChangeShowSelected?: (value: boolean) => void;
}

export function ShowSelected({ onChangeShowSelected }: ShowSelectedProps) {
  const history = useHistory();

  const { search } = history.location;

  const pageParams = parseSearch(search);

  const { selected_ids, show_selected } = pageParams;

  if (!onChangeShowSelected) {
    return null;
  }

  const handleChange = e => {
    onChangeShowSelected(e.target.checked);
  };

  return (
    <StyledCheckBoxWrapper>
      <CheckBox
        size="small"
        checked={show_selected}
        label={`View selected (${selected_ids?.length || 0})`}
        onChange={handleChange}
      />
    </StyledCheckBoxWrapper>
  );
}
