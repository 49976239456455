import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockPerson } from 'app/containers/RootInsights/types';

const { datatype, name } = faker;

export const insightsItemDataBlockPersonMock: () => IInsightsItemDataBlockPerson =
  () => ({
    id: 'IInsightsItemDataBlockPerson',
    type: 'person',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Person',
    name: name.fullName(),
    entity_id: datatype.uuid(),
  });
