import { ButtonBase, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableSizeType, TableStyleType } from './types';

interface TableWrapperProps {
  maxHeight?: string;
}

export const TableWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'maxHeight',
})<TableWrapperProps>`
  position: relative;
  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
    overflow: auto;
    `}

  .with-popup {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface StyledTableProps {
  stickyHeader: string;
  styleType: TableStyleType;
  size: TableSizeType;
}

export const StyledTable = styled('table', {
  shouldForwardProp: prop =>
    prop !== 'stickyHeader' && prop !== 'styleType' && prop !== 'size',
})<StyledTableProps>`
  position: relative;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: ${({ theme, styleType }) =>
    theme.layout.table.tableBorder[styleType]};

  thead {
    tr {
      border-top: none;
    }
  }

  th {
    text-align: left;
    background: ${({ theme, styleType }) =>
      theme.layout.table.headBackground[styleType]};
  }

  th {
    ${({ stickyHeader }) =>
      stickyHeader &&
      `
        position: sticky;
        top: ${stickyHeader};
        z-index: 9;
    `};
  }

  tr {
    &:nth-of-type(2n) {
      td {
        background: ${({ theme, styleType }) =>
          theme.layout.table.backgroundSecondary[styleType]};
      }
    }

    td {
      background: ${({ theme, styleType }) =>
        theme.layout.table.background[styleType]};
    }
  }

  td {
    max-width: 40rem;
  }

  th {
    border-bottom: ${({ theme, styleType }) =>
      theme.layout.table.headerBorder[styleType]};
  }

  td {
    border-bottom: ${({ theme, styleType }) =>
      theme.layout.table.rowBorder[styleType]};
  }

  td,
  th {
    padding-top: ${({ theme, size }) =>
      theme.layout.table.rowPaddingTopBottom[size]};
    padding-bottom: ${({ theme, size }) =>
      theme.layout.table.rowPaddingTopBottom[size]};
    padding-left: ${({ theme, size }) =>
      theme.layout.table.rowPaddingLeftRight[size]};
    padding-right: ${({ theme, size }) =>
      theme.layout.table.rowPaddingLeftRight[size]};

    &:first-of-type {
      padding-left: ${({ theme, size }) =>
        theme.layout.table.rowPaddingCorner[size]};
    }

    &:last-of-type {
      padding-right: ${({ theme, size }) =>
        theme.layout.table.rowPaddingCorner[size]};
    }
  }
`;

interface StyledTableHeaderThProps {
  max_width?: string;
  width?: string;
  is_empty?: boolean;
  is_small?: boolean;
}

export const StyledTableHeaderTh = styled('th', {
  shouldForwardProp: prop =>
    prop !== 'max_width' &&
    prop !== 'width' &&
    prop !== 'is_empty' &&
    prop !== 'is_small',
})<StyledTableHeaderThProps>`
  ${({ max_width }) => max_width && `max-width: ${max_width}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ is_empty }) => is_empty && `padding: 0!important`};
  ${({ is_small }) => is_small && `width: 0.1%`};
`;

interface StyledTableCellProps {
  is_empty?: boolean;
  is_small?: boolean;
}

export const StyledTableCell = styled('td', {
  shouldForwardProp: prop => prop !== 'is_small' && prop !== 'is_empty',
})<StyledTableCellProps>`
  ${({ is_small }) => is_small && `width: 0.1%`};
  ${({ is_empty }) => is_empty && `padding: 0!important; width: 0.1%`};
`;

interface StyledTableHeaderCellProps {
  orderable?: boolean;
  currentOrder?: boolean;
}

export const StyledTableHeaderCell = styled('div', {
  shouldForwardProp: prop => prop !== 'orderable' && prop !== 'currentOrder',
})<StyledTableHeaderCellProps>`
  display: flex;
  align-items: center;
  ${({ orderable }) => orderable && 'cursor: pointer;'}

  .arrow {
    opacity: 0;
    svg path {
      fill: ${({ theme }) => theme.palette.text.secondary};
    }
  }

  ${({ currentOrder, theme }) =>
    currentOrder &&
    `
      .arrow {
        opacity: 1;
        svg path {
          fill: ${theme.palette.text.primary};
        }
      }
  `}

  .explanation {
    cursor: pointer;
    margin: 0 0 0 0.5rem;
  }

  &:hover {
    .arrow {
      opacity: 1;
    }
  }
`;

interface StyledTableRowProps {
  isHover?: boolean;
  rowBackground?: string;
  isActive?: boolean;
  collapseHead?: boolean;
  collapseBody?: boolean;
  isExpanded?: boolean;
  lasOfGroup?: boolean;
}

export const StyledTableRow = styled('tr', {
  shouldForwardProp: prop =>
    prop !== 'isHover' &&
    prop !== 'rowBackground' &&
    prop !== 'isActive' &&
    prop !== 'collapseHead' &&
    prop !== 'collapseBody' &&
    prop !== 'collapseHead' &&
    prop !== 'isExpanded' &&
    prop !== 'lasOfGroup',
})<StyledTableRowProps>`
  td {
    transition: background 0.3s;
  }

  & > td:last-of-type {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      bottom: 50%;
      width: 3px;
      background: transparent;
      right: 0;
      transition: 0.3s;
    }
  }

  ${({ isHover, theme }) =>
    isHover &&
    `
    &:hover {
      & > td {
        background: ${theme.layout.table.rowHover}!important;
      }
      cursor: pointer;
    }
  `}

  ${({ rowBackground }) =>
    rowBackground &&
    `
    & > td {
        background: ${rowBackground}!important;
    }
  `}
  
  ${({ isActive, theme }) =>
    isActive &&
    `
    & > td {
      background: ${theme.layout.table.rowBackgroundSelected}
    }
    
    & > td:last-of-type {
      &:after {
        top:0;
        background: ${theme.palette.primary.main};
        bottom:0;
      }
    }
  `}
  
  ${({ collapseHead, isExpanded, theme }) =>
    collapseHead &&
    `
    td {
      border-top: 1px solid ${
        isExpanded ? theme.palette.text.primary : 'transparent'
      };
      &:first-of-type {
        border-left: 1px solid ${
          isExpanded ? theme.palette.text.primary : 'transparent'
        };    
      }
      &:last-of-type {
        border-right: 1px solid ${
          isExpanded ? theme.palette.text.primary : 'transparent'
        };
      }
    }
  `}
  
  ${({ collapseHead, isExpanded, theme }) =>
    collapseHead &&
    isExpanded &&
    `
    td {
      background: ${theme.layout.table.headBackground.default}!important;
      border-bottom: 0px solid ${theme.layout.table.headBackground.default}!important;
    }
  `} 
  
  ${({ collapseBody, isExpanded, lasOfGroup, theme }) =>
    collapseBody &&
    `
    position: absolute;
    opacity:0;
    bottom:0;
    z-index: -9999;
    pointer-events:none;
    ${
      isExpanded &&
      `opacity:1; position: relative; bottom: unset; z-index: 2; pointer-events: all;`
    }
    td {
      border-bottom: ${
        lasOfGroup
          ? `1px solid ${theme.palette.text.primary}`
          : `1px dashed ${theme.palette.text.secondary}`
      };
    }
    
    td {
      &:first-of-type {
        border-left: 1px solid ${
          isExpanded ? theme.palette.text.primary : 'transparent'
        };    
      }
      &:last-of-type  {
        border-right: 1px solid ${
          isExpanded ? theme.palette.text.primary : 'transparent'
        };
      }
    }
  `}
`;

export const StyledTableRowSep = styled('tr')`
  height: 0.5rem;
`;

export const StyledTextWithAvatar = styled('div')`
  display: flex;
  align-items: center;
  img,
  svg {
    margin: 0 0.5rem 0 0;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
  }
`;

export const StyledTableIcon = styled('div')`
  svg {
    width: 1.5rem;
  }
`;

interface StyledTagListProps {
  nowrap?: boolean;
}

export const StyledTagList = styled('div', {
  shouldForwardProp: prop => prop !== 'nowrap',
})<StyledTagListProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  & > * {
    margin: 0 0.3rem 0.5rem 0;
  }
  flex-wrap: wrap;
  ${({ nowrap }) => nowrap && `flex-wrap: nowrap;`}
`;

export const StyledLinkList = styled('div')`
  position: relative;
  white-space: nowrap;

  &.wrap {
    white-space: normal;
  }
`;

export const StyledLinkText = styled(Link)`
  max-width: 150px;
  display: inline-block;
  vertical-align: bottom;
  &:hover {
    text-decoration: underline;
  }
`;

export const IconBox = styled('div')`
  svg,
  img {
    width: 14px;
    height: 14px;
  }
`;

export const StyledInsightIndicatorIconBox = styled('div')`
  svg,
  img {
    width: 14px;
    height: 14px;
    margin: 0 0 0 0.3rem;
  }
`;

interface IWithIsExpanded {
  isExpanded?: boolean;
}

export const StyledExpState = styled('div', {
  shouldForwardProp: prop => prop !== 'isExpanded',
})<IWithIsExpanded>`
  svg {
    width: 20px;
    fill: ${({ theme }) => theme.palette.primary.main}
    transition: 0.3s;
    ${({ isExpanded }) => isExpanded && `transform: rotate(180deg);`}
  }
`;

export const StyledInsightTypeIcon = styled('div')`
  border-right: 1px solid ${({ theme }) => theme.palette.divider}
  padding: 0 0.6rem 0 0;

  svg {
    width: 24px;
  }
`;

export const StyledInsightGroupIndicator = styled('div', {
  shouldForwardProp: prop => prop !== 'isExpanded',
})<IWithIsExpanded>`
  display: flex;
  align-items: center;
  svg:nth-of-type(1) {
    width: 28px;
    height: 28px;
  }

  svg:nth-of-type(2) {
    width: 11px;
    height: 12px;
    margin: 10px 0 0 2px;
    transition: 0.3s;
    transform: scale(0.5);
    opacity: 0;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
      svg:nth-of-type(2) {
        transform: scale(1);
        opacity: 1;
      }  
  `}
`;

export const StyledExplanationButton = styled(ButtonBase)`
  border-radius: 50%;
`;

export const StyledLocationAlertBox = styled('div')`
  position: relative;
  padding: 0.5rem 1rem;
  max-width: 26rem;

  .close {
    position: absolute;
    top: 2px;
    right: 0;
  }

  .pre {
    white-space: pre-wrap;
  }
`;
