/**
 *
 * AppLoader
 *
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRoot } from 'app/containers/Root/useRoot';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { LoadingPage } from 'app/components/LoadingPage';

export function AppLoader() {
  const { t } = useTranslation();

  const { loading } = useRoot();

  return (
    <>
      <Helmet>
        <title>{t(translations.appLoader.title)}</title>
      </Helmet>
      <LoadingPage isLoading={loading} fullPage />
    </>
  );
}
