import { APIResponsePartyInsightItem } from 'api/insights/getPartyInsightItemAPI/index';
import { getRandomItemFromArray } from 'api/utils';
import {
  IInsightsItemType,
  InsightIndicatorType,
} from 'app/containers/RootInsights/types';
import { insightsItemDataBlockTextMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockTextMock';
import { insightsItemDataBlockListMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockListMock';
import { insightsItemDataBlockDateMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockDateMock';
import { insightsItemDataBlockTableMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockTableMock';
import { insightsItemDataBlockQuestionMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockQuestionMock';
import { insightsItemDataBlockRiskDomainsMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockRiskDomainsMock';
import { insightsItemDataBlockOnlineAssetMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockOnlineAssetMock';
import { insightsItemDataBlockFourthPartyMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockFourthPartyMock';
import { insightsItemDataBlockLocationMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockLocationMock';
import { insightsItemDataBlockPersonMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockPersonMock';
import { insightsItemDataBlockSoftwarePackageMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockSoftwarePackageMock';
import { insightsItemDataBlockAccordionListMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockAccordionListMock';
import { insightsItemDataBlockViewerMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockViewerMock';

export const getInsightDataMock: (
  id: string,
) => APIResponsePartyInsightItem = id => {
  return {
    id,
    type: getRandomItemFromArray([
      'online-assets',
      'questions',
      'person',
      'location',
      'control',
      'insight_online_asset_finding',
    ]) as IInsightsItemType,
    title: 'Title',
    type_title: getRandomItemFromArray([
      'Online assets',
      'Questions',
      'Person',
      'Location',
      'Control',
      'insight_online_asset_finding',
    ]),
    subtitle: getRandomItemFromArray(['Compliant', 'Non compliant', 'Insight']),
    status_id: getRandomItemFromArray([
      'compliant',
      'non_compliant',
      'insight',
    ]) as InsightIndicatorType,
    status_label: getRandomItemFromArray([
      'Compliant',
      'Non compliant',
      'Insight',
    ]),
    blocks: [
      insightsItemDataBlockAccordionListMock(),
      insightsItemDataBlockViewerMock(),
      insightsItemDataBlockViewerMock(),
      insightsItemDataBlockViewerMock(),
      insightsItemDataBlockViewerMock(),
      insightsItemDataBlockViewerMock(),
      insightsItemDataBlockQuestionMock(),
      insightsItemDataBlockTextMock(),
      insightsItemDataBlockListMock(),
      insightsItemDataBlockLocationMock(),
      insightsItemDataBlockRiskDomainsMock(),
      insightsItemDataBlockDateMock(),
      insightsItemDataBlockOnlineAssetMock(),
      insightsItemDataBlockTableMock(),
      insightsItemDataBlockSoftwarePackageMock(),
      insightsItemDataBlockFourthPartyMock(),
      insightsItemDataBlockPersonMock(),
    ],
  };
};
