import { RequestApi } from 'api/types';
import { SearchResponse } from 'app/hooks/useSearch/types';
import { USE_MOCK } from 'utils/constants';
import { apiDelay } from 'api/utils';
import { request } from 'utils/request';
import { mock } from 'api/searchAPI/mock';

export type APIPayloadSearch = string;

export interface APIResponseSearch extends SearchResponse {}

export const searchAPI: RequestApi<
  APIPayloadSearch,
  APIResponseSearch
> = async (query, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock(query));
      }, apiDelay);
    });
  }

  return request(`/search/?query=${query}`, { signal });
};
