import { INameWithAvatarCell } from 'app/containers/AbstractTable/types';
import { StyledTextWithAvatar } from 'app/containers/AbstractTable/styled';
import { DefaultAvatarIcon } from 'app/components/Icons/svg/DefaultAvatarIcon';
import { Typography } from 'app/components/Typography';

export const CellNameWithAvatar = ({ data }: INameWithAvatarCell) => {
  const { avatar, name } = data;

  const BodyImg = () => {
    if (avatar) {
      return <img className="cell-image" src={avatar} alt={name} />;
    }
    return <DefaultAvatarIcon />;
  };

  return (
    <StyledTextWithAvatar>
      <BodyImg />
      <Typography variant="body2">
        <strong>{name}</strong>
      </Typography>
    </StyledTextWithAvatar>
  );
};
