/**
 *
 * CellTextWithShowMore
 *
 */

import React from 'react';
import { ITextShowMoreCell } from '../../types';
import { TextWithMods } from '../../../../components/TextWithMods';
import { Tooltip, Typography } from '@mui/material';

const defaultTrimOption = 15;

export const CellTextWithShowMore = ({ data }: ITextShowMoreCell) => {
  const { text, mods, trim } = data;

  const trimOption = trim || defaultTrimOption;

  if (text.length > trimOption) {
    return (
      <Tooltip
        title={<Typography variant="body2">{text}</Typography>}
        aria-label={text}
        placement="bottom-start"
      >
        <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
          <TextWithMods mods={mods || []}>
            {`${text.substring(0, trimOption)}...`}
          </TextWithMods>
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
      <TextWithMods mods={mods || []}>{text}</TextWithMods>
    </Typography>
  );
};
