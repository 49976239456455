/**
 *
 * TableTop
 *
 */
import { MenuItem, Typography } from '@mui/material';
import React from 'react';

interface DropdownMenuItemProps {
  option: number;
  limit: number;
  onChangeLimit: (limit: number) => void;
}

export function DropdownMenuItem({
  option,
  limit,
  onChangeLimit,
}: DropdownMenuItemProps) {
  const handleChangeLimit = () => {
    onChangeLimit(option);
  };

  return (
    <MenuItem onClick={handleChangeLimit} selected={limit === option}>
      <Typography variant="body2" color="inherit">
        {option}
      </Typography>
    </MenuItem>
  );
}
