import { styled } from '@mui/material/styles';

export const StyledErrorBlock = styled('div')`
  display: flex;
  align-items: center;
  background: #f9e6eb;
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid #eb5757;
  border-radius: 4px;
`;
