import { StyledEditFilterButton } from './styled';
import { useFilter } from './FilterContext';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { EditFilterIcon } from 'app/components/Icons/svg/EditFilterIcon';
import { Typography } from 'app/components/Typography';

export function EditFilterButton() {
  const { t } = useTranslation();

  const { currentFilter, openEditFilter } = useFilter();

  return (
    <StyledEditFilterButton
      onClick={openEditFilter}
      data-test="button_add_filter"
    >
      <EditFilterIcon />
      <Typography color="inherit" variant="body2">
        {Boolean(currentFilter)
          ? t(translations.components.filter.editFilter)
          : t(translations.components.filter.addFilter)}
      </Typography>
    </StyledEditFilterButton>
  );
}
