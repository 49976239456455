import {
  Autocomplete as MuiAutocomplete,
  CircularProgress,
} from '@mui/material';
import { ITextFieldProps, TextField } from '../TextField';
import { styled } from '@mui/material/styles';
import { WithTestID } from 'types/common';

export interface AutocompleteProps extends WithTestID {
  inputPops: ITextFieldProps;
  inputValue?: string;
  loading?: boolean;
  readonly?: boolean;
  options?: any;
  onInputChange?: (event, newInputValue, reason) => void;
  onChange?: (event, value) => void;
  onBlur?: () => void;
  getOptionLabel?: (option) => any;
  renderOption?: (props, option, { selected }) => any;
  value?: any;
  disableClearable?: boolean;
}

export const StyledAutocomplete = styled(MuiAutocomplete)`
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0;

    .MuiAutocomplete-input {
      padding: 7px 14px 7px;
    }
  }

  &.MuiAutocomplete-hasClearIcon {
    .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
      padding: 0 39px 0 0;
    }
  }
`;

export function Autocomplete({
  inputPops,
  inputValue,
  onInputChange,
  loading,
  options,
  getOptionLabel,
  value,
  renderOption,
  onBlur,
  onChange,
  disableClearable,
  readonly,
  test_id,
}: AutocompleteProps) {
  return (
    <StyledAutocomplete
      fullWidth
      freeSolo
      inputValue={inputValue}
      options={options}
      loading={loading}
      value={value}
      disableClearable={
        disableClearable || inputPops.disabled || inputPops.readonly
      }
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onBlur}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      disabled={inputPops.disabled || inputPops.readonly}
      data-test={test_id}
      renderInput={params => (
        <TextField
          {...params}
          {...inputPops}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            inputProps: params.inputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          readonly={readonly}
        />
      )}
    />
  );
}
