import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { apiDelay } from 'api/utils';
import { request } from 'utils/request';
import queryString from 'query-string';
import { mock } from 'api/riskHunter/getRiskHunterAPI/mock';
import { DominoResponse } from 'types/domino';

export type APIPayloadRiskHunter = {
  aggregators: string[];
  relation?: string | null;
};

export interface APIResponseRiskHunter extends DominoResponse {}

export const getRiskHunterAPI: RequestApi<
  APIPayloadRiskHunter,
  APIResponseRiskHunter
> = async (params, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock(params));
      }, apiDelay);
    });
  }

  return request(`/risk_hunter/dimensions?${queryString.stringify(params)}`, {
    signal,
  });
};
