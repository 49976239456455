/**
 *
 * TableRowContext
 *
 */

import React, { createContext } from 'react';
import { IUseTableRow } from '../types';

interface ITableRowContext extends IUseTableRow {}

export const TableRowContext = createContext<ITableRowContext | undefined>(
  undefined,
);

export const useTableRow = () => {
  const context = React.useContext(TableRowContext);
  if (context === undefined) {
    throw new Error(
      'useTableRow must be used within a TableRowContextProvider',
    );
  }
  return context;
};
