import React from 'react';
import { IconProps } from './types';

export const HeaderSearchIcon = ({ stroke }: IconProps) => (
  <svg
    width="20"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18"
  >
    <path
      d="M13.2927 12.5556C15.6713 14.6156 16.6214 14.9399 19 17m-4.0783-8.9664c0 3.8845-3.1165 7.0335-6.9608 7.0335C4.1165 15.0671 1 11.9181 1 8.0336 1 4.149 4.1165 1 7.9609 1c3.8443 0 6.9608 3.149 6.9608 7.0336z"
      stroke={stroke || '#fff'}
      strokeWidth="2"
    />
  </svg>
);
