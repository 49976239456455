import { auth0 } from 'utils/auth0';
import { AUTH0_AUDIENCE } from 'utils/constants';
import { put } from 'redux-saga/effects';
import { actions } from 'app/containers/Root/slice';

export function* checkSession() {
  const { search } = window.location;

  const params = new URLSearchParams(search);

  const error = params.get('error');

  const code = params.get('code');

  const state = params.get('state');

  const errorDescription = params.get('error_description');

  const shouldParseResult = code && state;

  try {
    if (error) {
      throw new Error(error);
    }
    if (shouldParseResult) {
      yield auth0.handleRedirectCallback();
    }

    return yield auth0.getTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    });
  } catch (e: any) {
    if (e.message === 'access_denied') {
      yield put(
        actions.onError({
          type: 'no_access',
          transactionId: e.transactionId,
          message: errorDescription || '',
        }),
      );

      return;
    }

    if (e.error !== 'login_required') {
      throw e;
    }
    return yield auth0.loginWithRedirect({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
    });
  }
}
