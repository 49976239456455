import { StyledItem } from './styled';
import { SearchResult } from 'types/search';
import { useSearchBar } from './Context';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Highlight } from 'app/components/Highlight';
import { Typography } from 'app/components/Typography';
import { Link as StyledLink } from 'app/components/Link';
import { usePermissions } from 'app/containers/Root/usePermissions';

interface SearchItemProps {
  item: SearchResult;
}

export const SearchItem = ({ item }: SearchItemProps) => {
  const { t } = useTranslation();

  const { createScope } = usePermissions();

  const history = useHistory();

  const { handleLink, query } = useSearchBar();

  const handleClickAddScope = e => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/third-party/${item.id}/scope/add`);
    handleLink();
  };

  switch (item.type) {
    case 'scope':
      return (
        <StyledItem
          to={`/third-party/${item.party_id}/scope/${item.id}`}
          onClick={handleLink}
          data-test="field_scope_search_item"
        >
          <Typography variant="body2" color="textPrimary">
            <Highlight searchText={query} highlightTag="strong">
              {item.label}
            </Highlight>
          </Typography>
        </StyledItem>
      );

    case 'party':
      return (
        <StyledItem
          to={`/third-party/${item.id}`}
          onClick={handleLink}
          data-test="field_third_parrty_search_item"
        >
          <Typography variant="body2" color="textPrimary">
            <Highlight searchText={query} highlightTag="strong">
              {item.label}
            </Highlight>
            {createScope && (
              <>
                {' | '}
                <StyledLink
                  to={`/third-party/${item.id}/scope/add`}
                  component={Link}
                  underline="hover"
                  onClick={handleClickAddScope}
                >
                  {t(translations.searchBar.addScope)}
                </StyledLink>
              </>
            )}
          </Typography>
        </StyledItem>
      );
    default:
      return null;
  }
};
