declare global {
  interface Window {
    NODE_ENV?: string;
    API_HOST?: string;
    AUTH0_CLIENT_ID?: string;
    AUTH0_DOMAIN?: string;
    AUTH0_AUDIENCE?: string;
    SENTRY_DSN?: string;
    SENTRY_ENV?: string;
  }

  interface env {
    NODE_ENV?: string;
    REACT_APP_USE_MOCK?: string;
    REACT_APP_API_HOST?: string;
    REACT_APP_AUTH0_CLIENT_ID?: string;
    REACT_APP_AUTH0_DOMAIN?: string;
    REACT_APP_AUTH0_AUDIENCE?: string;
    REACT_APP_SENTRY_DSN?: string;
    REACT_APP_SENTRY_ENV?: string;
  }
}
export const NODE_ENV = window.NODE_ENV || process.env.NODE_ENV;
export const USE_MOCK = process.env.REACT_APP_USE_MOCK === 'true';

export const API_HOST =
  process.env.REACT_APP_API_HOST || window.API_HOST || '/api';

export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID || window.AUTH0_CLIENT_ID || '';
export const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN || window.AUTH0_DOMAIN || '';
export const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE || window.AUTH0_AUDIENCE || '/api';
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN || window.SENTRY_DSN || '';
export const SENTRY_ENV =
  process.env.REACT_APP_SENTRY_ENV || window.SENTRY_ENV || '';

export const DATE_FORMAT = 'DD-MMM-YYYY';
export const DATE_FORMAT_WITH_HOURS = 'DD-MMM-YYYY | HH:MM';

export const urlRegex =
  /^(https?:\/\/)?([\w-]+\.)*[\w-]+\.[\w-]+(\/[\w- ;,./?%&=]*)?$/i;

export const SUPPORT_LINK = `mailto:support@mirato.com`;
