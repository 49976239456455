/**
 *
 * TableTop
 *
 */
import React, { FC, ReactNode } from 'react';
import { ITableLegend } from 'app/containers/AbstractTable/types';
import { TableTopLegendBox, TableTopWrapper } from './styled';
import { Legend } from './Legend';
import { Pagination } from './Pagination';
import { DropdownMenu } from './DropdownMenu';
import { ShowSelected } from './ShowSelected';
import { Box } from 'app/components/Box';

interface TableTopProps {
  limit: number;
  limitOptions?: number[];
  page: number;
  all: number;
  legend?: ITableLegend;
  onChangePage?: (e, page: number) => void;
  onChangeLimit?: (limit: number) => void;
  disable?: boolean;
  onChangeShowSelected?: (value: boolean) => void;
  children?: ReactNode;
}

export const TableTop: FC<TableTopProps> = props => {
  const {
    limit,
    legend,
    onChangeLimit,
    limitOptions = [],
    onChangeShowSelected,
    children,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleOpenDropDown = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <TableTopWrapper>
      <TableTopLegendBox>
        <ShowSelected onChangeShowSelected={onChangeShowSelected} />
        <Legend legend={legend} />
      </TableTopLegendBox>
      <Box display="flex" alignItems="center" gap=".5rm">
        {children}
        <Pagination {...props} handleOpenDropDown={handleOpenDropDown} />
        <DropdownMenu
          limit={limit}
          onChangeLimit={onChangeLimit}
          limitOptions={limitOptions}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </Box>
    </TableTopWrapper>
  );
};
