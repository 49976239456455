import { useState } from 'react';
import { useSearch } from 'app/hooks/useSearch';
import { IUseSearchBarController } from './types';

export const useSearchBarController = (): IUseSearchBarController => {
  const [query, setQuery] = useState<string>('');

  const [open, setOpen] = useState(false);

  const { handleSearch, loading, data, error } = useSearch({
    disableEmptyQuery: true,
  });

  const onChangeSearch = (value: string) => {
    setQuery(value);
    handleSearch(value);
  };

  const handleClickAway = () => setOpen(false);

  const handleLink = () => {
    setOpen(false);
    onChangeSearch('');
  };

  const onFocus = () => {
    setOpen(true);
    onChangeSearch(query);
  };

  return {
    query,
    data,
    loading,
    error,
    open,
    handleLink,
    onFocus,
    handleClickAway,
    onChangeSearch,
  };
};
