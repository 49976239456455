/**
 *
 * CellTagsList
 *
 */

import React from 'react';
import { ITagListCell } from '../../types';
import { StyledTagList } from '../../styled';
import { Tag } from '../../../../components/Tag';
import { Box, Popover, Typography } from '@mui/material';

export const CellTagsList = ({ data }: ITagListCell) => {
  const { tags } = data;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  if (!tags.length) {
    return (
      <Typography variant="body2" color="inherit">
        None
      </Typography>
    );
  }

  if (tags.length > 3) {
    return (
      <StyledTagList nowrap>
        {tags.slice(0, 2).map(i => (
          <Tag key={i.id}>
            <Typography variant="caption" color="inherit" noWrap>
              {i.label}
            </Typography>
          </Tag>
        ))}
        <Tag
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography variant="caption" color="inherit" noWrap>
            {`+${tags.length - 2}`}
          </Typography>
        </Tag>
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{ pointerEvents: 'none' }}
        >
          <Box p={2}>
            <StyledTagList style={{ maxWidth: '10rem' }}>
              {tags.map(i => (
                <Tag key={i.id}>
                  <Typography variant="caption" color="inherit">
                    {i.label}
                  </Typography>
                </Tag>
              ))}
            </StyledTagList>
          </Box>
        </Popover>
      </StyledTagList>
    );
  }
  return (
    <StyledTagList>
      {tags.map(i => (
        <Tag key={i.id}>
          <Typography variant="caption" color="inherit">
            {i.label}
          </Typography>
        </Tag>
      ))}
    </StyledTagList>
  );
};
