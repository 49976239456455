/**
 *
 * TableEmpty
 *
 */

import React from 'react';
import { useTable } from './TableContext';
import { useTranslation } from 'react-i18next';
import { translations } from '../../../locales/translations';
import { Box, Typography } from '@mui/material';

export function TableEmpty() {
  const { t } = useTranslation();

  const { table } = useTable();

  if (table.rows.length) {
    return null;
  }

  return (
    <Box p={6}>
      <Typography variant="h6" align="center">
        {t(translations.components.abstractTable.tableEmptyTitle)}
      </Typography>
    </Box>
  );
}
