import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';

export interface APIPayloadTotalsPartiesScopes {}

export interface APIResTotalsPartiesScopes {
  party_count: number;
  scope_count: number;
}

const mock: APIResTotalsPartiesScopes = { party_count: 25, scope_count: 135 };

export const getTotalsPartiesScopesAPI: RequestApi<
  APIPayloadTotalsPartiesScopes,
  APIResTotalsPartiesScopes
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/totals/parties_scopes`, { signal });
};
