import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { WithScopeId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { IScopeData } from 'app/containers/RootScope/types';
import { getScopeDataMock } from 'api/scopes/getScopeDataAPI/mock';

export interface APIPayloadScopeData extends WithScopeId {}

export interface APIResponseScopeData extends IScopeData {}

export const getScopeDataAPI: RequestApi<
  APIPayloadScopeData,
  APIResponseScopeData
> = async ({ scopeId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getScopeDataMock(scopeId));
      }, apiDelay);
    });
  }

  return request(`/scopes/${scopeId}`, { signal });
};
