import React, { Fragment } from 'react';
import { IScopeItem } from '../../types';
import { StyledLinkList, StyledLinkText } from '../../styled';
import { Box, Link, Popover, Typography } from '@mui/material';

interface Props {
  links: IScopeItem[];
  onScopeClick?: (e) => void;
}

export const TableLinkList = ({ links, onScopeClick }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLinkClick = e => onScopeClick && onScopeClick(e);

  if (!links.length) {
    return (
      <Typography variant="body2" color="inherit">
        None
      </Typography>
    );
  }

  if (links.length > 2) {
    return (
      <StyledLinkList>
        {links.slice(0, 2).map(i => (
          <Fragment key={i.id}>
            <StyledLinkText
              variant="body2"
              color="inherit"
              noWrap
              onClick={onLinkClick}
              id={i.id}
              style={{ cursor: 'pointer' }}
            >
              {i.label}
            </StyledLinkText>
            <Link variant="body2">{' | '}</Link>
          </Fragment>
        ))}
        <Link
          component="span"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography variant="body2" color="inherit" component="span">
            {`+${links.length - 2}`}
          </Typography>
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            disablePortal
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ pointerEvents: 'none' }}
            onClose={handlePopoverClose}
          >
            <Box p={2} style={{ pointerEvents: 'all' }}>
              <StyledLinkList className="wrap">
                {links.map((i, n, arr) => (
                  <Fragment key={i.id}>
                    <Link
                      onClick={onLinkClick}
                      id={i.id}
                      style={{ cursor: 'pointer' }}
                      variant="body2"
                    >
                      {i.label}
                    </Link>
                    <br />
                  </Fragment>
                ))}
              </StyledLinkList>
            </Box>
          </Popover>
        </Link>
      </StyledLinkList>
    );
  }
  return (
    <StyledLinkList>
      {links.map((i, n, arr) => (
        <Fragment key={i.id}>
          <StyledLinkText
            variant="body2"
            color="inherit"
            noWrap
            onClick={onLinkClick}
            id={i.id}
            style={{ cursor: 'pointer' }}
          >
            {i.label}
          </StyledLinkText>
          {n !== arr.length - 1 && <Link>{' | '}</Link>}
        </Fragment>
      ))}
    </StyledLinkList>
  );
};
