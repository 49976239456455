/**
 *
 * CellTextWithTooltip
 *
 */

import React from 'react';
import { ITextWithTooltipCell } from '../../types';
import { TextWithMods } from '../../../../components/TextWithMods';
import { Tooltip, Typography } from '@mui/material';

export const CellTextWithTooltip = ({ data }: ITextWithTooltipCell) => {
  const { text, tooltip, mods } = data;

  return (
    <Tooltip
      title={<Typography variant="body2">{tooltip}</Typography>}
      aria-label={text}
      placement="bottom"
    >
      <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
        <TextWithMods mods={mods || []}>{text}</TextWithMods>
      </Typography>
    </Tooltip>
  );
};
