import { faker } from '@faker-js/faker';
import { APIResponseCurrentUser } from 'api/users/getCurrentUserApi/index';
import { IUser } from 'types/user';

const { person, string, internet, phone, company } = faker;

export function createRandomUser(): IUser {
  return {
    user_id: string.uuid(),
    name: person.fullName(),
    email: internet.email(),
    phone_number: phone.number(),
    position: person.jobTitle(),
    nickname: internet.userName(),
    roles: ['tprm-admin'],
    user_metadata: {
      business_unit: company.name(),
      phone_number: phone.number(),
      position: person.jobTitle(),
    },
  };
}

export const mock: APIResponseCurrentUser = createRandomUser();
