import { actions } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlerts } from '../selectors';
import { IAlert, IUseAlerts } from './types';

export const useAlerts = (): IUseAlerts => {
  const alerts = useSelector(selectAlerts);

  const dispatch = useDispatch();

  const addDefaultErrorAlert = (transactionId: string) =>
    dispatch(actions.addDefaultErrorAlert({ transactionId }));

  const addAlert = (alert: IAlert) => dispatch(actions.addAlert(alert));

  return { alerts, addAlert, addDefaultErrorAlert };
};
