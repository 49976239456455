import { faker } from '@faker-js/faker';
import { APIResponseRiskDomains } from 'api/thirdParties/getRiskDomainsAPI/index';
import { IRiskDomainTag } from 'app/containers/RootThirdParty/types';

const { random } = faker;

const count = 10;

export const buildDomain = (num: number): IRiskDomainTag => {
  return {
    risk_domain: random.words(2),
    risk_domain_id: `${num}_risk_domain_id`,
    impact: random.words(2),
    impact_id: `${num}_impact`,
    cer_level: random.words(2),
  };
};

export const risk_domainsMock = Array.from(
  Array(Math.floor(Math.random() * count) + 1).keys(),
).map(buildDomain);

export const mock: APIResponseRiskDomains = {
  risk_domains: risk_domainsMock,
  // risk_domains: [],
};
