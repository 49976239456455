import { ReactComponentElement } from 'react';
import { ReactComponent as expand_more } from 'app/components/Icon/svg/expand_more.svg';
import { ReactComponent as analizing } from 'app/components/Icon/svg/analizing.svg';
import { ReactComponent as check } from 'app/components/Icon/svg/check.svg';
import { ReactComponent as close } from 'app/components/Icon/svg/close.svg';
import { ReactComponent as upload } from 'app/components/Icon/svg/upload.svg';
import { ReactComponent as question } from 'app/components/Icon/svg/question.svg';
import { ReactComponent as download } from 'app/components/Icon/svg/download.svg';
import { ReactComponent as dropdown_up } from 'app/components/Icon/svg/dropdown_up.svg';
import { ReactComponent as dropdown_down } from 'app/components/Icon/svg/dropdown_down.svg';
import { ReactComponent as exclamation_mark_filed } from 'app/components/Icon/svg/exclamation_mark_filed.svg';
import { ReactComponent as lock } from 'app/components/Icon/svg/lock.svg';
import { ReactComponent as eye } from 'app/components/Icon/svg/eye.svg';
import { ReactComponent as closed_eye } from 'app/components/Icon/svg/closed_eye.svg';
import { ReactComponent as export_icon } from 'app/components/Icon/svg/export_icon.svg';
import { ReactComponent as compliant } from 'app/components/Icon/svg/compliant.svg';
import { ReactComponent as non_compliant } from 'app/components/Icon/svg/non_compliant.svg';
import { ReactComponent as insight } from 'app/components/Icon/svg/insight.svg';
import { ReactComponent as group_compliant } from 'app/components/Icon/svg/group_compliant.svg';
import { ReactComponent as group_non_compliant } from 'app/components/Icon/svg/group_non_compliant.svg';
import { ReactComponent as group_insight } from 'app/components/Icon/svg/group_insight.svg';
import { ReactComponent as insight_type_4th_party } from 'app/components/Icon/svg/insight_type_4th_party.svg';
import { ReactComponent as insight_type_control } from 'app/components/Icon/svg/insight_type_control.svg';
import { ReactComponent as insight_type_document } from 'app/components/Icon/svg/insight_type_document.svg';
import { ReactComponent as insight_type_location } from 'app/components/Icon/svg/insight_type_location.svg';
import { ReactComponent as insight_type_questionnaire } from 'app/components/Icon/svg/insight_type_questionnaire.svg';
import { ReactComponent as insight_type_online_asset } from 'app/components/Icon/svg/insight_type_online_asset.svg';
import { ReactComponent as insight_type_person } from 'app/components/Icon/svg/insight_type_person.svg';
import { ReactComponent as insight_type_software_package } from 'app/components/Icon/svg/insight_type_software_package.svg';
import { ReactComponent as insight_type_blackkite } from 'app/components/Icon/svg/insight_type_blackkite.svg';
import { ReactComponent as insight_type_cisa } from 'app/components/Icon/svg/insight_type_cisa.svg';
import { ReactComponent as event_info } from 'app/components/Icon/svg/event_info.svg';
import { ReactComponent as event_reminder } from 'app/components/Icon/svg/event_reminder.svg';
import { ReactComponent as event_risk } from 'app/components/Icon/svg/event_risk.svg';
import { ReactComponent as event_operational } from 'app/components/Icon/svg/event_operational.svg';
import { ReactComponent as sad_face } from 'app/components/Icon/svg/sad_face.svg';
import { ReactComponent as attention } from 'app/components/Icon/svg/attention.svg';
import { ReactComponent as check_box_blank } from 'app/components/Icon/svg/check_box_blank.svg';
import { ReactComponent as check_box_icon } from 'app/components/Icon/svg/check_box_icon.svg';
import { ReactComponent as check_box_interminate } from 'app/components/Icon/svg/check_box_interminate.svg';
import { ReactComponent as radio_blank } from 'app/components/Icon/svg/radio_blank.svg';
import { ReactComponent as radio_select } from 'app/components/Icon/svg/radio_select.svg';
import { ReactComponent as location_square } from 'app/components/Icon/svg/location_square.svg';
import { ReactComponent as location } from 'app/components/Icon/svg/location.svg';
import { ReactComponent as domain } from 'app/components/Icon/svg/domain.svg';
import { ReactComponent as id } from 'app/components/Icon/svg/id.svg';
import { ReactComponent as fulscreen_open } from 'app/components/Icon/svg/fulscreen_open.svg';
import { ReactComponent as fulscreen_close } from 'app/components/Icon/svg/fulscreen_close.svg';
import { ReactComponent as no_access } from 'app/components/Icon/svg/no_access.svg';
import { ReactComponent as oops } from 'app/components/Icon/svg/oops.svg';
import { ReactComponent as remove } from 'app/components/Icon/svg/remove.svg';
import { ReactComponent as rejection } from 'app/components/Icon/svg/rejection.svg';
import { ReactComponent as edit } from 'app/components/Icon/svg/edit.svg';

export const iconsDictionary = {
  expand_more,
  analizing,
  check,
  close,
  upload,
  question,
  download,
  dropdown_up,
  dropdown_down,
  exclamation_mark_filed,
  lock,
  eye,
  closed_eye,
  export_icon,
  compliant,
  non_compliant,
  insight,
  group_compliant,
  group_non_compliant,
  group_insight,
  insight_type_4th_party,
  insight_type_control,
  insight_type_document,
  insight_type_location,
  insight_type_questionnaire,
  insight_type_online_asset,
  insight_type_person,
  insight_type_software_package,
  insight_type_blackkite,
  insight_type_cisa,
  event_info,
  event_reminder,
  event_risk,
  event_operational,
  sad_face,
  attention,
  check_box_blank,
  check_box_icon,
  check_box_interminate,
  radio_blank,
  radio_select,
  location_square,
  location,
  domain,
  id,
  fulscreen_open,
  fulscreen_close,
  no_access,
  oops,
  remove,
  rejection,
  edit,
};

export type IconsMapType = {
  [key in keyof typeof iconsDictionary]: ReactComponentElement<any>;
};
