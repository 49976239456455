export enum StyleConstants {
  HEADER_HEIGHT = '3.2rem',
  HEADER_PADDING_BOTTOM = '2rem',
  HEADER_SEARCH_BAR_MAX_WIDTH = '600px',
  ASIDE_WIDTH = '3.2rem',
  DESKTOP_PAGE_BOX_PADDING = '1.5rem',

  //Moderation Tool app constants
  MODERATION_HEADER_HEIGHT = '4.5rem',
  MODERATION_EVIDENCE_LIST_INITIAL_WIDTH = '30rem',
}
