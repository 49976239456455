/**
 *
 * useFilterController
 *
 */
import {
  FilterProps,
  IFilterField,
  ILogicOperator,
  IPrimitiveFilter,
  IUseFilterController,
} from './types';
import React, { useEffect, useState } from 'react';
import { deepCompare } from 'utils/deepCompare';

const initFieldState: IPrimitiveFilter = {
  operator: '',
  field: '',
  value: '',
};

export function useFilterController(
  props: FilterProps,
  editBoxAnchor?: React.RefObject<HTMLDivElement> | null,
): IUseFilterController {
  const { onChangeFilter, filterData } = props;

  const { default_filter, filters } = filterData;

  useEffect(() => {
    if (editBoxAnchor) {
      setEditFilterAnchor(editBoxAnchor.current);
    }
  }, [editBoxAnchor]);

  const setCurrentFilter = () => {
    if (props.currentFilter === undefined && default_filter) {
      return filters?.find(i => i.id === default_filter)?.definition;
    }
    return props.currentFilter;
  };

  const currentFilter = setCurrentFilter();

  const [isEditFilterOpen, setIsEditFilterOpen] = useState<boolean>(false);

  const [highlightedField, setHighlightedField] = useState<IPrimitiveFilter>();

  const [editFilterAnchor, setEditFilterAnchor] =
    useState<HTMLDivElement | null>(null);

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const openEditFilter = () => {
    setEditState();
    setIsEditFilterOpen(true);
  };

  const closeEditFilter = () => {
    setIsEditFilterOpen(false);
    setHighlightedField(undefined);
  };

  const doHighlightField = (item: IPrimitiveFilter) => {
    setHighlightedField(item);
    openEditFilter();
  };

  const onRemoveFilterItem = (item: IPrimitiveFilter) => {
    if (currentFilter && currentFilter?.items.length > 1) {
      onChangeFilter({
        ...currentFilter,
        items: currentFilter?.items.filter(i => i !== item),
      });
    } else {
      onChangeFilter(null);
    }
  };

  const [mainOperator, setMainOperator] = useState<ILogicOperator>('and');

  const toggleMainOperator = val => setMainOperator(val ? 'or' : 'and');

  const initFieldsState: IPrimitiveFilter[] = [initFieldState];

  const [fields, setFields] = useState<IFilterField[]>(initFieldsState);
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(true);

  useEffect(() => {
    if (fields.length === 1 && deepCompare(fields[0], initFieldState)) {
      setIsEmptyFilter(true);
    } else {
      setIsEmptyFilter(false);
    }
  }, [fields]);

  const doClearAllFields = () => setFields(initFieldsState);

  const setEditState = () => {
    if (currentFilter) {
      setFields(currentFilter.items);
    } else {
      doClearAllFields();
    }
  };

  const removeField = (index: number) => {
    if (fields.length > 1) {
      setFields(prev => prev.filter((i, n) => n !== index));
      return null;
    }

    if (deepCompare(fields[0], initFieldState)) {
      closeEditFilter();
      return null;
    }

    doClearAllFields();
  };

  const addField = () => setFields(prev => [...prev, initFieldState]);

  const doSaveToState = (field: IFilterField, index: number) => {
    let newState: IFilterField[] = [...fields];
    newState[index] = field;
    setFields(newState);
  };

  const doApplyFilter = () => {
    closeEditFilter();

    if (deepCompare(fields[0], initFieldState)) {
      onChangeFilter(null);
      return null;
    }

    onChangeFilter({ operator: mainOperator, items: fields });
  };

  const createNewFilter = () => {
    setFields(initFieldsState);
    setIsEditFilterOpen(true);
  };

  return {
    ...props,
    currentFilter,
    onRemoveFilterItem,
    doHighlightField,
    openEditFilter,
    setEditState,
    closeEditFilter,
    toggleMainOperator,
    removeField,
    doClearAllFields,
    addField,
    doSaveToState,
    doApplyFilter,
    createNewFilter,
    setHighlightedField,
    isEditFilterOpen,
    highlightedField,
    editFilterAnchor,
    mainOperator,
    fields,
    isSelectOpen,
    setIsSelectOpen,
    isEmptyFilter,
  };
}
