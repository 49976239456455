/**
 *
 * MainView
 *
 */

import React from 'react';
import { useRoot } from 'app/containers/Root/useRoot';
import { StyledAvatar, StyledButton } from './styled';
import { useCurrentUserNav } from './Context';
import { DropMenu } from './DropMenu';

export function MainView() {
  const { current_user } = useRoot();

  const { openMenu } = useCurrentUserNav();

  return (
    <>
      <StyledButton onClick={openMenu}>
        <StyledAvatar>{current_user?.name?.slice(0, 1)}</StyledAvatar>
      </StyledButton>
      <DropMenu />
    </>
  );
}
