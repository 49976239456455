import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { PartiesTable } from './PartiesTable';
import queryString from 'query-string';
import { useNotificationCenter } from '../Context';
import { useHistory } from 'react-router-dom';
import { Box } from 'app/components/Box';
import { Typography } from 'app/components/Typography';

export const Parties = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { settings } = useNotificationCenter();

  const { selected_parties } = settings;

  useEffect(() => {
    const { search } = history.location;

    const parsedSearch = queryString.parse(search);

    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        selected_ids: JSON.stringify(selected_parties || []),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography variant="h6" m="0 0 0.5rem 0">
        {t(translations.notificationSettings.followingList)}:
      </Typography>
      {selected_parties && <PartiesTable />}
    </Box>
  );
};
