import { faker } from '@faker-js/faker';
import { APIResponseParties } from 'api/thirdParties/getPartiesAPI';
import { buildMockTable } from 'api/utils';
import { IHeaderItem, ITableRow } from 'app/containers/AbstractTable/types';
import { buildMockFilterData } from 'api/mockFilterData';

const rowsCount = 10;

const headerItems: IHeaderItem[] = [
  {
    id: 'name',
    label: 'Name',
    sublabel: '',
    orderable: true,
  },
  {
    id: 'impact',
    label: 'Inherent Risk',
    sublabel: '',
    orderable: true,
  },
  {
    id: 'probability_level',
    label: 'Residual Risk',
    sublabel: '',
    orderable: false,
  },
  {
    id: 'type',
    label: 'Type',
    sublabel: '',
    orderable: true,
  },
  {
    id: 'relationship_manager',
    label: 'Relationship Manager',
    sublabel: '',
    orderable: false,
  },
  {
    id: 'business_unit',
    label: 'Business Unit',
    sublabel: '',
    orderable: true,
  },
];

const { internet, random } = faker;

const buildRows = (count: number): ITableRow => {
  return {
    id: `${count}_scope_row`,
    cells: [
      {
        type: 'text',
        data: {
          text: random.words(),
          mods: ['bold'],
        },
      },
      {
        type: 'text',
        data: {
          text: random.words(),
        },
      },
      {
        type: 'text',
        data: {
          text: random.words(),
        },
      },
      {
        type: 'text',
        data: {
          text: random.words(),
        },
      },
      {
        type: 'text',
        data: {
          text: internet.email(),
        },
      },
      {
        type: 'text',
        data: {
          text: random.words(2),
        },
      },
    ],
    order_value: 'name',
    type: 'link-by-id',
  };
};

const rows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildRows);

const table = buildMockTable(headerItems, rows);

export const mock: APIResponseParties = {
  table,
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 54,
  page: 1,
  filter_data: buildMockFilterData(),
  legend: {
    items: [],
  },
};
