import React, { FC } from 'react';

import MUILink, { LinkProps as MuiLInkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { MuiProps } from 'types/common';

type LinkProps = MuiLInkProps & MuiProps;

export const StyledMUILink = styled(MUILink)`
  cursor: pointer;
`;

export const Link: FC<LinkProps> = props => {
  return <StyledMUILink {...props} />;
};
