import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import ReactGA from 'react-ga';

import { NotFoundPage } from '../NotFoundPage/Loadable';
import { useRoot } from 'app/containers/Root/useRoot';
import { PageName } from 'types/user';
import { RootAdmin } from '../RootAdmin/Loadable';
import { RootRiskBoard } from '../RootRiskBoard/Loadable';
import { RootThirdParty } from '../RootThirdParty/Loadable';
import { RootAggregationRisk } from '../RootAggregationRisk/Loadable';
import { NotificationCenter } from './Notifications/NotificationCenter';
import { usePermissions } from 'app/containers/Root/usePermissions';

export const SentryRoute = Sentry.withSentryRouting(Route);

const selectRouteComponent = (id: PageName) => {
  switch (id) {
    case 'risk_board':
      return RootRiskBoard;
    case 'third_party':
      return RootThirdParty;
    case 'aggregation_risk':
      return RootAggregationRisk;
    default:
      return NotFoundPage;
  }
};

export function AppRouter() {
  const history = useHistory();

  const { isAdmin } = usePermissions();

  const { aside, current_user } = useRoot();

  useEffect(() => {
    ReactGA.pageview(`${history.location.pathname}${history.location.search}`);
  }, [history.location.pathname, history.location.search]);

  useEffect(() => {
    const pathname = sessionStorage.getItem('t_pathname');

    const search = sessionStorage.getItem('t_search');

    if (current_user && pathname && pathname !== '/') {
      history.push({
        pathname,
        search: search || '',
      });

      sessionStorage.removeItem('t_pathname');

      sessionStorage.removeItem('t_search');
    }
  }, [current_user, history]);

  if (!aside || !current_user) {
    return null;
  }

  const activeRoutes = aside.filter(i => !i.disabled);

  const defaultHomepage =
    aside.find(i => i.is_homepage)?.route || activeRoutes[0].route;

  return (
    <Switch>
      {Boolean(activeRoutes.length) && (
        <Redirect exact from="/" to={defaultHomepage} />
      )}
      {activeRoutes.map(i => (
        <SentryRoute
          key={i.id}
          path={i.route}
          component={selectRouteComponent(i.id)}
        />
      ))}
      {isAdmin && <SentryRoute path="/admin" component={RootAdmin} />}
      <SentryRoute path="/notification-center" component={NotificationCenter} />
      <SentryRoute component={NotFoundPage} />
    </Switch>
  );
}
