import React, { FC } from 'react';
import MUIMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import { MuiProps } from 'types/common';
import { styled } from '@mui/material/styles';

export type MenuItemProps = MuiMenuItemProps & MuiProps;

const StyledMenuItem = styled(MUIMenuItem)`
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const MenuItem: FC<MenuItemProps> = props => {
  return <StyledMenuItem {...props} />;
};
