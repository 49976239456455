export class ResponseError extends Error {
  public response: Response;
  public transactionId: string;

  constructor(response: Response, transactionId: string) {
    super(response.statusText);
    this.response = response;
    this.transactionId = transactionId;
  }
}

export type ErrorType =
  | 'crash'
  | 'block_crash'
  | 'no_access'
  | 'not_found'
  | 'fail'
  | 'validation';

export interface ErrorBase<T extends ErrorType> {
  type: T;
  transactionId: string;
}

export interface CrashError extends ErrorBase<'crash'> {
  message?: string;
}

export interface BlockCrashError extends ErrorBase<'block_crash'> {}
export interface NoAccessError extends ErrorBase<'no_access'> {
  message: string;
}

export interface NotFoundError extends ErrorBase<'not_found'> {}

export interface FailureError extends ErrorBase<'fail'> {
  message: string;
}

export interface ValidationError<T> extends ErrorBase<'validation'> {
  field: T;
  message: string;
}

export type IError =
  | CrashError
  | BlockCrashError
  | NotFoundError
  | NoAccessError
  | ValidationError<string>
  | FailureError;
