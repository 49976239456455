/**
 *
 * NavTabs
 *
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledTab, StyledTabs } from '../styled';
import { ITabsProps } from '../types';

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label: string;
  href: string;
  disabled?: boolean;
}

function LinkTab(props: LinkTabProps) {
  const history = useHistory();
  return (
    <StyledTab
      {...props}
      component="a"
      onClick={event => {
        event.preventDefault();
        history.push(props.href);
      }}
    />
  );
}

interface INavTabsProps extends ITabsProps {}

export default function NavTabs({
  tabs,
  centered,
  removeDivider,
}: INavTabsProps) {
  const history = useHistory();

  const currentTab = tabs.find(i =>
    history.location.pathname.includes(i.linkTo || ''),
  );

  const value = currentTab ? tabs.indexOf(currentTab) : 0;

  return (
    <StyledTabs
      centered={centered}
      value={value}
      aria-label="nav tabs"
      textColor="primary"
      indicatorColor="primary"
      removeDivider={removeDivider}
    >
      {tabs.map((i, n) => (
        <LinkTab
          key={i.label}
          label={i.label}
          href={i.linkTo || '/'}
          disabled={i.disabled}
          data-test={`nav_${i.id}`}
          {...a11yProps(n)}
        />
      ))}
    </StyledTabs>
  );
}
