/**
 *
 * FilterTagValues
 *
 */
import React, { Fragment } from 'react';
import { IFilteringValueOption } from './types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { translations } from 'locales/translations';

export interface IFilterTagValues {
  values: string | string[];
  options: IFilteringValueOption[];
}

export const FilterTagValues = ({ values, options }: IFilterTagValues) => {
  const { t } = useTranslation();

  if (Array.isArray(values) || options.length) {
    const valuesWithLabels = options.filter(i => values.includes(i.value));
    return (
      <Typography variant="body2">
        {valuesWithLabels.map((i, n, array) => (
          <Fragment key={i.value}>
            <strong>{i.label}</strong>
            {array.length - 1 !== n && (
              <span style={{ margin: '0 .3rem' }}>
                {' '}
                <i>{t(translations.components.filter.and)}</i>{' '}
              </span>
            )}
          </Fragment>
        ))}
      </Typography>
    );
  }
  return (
    <Typography variant="body2">
      <strong>{values}</strong>
    </Typography>
  );
};
