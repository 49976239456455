/**
 *
 * TableTop
 *
 */
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const TableTopWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  & > * {
    margin: 0 2rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

export const TableTopLegendBox = styled('div')`
  display: flex;
  align-items: center;

  & > * {
    margin: 0 2rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
`;

export const StyledCheckBoxWrapper = styled('div')`
  padding: 0 0 0 0.65rem;
`;

export const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.layout.pagination.buttonColor};

  &:hover {
    color: ${({ theme }) => theme.layout.pagination.buttonColorHover};
  }
`;

export const LegendWrapper = styled('ul')`
  display: flex;
  list-style: none;
  padding: 0;

  & > * {
    margin: 0 1rem 0 0;
    &:last-child {
      margin: 0;
    }
  }

  li {
    display: flex;
    align-items: center;

    svg {
      margin: 0 0.5rem 0 0;
      width: 0.5rem;
    }
  }
`;
