import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { ScopeBlockType } from 'app/containers/RootScope/types';
import { getInitScopeDataMock } from 'api/scopes/getInitScopeDataAPI/mock';

export interface APIPayloadInitScopeData {}

export type APIResponseInitScopeData = ScopeBlockType[];

export const getInitScopeDataAPI: RequestApi<
  APIPayloadInitScopeData,
  APIResponseInitScopeData
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getInitScopeDataMock());
      }, apiDelay);
    });
  }

  return request(`/scopes/page_form`, { signal });
};
