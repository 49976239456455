import { selectRoot } from 'app/containers/Root/selectors';
import { actions } from 'app/containers/Root/slice';
import i18next from 'i18next';
import { call, put, select } from 'redux-saga/effects';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';

export function* getLocalization() {
  if (USE_MOCK) {
    return;
  }

  try {
    const { current_user } = yield select(selectRoot);

    const translations = yield call(
      request,
      `/localization/${current_user.language || 'en'}`,
    );

    i18next.addResourceBundle('en', 'translation', translations);
  } catch (e: any) {
    yield put(
      actions.onError({ type: 'crash', transactionId: e.transactionId }),
    );
  }
}
