import { ReactNode } from 'react';
import { StyledCloseButton, StyledTitle } from './styled';
import { Icon } from 'app/components/Icon';
import { Typography } from 'app/components/Typography';

interface IAlertTitleProps {
  children?: ReactNode;
  onClose?: () => void;
  error?: boolean;
}

export const AlertTitle = ({ children, onClose, error }: IAlertTitleProps) => (
  <StyledTitle>
    {error && <Icon name="sad_face" color="#EB5757" />}
    <Typography variant="body1" color="inherit" component="div">
      {children}
    </Typography>
    {onClose && (
      <StyledCloseButton
        data-test="button_close"
        aria-label="close"
        onClick={onClose}
      >
        <Icon name="close" />
      </StyledCloseButton>
    )}
  </StyledTitle>
);
