import React from 'react';

export const AsideRiskBoard = () => (
  <svg
    width="50"
    height="50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <circle cx="33.5" cy="16.5" r="5.5" className="colored" />
    <circle cx="16.5" cy="33.5" r="4" stroke="#827F81" />
    <circle cx="18.5" cy="19.5" r="2" stroke="#827F81" />
    <circle cx="31.5" cy="32.5" r="3" stroke="#827F81" />
    <path
      d="M38 25h2v1h-2v-1zM21 25h2v1h-2v-1zM30 25h2v1h-2v-1zM13 25h2v1h-2v-1zM34 25h2v1h-2v-1zM17 25h2v1h-2v-1zM26 25h2v1h-2v-1zM9 25h2v1H9v-1zM25 39v2h-1v-2h1zM25 22v2h-1v-2h1zM25 31v2h-1v-2h1zM25 14v2h-1v-2h1zM25 35v2h-1v-2h1zM25 18v2h-1v-2h1zM25 27v2h-1v-2h1zM25 10v2h-1v-2h1z"
      fill="#827F81"
    />
  </svg>
);
