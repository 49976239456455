import { faker } from '@faker-js/faker';
import {
  APIResponseRiskBoardMatrixCellGroups,
  ITrendGroup,
} from 'api/riskBoard/getRiskBoardMatrixCellGroupsAPI/index';

const rowsCount = 10;

const { datatype, random } = faker;

const buildTrendGroup = (count: number): ITrendGroup => {
  return {
    id: `${count}_group`,
    label: random.word(),
    count: datatype.number({ max: 100 }).toString(),
  };
};

const groups = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildTrendGroup);

export const mock: APIResponseRiskBoardMatrixCellGroups = {
  groups,
  trend_label: 'RBM/Trends/Option|Last 6 months',
  impact: 'High Inherent Risk',
  probability: 'Low Residual Risk',
};
