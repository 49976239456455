import React, { FC } from 'react';

import { styled } from '@mui/material';
import MUIPopover, { PopoverProps } from '@mui/material/Popover';

export const StyledPopover = styled(MUIPopover)`
  .MuiPopover-paper {
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: 6px;
    box-shadow: ${({ theme }) => theme.shadows[2]};
  }
`;

export const Popover: FC<PopoverProps> = props => {
  return <StyledPopover {...props} />;
};
