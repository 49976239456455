import { useFilter } from './FilterContext';
import { InputSwitch } from 'app/components/Inputs/InputSwitch';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Box, Typography } from '@mui/material';
import { StyledFieldsBox } from './styled';
import { EditFilterField } from './EditFilterField';

export function EditFilter() {
  const { t } = useTranslation();

  const { mainOperator, toggleMainOperator, fields } = useFilter();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        p="0 3rem"
        className="paddingConfig"
      >
        <Typography
          color="textSecondary"
          variant="body1"
          style={{ margin: '.9rem 0px 0.5rem' }}
        >
          {t(translations.components.filter.addOrEdit)}
        </Typography>
        <InputSwitch
          off={t(translations.components.filter.and)}
          on={t(translations.components.filter.or)}
          value={mainOperator === 'or'}
          handleChange={toggleMainOperator}
          test_id="input_switch_operator"
        />
      </Box>
      <StyledFieldsBox className="paddingConfig">
        {fields.map((i, n, array) => (
          <EditFilterField
            key={n}
            fieldItem={i}
            isLast={array.length === n + 1}
            index={n}
          />
        ))}
      </StyledFieldsBox>
    </>
  );
}
