import { IMatrix } from 'types/matrix';

export const matrixBase: Omit<IMatrix, 'cells'> = {
  x_axis: {
    label: 'Residual Risk',
    values: [
      {
        label: 'Low',
        p: 0,
      },
      {
        label: 'Moderate',
        p: 1,
      },
      {
        label: 'High',
        p: 2,
      },
      {
        label: 'Very High',
        p: 3,
      },
    ],
  },
  y_axis: {
    label: 'Inherent Risk',
    values: [
      {
        label: 'Low',
        p: 0,
      },
      {
        label: 'Moderate',
        p: 1,
      },
      {
        label: 'High',
        p: 2,
      },
    ],
  },
  boundaries: [
    {
      polyline: [
        {
          x: 0,
          y: 0,
        },
        {
          x: 0,
          y: 0,
        },
        {
          x: 0,
          y: 0,
        },
      ],
    },
    {
      polyline: [
        {
          x: 0,
          y: 1,
        },
        {
          x: 1,
          y: 1,
        },
        {
          x: 1,
          y: 0,
        },
      ],
    },
    {
      polyline: [
        {
          x: 0,
          y: 2,
        },
        {
          x: 2,
          y: 2,
        },
        {
          x: 2,
          y: 0,
        },
      ],
    },
    {
      polyline: [
        {
          x: 0,
          y: 3,
        },
        {
          x: 3,
          y: 3,
        },
        {
          x: 3,
          y: 0,
        },
      ],
    },
    {
      polyline: [
        {
          x: 3,
          y: 3,
        },
        {
          x: 4,
          y: 3,
        },
        {
          x: 4,
          y: 0,
        },
      ],
    },
  ],
};
