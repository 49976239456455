import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import { IRiskBoardMatrixTabIds } from 'app/containers/RootRiskBoard/types';
import {
  IAbstractPageData,
  IAbstractTable,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { mock } from 'api/riskBoard/getRiskBoardMatrixCellAPI/mock';

export interface APIPayloadRiskBoardMatrixCell extends IPageParams {
  tab_id: IRiskBoardMatrixTabIds;
  cell_id: string;
}

export interface APIResponseRiskBoardMatrixCell
  extends IAbstractTable,
    IAbstractPageData {
  impact: string;
  probability: string;
}

export const getRiskBoardMatrixCellAPI: RequestApi<
  APIPayloadRiskBoardMatrixCell,
  APIResponseRiskBoardMatrixCell
> = async ({ cell_id, tab_id, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(
    `/risk_board/totals/${tab_id}/${cell_id}?${stringifyParams(props)}`,
    {
      signal,
    },
  );
};
