import { ScopeBlockContactDetails } from 'app/containers/RootScope/types';

export const scopeInitBlockContactDetailsMock: ScopeBlockContactDetails = {
  id: 'contact_details',
  type: 'contact_details',
  fields_list: [
    {
      id: 'relationship_manager',
      type: 'contact',
      label: 'Relationship Manager',
      required: true,
      fields_list: [
        {
          id: 'relationship_manager_aggregator_button',
          type: 'aggregator_button',
          label: 'CommonBlock/Field|Aggregator',
          required: false,
        },
        {
          id: 'relationship_manager_email',
          type: 'autocomplete_email',
          label: 'Email',
          placeholder: 'Enter email',
          required: true,
        },
        {
          id: 'relationship_manager_name',
          type: 'text',
          label: 'Name',
          placeholder: 'Enter name',
          required: true,
        },
        {
          id: 'blank_2',
          type: 'blank',
        },
      ],
    },
  ],
};
