import {
  NotificationField,
  NotificationFieldsStateType,
} from 'app/containers/Root/Notifications/Form/types';

export const getAbstractFormState = (
  fields: NotificationField[],
): NotificationFieldsStateType => {
  const state = {};

  const valuesLoop = (fields: NotificationField[]) => {
    fields.forEach(field => {
      const { id, type } = field;

      if (type === 'group') {
        valuesLoop(field.fields_list || []);
      } else {
        state[id] = field;
      }
    });
  };

  valuesLoop(fields);

  return state;
};

export const getAbstractForm = (
  state: NotificationFieldsStateType,
): NotificationField[] =>
  Object.values(state).filter(
    field => field.type !== 'blank' && field.type !== 'separator',
  );
