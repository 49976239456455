import { useDispatch, useSelector } from 'react-redux';
import { IOnChangeEventFieldValue, IUseNotifications } from './types';
import { selectNotifications } from './selectors';
import { actions } from './slice';
import { useHistory } from 'react-router-dom';
import { handleCloseSettings, handleOpenSettings } from './utils';
import { useEffect } from 'react';
import { parseSearch } from 'app/hooks/usePageParams/utils';

export const useNotifications = (): IUseNotifications => {
  const history = useHistory();

  const state = useSelector(selectNotifications);

  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname, search } = history.location;

    if (pathname.includes('parties')) {
      const pageParams = parseSearch(search);

      const { selected_ids } = pageParams;

      if (selected_ids) {
        dispatch(
          actions.onChangeSelectedParties({ selected_parties: selected_ids }),
        );
      }
    }
  }, [dispatch, history.location, history.location.search]);

  const getSettings = () => {
    dispatch(actions.getSettings());
  };

  const saveSettings = () => {
    dispatch(actions.saveSettings());
  };

  const onChangeEventFieldValue = (props: IOnChangeEventFieldValue) => {
    dispatch(actions.onChangeEventFieldValue(props));
  };

  const openSettings = () => {
    handleOpenSettings(history);
  };
  const closeSettings = () => {
    handleCloseSettings(history);
  };

  return {
    ...state,
    openSettings,
    closeSettings,
    getSettings,
    saveSettings,
    onChangeEventFieldValue,
  };
};
