import React from 'react';
import { StyledMultiTag, StyledMultiTagItem } from '../../styled';
import { Box, Typography } from '@mui/material';
import { SelectOptionType } from 'app/components/Inputs/Select_old';
import { useNotificationCenter } from '../../Context';
import { IAbstractFieldMultiTag } from 'app/containers/Root/Notifications/Form/types';
import { useSelector } from 'react-redux';
import { selectEventFormField } from 'app/containers/Root/Notifications/selectors';

interface MultiTagItemProps extends SelectOptionType {
  selected?: boolean;
  onSelect: (value?: string) => void;
}

const MultiTagItem = ({
  value,
  label,
  selected,
  onSelect,
}: MultiTagItemProps) => {
  const onClick = () => {
    onSelect(value);
  };

  return (
    <StyledMultiTagItem selected={selected} onClick={onClick}>
      <Typography color="inherit" variant="caption">
        {label}
      </Typography>
    </StyledMultiTagItem>
  );
};

interface FieldMultiTagProps extends IAbstractFieldMultiTag {}

export const FieldMultiTag = ({ id, label, options }: FieldMultiTagProps) => {
  const { value } = useSelector(
    selectEventFormField(id),
  ) as IAbstractFieldMultiTag;

  const { onChangeEventFieldValue } = useNotificationCenter();

  const onSelect = selectedValue => {
    const newValue = value?.includes(selectedValue)
      ? value?.filter(i => i !== selectedValue)
      : value
      ? [...value, selectedValue]
      : [selectedValue];

    onChangeEventFieldValue({ field_id: id, value: newValue });
  };

  return (
    <StyledMultiTag>
      <Typography variant="body2" component="label">
        {label}
      </Typography>
      <Box display="flex" gap=".5rem">
        {options.map(option => (
          <MultiTagItem
            key={option.value}
            onSelect={onSelect}
            selected={value?.includes(option.value)}
            {...option}
          />
        ))}
      </Box>
    </StyledMultiTag>
  );
};
