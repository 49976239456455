import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.notifications || initialState;

export const selectNotifications = createSelector(
  [selectDomain],
  notifications => notifications,
);

export const selectEventForm = createSelector(
  [selectDomain],
  notifications => notifications.settings.eventsForm,
);

export const selectEventFormField = (field_id: string) =>
  createSelector(
    [selectDomain],
    notifications =>
      notifications.settings.eventsForm &&
      notifications.settings.eventsForm[field_id],
  );
