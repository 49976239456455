/**
 *
 * LoadingOverlay
 *
 */

import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

interface LoadingOverlayProps {
  loading?: boolean;
}

const OverlayContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'loading',
})<LoadingOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  ${({ loading }) =>
    loading
      ? `
      opacity: 1;
      pointer-events: all;
  `
      : `
      opacity: 0;
      pointer-events: none;
  `}
`;

export function LoadingOverlay({ loading }: LoadingOverlayProps) {
  return (
    <OverlayContainer loading={loading}>
      <CircularProgress />
    </OverlayContainer>
  );
}
