import { IPopupBlock, TableCellPopupProps } from '../types';
import { StyledLocationAlertBox } from '../styled';
import { BlockTitle } from './BlockTitle';
import { BlockText } from './BlockText';
import { BlockProperty } from './BlockProperty';
import { BlockLinkButton } from './BlockLinkButton';
import { IconButton } from 'app/components/Buttons/IconButton';
import { Icon } from 'app/components/Icon';

const TableCellPopupBody = (block: IPopupBlock) => {
  switch (block.type) {
    case 'title':
      return <BlockTitle {...block} />;
    case 'text':
      return <BlockText {...block} />;
    case 'property':
      return <BlockProperty {...block} />;
    case 'link_button':
      return <BlockLinkButton {...block} />;
    default:
      return null;
  }
};

export const TableCellPopup = ({ popup, onClose }: TableCellPopupProps) => (
  <StyledLocationAlertBox>
    <IconButton className="close" size="small" onClick={onClose && onClose}>
      <Icon name="close" />
    </IconButton>
    {popup.blocks.map((i, n) => (
      <TableCellPopupBody {...i} key={n} />
    ))}
  </StyledLocationAlertBox>
);
