import { useRoot } from 'app/containers/Root/useRoot';
import { PageError } from './PageError';
import {
  DesktopPageBox,
  LayoutDesktop,
  PageContainer,
} from 'app/components/LayoutDesktop';
import { Header } from './Header';
import { Aside } from './Aside';
import { AppRouter } from './AppRouter';
import { NODE_ENV } from 'utils/constants';

export function RootLayout() {
  const { fullsScreenMode, error, isReady } = useRoot();

  if (NODE_ENV !== 'development' && error) {
    return (
      <LayoutDesktop fullsScreenMode>
        <PageError />
      </LayoutDesktop>
    );
  }

  if (!isReady) {
    return null;
  }

  return (
    <>
      <LayoutDesktop fullsScreenMode={fullsScreenMode}>
        <Header />
        <Aside />
        <DesktopPageBox>
          <PageContainer>
            <AppRouter />
          </PageContainer>
        </DesktopPageBox>
      </LayoutDesktop>
    </>
  );
}
