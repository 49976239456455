import React from 'react';

export const Explanation = () => (
  <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="8.5" fill="#DEDEDE" />
    <path
      d="M8.07129 11.2314c-.04102-.1139-.07747-.262-.10938-.4443-.0319-.1823-.04785-.3623-.04785-.54 0-.27802.05469-.52868.16406-.75198.11394-.22787.25293-.43978.417-.63574.16862-.19597.34863-.3851.54004-.56739.19596-.18229.37597-.36002.54004-.5332.16862-.17318.30761-.35547.41699-.54688.11391-.1914.17091-.40104.17091-.6289 0-.20508-.041-.38281-.1231-.53321-.08199-.15494-.19592-.28255-.34176-.38281-.14127-.10482-.30534-.18229-.49219-.23242-.18229-.05013-.37825-.07519-.58789-.07519-.67903 0-1.32617.30305-1.9414.90917v-1.5791c.74739-.4375 1.52213-.65625 2.32422-.65625.36914 0 .71777.04785 1.04592.14356.3281.0957.6152.23698.8613.42383.2461.18684.4398.41927.5811.69726.1412.278.2119.60156.2119.9707 0 .35092-.0593.66309-.1778.93653-.1185.27344-.2688.52637-.4511.75879-.1778.22786-.3715.43066-.5811.6084-.2051.17773-.3988.35546-.58104.5332-.17774.17773-.32585.3623-.44434.55371-.11849.19141-.17773.40332-.17773.63572 0 .196.02506.3737.07519.5332.05013.1595.10026.294.15039.4033H8.07129zm.7793 2.8916c-.25977 0-.48991-.0888-.69043-.2666-.19141-.1731-.28711-.3873-.28711-.6425 0-.2598.0957-.474.28711-.6426.19596-.1823.4261-.2734.69043-.2734.25976 0 .48763.0911.68359.2734.19141.1686.28711.3828.28711.6426 0 .2552-.0957.4694-.28711.6425-.20052.1778-.42839.2666-.68359.2666z"
      fill="#001591"
    />
  </svg>
);
