/**
 *
 * useCurrentUserNav
 *
 */

import React from 'react';
import { useRoot } from 'app/containers/Root/useRoot';
import { IUseCurrentUserNavController } from './types';
import { handleOpenSettings } from '../../Notifications/utils';
import { useHistory } from 'react-router-dom';
import { getReportAPI, SystemReportType } from 'api/admin/getReportAPI';
import { downloadBlob } from 'utils/downloadBlob';

export function useCurrentUserNavController(): IUseCurrentUserNavController {
  const history = useHistory();

  const { handleLogout } = useRoot();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const openSettings = () => {
    handleOpenSettings(history);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleLogout();
    closeMenu();
  };

  const handleClickNotificationCenter = () => {
    closeMenu();
    openSettings();
  };

  const handleClickReport = async (type: SystemReportType) => {
    const blob = await getReportAPI({ type });

    downloadBlob(`report_${type}.xlsx`, blob);

    closeMenu();
  };

  return {
    anchorEl,
    openMenu,
    closeMenu,
    handleLogoutClick,
    handleClickNotificationCenter,
    handleClickReport,
  };
}
