export const handleOpenSettings = history => {
  sessionStorage.setItem(
    'notification_center',
    JSON.stringify({
      pathname: history.location.pathname,
      search: history.location.search,
    }),
  );
  history.push('/notification-center');
};

export const handleCloseSettings = history => {
  const locationString = sessionStorage.getItem(
    'notification_center',
  ) as string;

  const prevLocation = locationString?.length && JSON.parse(locationString);
  history.push(
    prevLocation
      ? { pathname: prevLocation.pathname, search: prevLocation.search }
      : '/',
  );

  sessionStorage.removeItem('notification_center');
  sessionStorage.removeItem('notification_center_parties_search');
};
