import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, IWithHistory } from './types';
import { IError } from 'types/errors';
import { WithScopeId } from 'app/hooks/useGetAbstractData/typesParamsConfig';

// The initial state of the AddScope container
export const initialState: ContainerState = {};

const pageAddScopeSlice = createSlice({
  name: 'pageAddScope',
  initialState,
  reducers: {
    createScope(state, _action: PayloadAction<IWithHistory>) {
      state.error = undefined;
      state.loading = true;
    },
    createScopeOk(state) {
      state.loading = false;
    },
    createScopeError(state, action: PayloadAction<IError>) {
      state.loading = false;
      state.error = action.payload;
    },

    updateScope(state, _action: PayloadAction<IWithHistory & WithScopeId>) {
      state.error = undefined;
      state.loading = true;
    },
    updateScopeOk(state) {
      state.loading = false;
    },
    updateScopeError(state, action: PayloadAction<IError>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = pageAddScopeSlice;
