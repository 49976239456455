import React from 'react';
import { Box, Typography } from '@mui/material';
import { IEventBlock } from '../../types';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '../styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconEventType } from '../../IconEventType';
import { EventFiled } from './Field/EventFiled';

type EventBlockProps = IEventBlock & {};

export const EventBlock = ({
  id,
  type,
  label,
  fields_list,
}: EventBlockProps) => {
  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
        <IconEventType icon={type} />
        <Typography m="0 0 0 1rem">{label}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {fields_list?.map(field => (
          <Box key={field.id} m="1rem 0">
            <EventFiled {...field} />
          </Box>
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};
