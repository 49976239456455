import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { IParty } from 'app/containers/RootAdmin/LogoFetch/types';
import { faker } from '@faker-js/faker';

export interface APIPayloadLogoFetchItem {
  partyId: string;
}

export interface APIResLogoFetchItem extends IParty {}

const { internet, company, image, address } = faker;

const mock: APIResLogoFetchItem = {
  domain: internet.url(),
  images: [image.image(), image.image(), image.image()],
  location: address.streetAddress(),
  party_name: company.name(),
};

export const getLogoFetchItemAPI: RequestApi<
  APIPayloadLogoFetchItem,
  APIResLogoFetchItem
> = async ({ partyId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/logo_fetch/?party_id=${partyId}`, { signal });
};
