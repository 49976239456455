import React, { useRef } from 'react';
import {
  AddThirdPartyLink,
  SearchButton,
  StyledInputContainer,
  StyledResultBody,
} from './styled';
import { SearchBarContext, useSearchBar } from './Context';
import { useSearchBarController } from './useSearchBar';
import { useTranslation } from 'react-i18next';
import { CircularProgress, ClickAwayListener, InputBase } from '@mui/material';
import { HeaderSearchIcon } from 'app/components/Icons/svg/HeaderSearchIcon';
import { translations } from 'locales/translations';
import { PlusIcon } from 'app/components/Icons/svg/PlusIcon';
import { ResultBody } from './ResultBody';
import { usePermissions } from 'app/containers/Root/usePermissions';
import { Box } from 'app/components/Box';
import { Divider } from 'app/components/Divider';
import { Typography } from 'app/components/Typography';

const SearchBarView = () => {
  const { t } = useTranslation();

  const { createParty } = usePermissions();

  const {
    query,
    loading,
    onFocus,
    onChangeSearch,
    handleLink,
    handleClickAway,
    open,
  } = useSearchBar();

  const searchInput = useRef<HTMLDivElement>(null);

  const handleInputOnChange = e => onChangeSearch(e.currentTarget.value);

  const isOpen = Boolean(open && query !== '');

  return (
    <Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StyledInputContainer ref={searchInput}>
          <SearchButton>
            <HeaderSearchIcon />
          </SearchButton>
          <InputBase
            fullWidth
            inputProps={{ 'aria-label': 'search' }}
            data-test="input_main_search"
            value={query}
            endAdornment={
              loading && (
                <Box display="flex">
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )
            }
            onFocus={onFocus}
            onChange={handleInputOnChange}
            placeholder={t(translations.searchBar.placeholder)}
          />
          <StyledResultBody isOpen={isOpen}>
            <ResultBody />
            {createParty && (
              <>
                <Box m="1rem 0" p="0 .5rem 0 3rem">
                  <Divider variant="fullWidth" />
                </Box>
                <AddThirdPartyLink
                  to={'/third-party/new'}
                  onClick={handleLink}
                  data-test="link_new_third_party"
                >
                  <div className="icon">
                    <PlusIcon fill="inherit" />
                  </div>
                  <Typography variant="body2" color="inherit">
                    <strong>{t(translations.searchBar.addParty)}</strong>
                  </Typography>
                </AddThirdPartyLink>
              </>
            )}
          </StyledResultBody>
        </StyledInputContainer>
      </ClickAwayListener>
    </Box>
  );
};

export function SearchBar() {
  const controller = useSearchBarController();

  return (
    <SearchBarContext.Provider value={{ ...controller }}>
      <SearchBarView />
    </SearchBarContext.Provider>
  );
}
