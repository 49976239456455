import React from 'react';
import { IListWithShowMoreData } from '../../types';
import { Box, Popover, Typography } from '@mui/material';

interface Props extends IListWithShowMoreData {}

export const CellListWithShowMore = ({ list, items_to_show = 1 }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!list.length) {
    return (
      <Typography variant="body2" color="inherit">
        None
      </Typography>
    );
  }

  if (list.length + 1 > items_to_show) {
    return (
      <Typography variant="body2" noWrap>
        {list
          .slice(0, items_to_show)
          .map((i, n, arr) => (n + 1 < arr.length ? `${i} | ` : i))}
        <Typography
          variant="body2"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {` | +${list.length - items_to_show}`}
        </Typography>
        <Popover
          id="ListWithShowMore-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{ pointerEvents: 'none' }}
        >
          <Box p={2}>
            <Typography variant="body2">
              {list.map((i, n, arr) => (n + 1 < arr.length ? `${i} | ` : i))}
            </Typography>
          </Box>
        </Popover>
      </Typography>
    );
  }
  return (
    <Typography variant="body2">
      {list.map((i, n, arr) => (n + 1 < arr.length ? `${i} | ` : i))}
    </Typography>
  );
};
