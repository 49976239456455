/**
 *
 * FilterTagView
 *
 */
import React from 'react';
import { IFilterData, IPrimitiveFilter } from './types';
import { StyledFilterTag, StyledFilterTagBox } from './styled';
import { FilterTagValues } from './FilterTagValues';
import { Box, IconButton, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';

export interface FilterTagViewProps {
  filterData: IFilterData;
  operator: string;
  item: IPrimitiveFilter;
  onRemoveFilterItem?: (item: IPrimitiveFilter) => void;
  doHighlightField?: (item: IPrimitiveFilter) => void;
  disableRemove?: boolean;
  isLast?: boolean;
}

export function FilterTagView({
  operator,
  item,
  filterData,
  onRemoveFilterItem,
  doHighlightField,
  disableRemove,
  isLast,
}: FilterTagViewProps) {
  const { filtering_fields, filtering_operators } = filterData;

  const filteringField = filtering_fields?.find(i => i.id === item.field);
  const fieldLabel = filteringField?.label;
  const operatorLabel = filtering_operators?.find(
    i => i.id === item.operator,
  )?.label;

  const handleRemoveField = () =>
    onRemoveFilterItem && onRemoveFilterItem(item);
  const handleHighlightField = () => doHighlightField && doHighlightField(item);

  return (
    <StyledFilterTagBox data-test="filter_tag">
      <Box className="tagBox">
        <StyledFilterTag
          onClick={handleHighlightField}
          disableRemove={disableRemove}
          disabled={!doHighlightField}
        >
          <Typography variant="body2" data-test="filter_tag_label">
            <strong>{fieldLabel}</strong>{' '}
          </Typography>
          <Typography variant="body2" data-test="filter_tag_operator">
            <i>{operatorLabel}</i>{' '}
          </Typography>
          <FilterTagValues
            values={item.value}
            options={filteringField?.options || []}
          />
        </StyledFilterTag>
        {!disableRemove && (
          <IconButton
            className="remove"
            onClick={handleRemoveField}
            data-test="button_filter_tag_clear"
          >
            <Clear fontSize="small" />
          </IconButton>
        )}
      </Box>
      {!isLast && (
        <Typography
          color={'inherit'}
          variant="body2"
          className="operator"
          data-test="filter_operator"
        >
          <i>{operator}</i>
        </Typography>
      )}
    </StyledFilterTagBox>
  );
}
