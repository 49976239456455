import React from 'react';
import { IconProps } from './types';

export const PlusIcon = ({ fill }: IconProps) => (
  <svg
    width="12"
    height="12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
  >
    <path
      d="M11.7344 6.78906H7v4.72264H5.44141V6.78906H.71875V5.21875h4.72266V.496094H7V5.21875h4.7344v1.57031z"
      fill={fill}
    />
  </svg>
);
