import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import { APIResponseParties } from 'api/thirdParties/getPartiesAPI';
import { mock } from 'api/thirdParties/getPartiesAPI/mock';

export interface APIPayloadNotificationCenterParties extends IPageParams {}

export type APIResponseNotificationCenterParties = APIResponseParties;

export const getNotificationCenterPartiesAPI: RequestApi<
  APIPayloadNotificationCenterParties,
  APIResponseNotificationCenterParties
> = async (props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/notifications/settings/parties/?${stringifyParams(props)}`, {
    signal,
  });
};
