/**
 *
 * InsightsContext
 *
 */

import React, { createContext } from 'react';
import { IUseNotifications } from '../types';

export const NotificationCenterContext = createContext<
  IUseNotifications | undefined
>(undefined);

export const useNotificationCenter = () => {
  const context = React.useContext(NotificationCenterContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationCenter must be used within a NotificationCenterContext',
    );
  }
  return context;
};
