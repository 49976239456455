import { PaletteMode, ThemeOptions } from '@mui/material';
import { getComponents } from './getComponents';
import { getLayoutStyles } from './getLayoutStyles';
import { getPalette } from './getPalette';

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: getPalette(mode),

  layout: getLayoutStyles(mode),

  typography: {
    fontFamily: 'Segoe UI Regular, Arial',
  },

  shape: {
    borderRadius: 2,
  },

  components: getComponents(mode),
});
