import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import {
  IRiskBoardMatrixTabIds,
  RiskBoardMatrixType,
} from 'app/containers/RootRiskBoard/types';
import {
  IAbstractFilterData,
  IAbstractMatrix,
  IResTotalsPartiesScopes,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { matrixMock } from 'api/riskBoard/getRiskBoardMatrixAPI/mock';

export interface APIPayloadRiskBoardMatrix extends IPageParams {
  type: RiskBoardMatrixType;
  tab_id: IRiskBoardMatrixTabIds;
}

export interface APIResponseRiskBoardMatrix
  extends IAbstractMatrix,
    IAbstractFilterData,
    IResTotalsPartiesScopes {}

export const getRiskBoardMatrixAPI: RequestApi<
  APIPayloadRiskBoardMatrix,
  APIResponseRiskBoardMatrix
> = async ({ type, tab_id, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(matrixMock(type));
      }, apiDelay);
    });
  }

  return request(`/risk_board/${type}/${tab_id}?${stringifyParams(props)}`, {
    signal,
  });
};
