import { IHeaderItem } from '../types';
import { Arrow } from './Arrow';
import {
  StyledExplanationButton,
  StyledTableHeaderCell,
  StyledTableHeaderTh,
} from '../styled';
import { TableCellPopup } from '../TableCellPopup';
import { useTableHeadItem } from './useTableHeadItem';
import { setPopupBorder } from '../TableCell/useTableCell';
import { Popover } from '@mui/material';
import { Explanation } from 'app/components/Icons/svg/Explanation';
import { Typography } from 'app/components/Typography';

export const TableHeadItem = (props: IHeaderItem) => {
  const { max_width, orderable, label, sublabel, popup, id } = props;

  const {
    width,
    is_small,
    is_empty,
    colSpan,
    isCurrentOrder,
    isPopupOpen,
    arrowUp,
    anchorEl,
    handleOrder,
    handleExplanation,
    handlePopoverClose,
    hideCell,
  } = useTableHeadItem(props);

  if (hideCell && hideCell === id) {
    return <StyledTableHeaderTh is_small is_empty />;
  }

  return (
    <StyledTableHeaderTh
      onClick={handleOrder}
      max_width={max_width}
      width={width}
      is_small={is_small}
      is_empty={is_empty}
      colSpan={colSpan}
      data-test={`table_head_${id}`}
    >
      <StyledTableHeaderCell
        orderable={orderable}
        currentOrder={isCurrentOrder}
      >
        <Typography variant="body2" noWrap style={{ margin: '0 0.4rem 0 0' }}>
          <strong>{label}</strong>
        </Typography>
        {Boolean(sublabel?.length) && (
          <Typography color="textSecondary" noWrap variant="body2">
            ({sublabel})
          </Typography>
        )}
        {orderable && <Arrow up={arrowUp} />}
        {popup && (
          <StyledExplanationButton
            className="explanation"
            onClick={handleExplanation}
          >
            <Explanation />
          </StyledExplanationButton>
        )}
      </StyledTableHeaderCell>
      {popup && isPopupOpen && (
        <Popover
          open={isPopupOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          PaperProps={{
            style: {
              borderTop: setPopupBorder(popup.status_id),
            },
          }}
        >
          <TableCellPopup popup={popup} onClose={handlePopoverClose} />
        </Popover>
      )}
    </StyledTableHeaderTh>
  );
};
