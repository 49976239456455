import { styled } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

interface FilterWrapperProps {
  disabled?: boolean;
}

export const FilterWrapper = styled('div')<FilterWrapperProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.3s;

  & > * {
    margin: 0 0.3rem 0.3rem 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.7;
      pointer-events: none;
  `}
`;

export const FilterButton = styled(ButtonBase)`
  padding: 0.25rem 0.5rem;
  background: ${({ theme }) => theme.layout.filter.filterButtonBackground};
  border: ${({ theme }) => theme.layout.filter.filterButtonBorder};
  border-radius: 2px;
  height: 2.2rem;
`;

export const ShowAllButton = styled(FilterButton)`
  display: flex;
  align-items: center;
  min-width: 200px;
  justify-content: space-between;

  svg {
    fill: #827f81;
  }
`;

interface CreateFilterButtonProps {
  disabled?: boolean;
}

export const CreateFilterButton = styled(ButtonBase)<CreateFilterButtonProps>`
  padding: 0.8rem 1rem;
  width: 100%;
  color: #001591;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
  display: block;

  ${({ disabled }) => disabled && 'opacity: .5'}
`;

export const StyledFilterTagBox = styled('div')`
  display: flex;
  align-items: center;
  .tagBox {
    position: relative;
    .remove {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  & > .operator {
    color: #108596;
    margin: 0 0.2rem 0 0.5rem;
  }
  &:last-of-type {
    & > .operator {
      display: none;
    }
  }
`;

interface StyledFilterTagProps {
  disableRemove?: boolean;
}

export const StyledFilterTag = styled(FilterButton, {
  shouldForwardProp: prop => prop !== 'disableRemove',
})<StyledFilterTagProps>`
  display: flex;
  align-items: center;
  padding: 0.25rem 2.5rem 0.25rem 0.5rem;

  ${({ disableRemove }) =>
    disableRemove
      ? 'padding: 0.25rem 0.5rem;'
      : 'padding: 0.25rem 2.5rem 0.25rem 0.5rem;'}

  & > p {
    margin: 0 0.3rem;
  }

  i {
    color: #3d4fe4;
  }
`;

export const StyledEditFilterButton = styled(FilterButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.3rem 0.5rem;

  & > svg:first-of-type {
    margin: 0 0.5rem 0 0;
  }
`;

export const EditWindowPopover = styled('div')`
  width: 880px;
`;

export const StyledEditWindow = styled('div')`
  padding: 1rem 0;
  overflow: auto;
`;

export const StyledFieldsBox = styled('div')`
  max-height: 250px;
  overflow-y: auto;
  padding: 0 3rem;
`;

interface StyledFieldProps {
  highlighted?: boolean;
}

export const StyledField = styled('div', {
  shouldForwardProp: prop => prop !== 'highlighted',
})<StyledFieldProps>`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 2rem);
  & > div {
    position: relative;
  }
  & > div:nth-of-type(1) {
    flex-basis: calc(25% - 0.5rem);
  }
  & > div:nth-of-type(2) {
    flex-basis: calc(25% - 0.5rem);
  }
  & > .hidden {
    flex-basis: 0;
  }
  & > div:last-of-type {
    flex-basis: calc(50% - 0.5rem);
    width: calc(50% - 0.5rem);
  }
  .remove {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0.3rem 0 0 0.3rem;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    .remove {
      opacity: 1;
    }
  }

  .operator {
    .MuiOutlinedInput-input {
      color: #3d4fe4;
      font-style: italic;
    }
  }

  .hidden {
    width: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    transition: 0.3s;
  }

  ${({ highlighted }) =>
    highlighted &&
    `
      .MuiOutlinedInput-notchedOutline {
        border-color: #1A1719!important;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
      }
  `}
`;
