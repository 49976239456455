import { APIResponseRiskBoardMatrixCell } from 'api/riskBoard/getRiskBoardMatrixCellAPI/index';
import { faker } from '@faker-js/faker';
import { buildMockTable } from 'api/utils';
import {
  IHeaderItem,
  IScopeItem,
  ITableRow,
  ITableTag,
} from 'app/containers/AbstractTable/types';

const rowsCount = 10;

const scopesCount = 20;

const tagCount = 12;

const headerItems: IHeaderItem[] = [
  {
    id: 'name',
    label: 'Party Name',
    sublabel: '',
    orderable: true,
  },
  {
    id: 'scopes',
    label: 'Relationships',
    sublabel: '',
    orderable: false,
  },
  {
    id: 'risk_domains',
    label: 'Risk Domains',
    sublabel: '',
    orderable: false,
  },
];

const { datatype, internet, company, random } = faker;

const buildScope = (count: number): IScopeItem => {
  return {
    id: `scope_${count}_${datatype.uuid()}`,
    label: `scope_${count}_${internet.domainWord()}`,
  };
};

const buildTag = (count: number): ITableTag => {
  return {
    id: `tag_${count}_${datatype.uuid()}`,
    label: `${random.words(3)}`,
  };
};

const buildRows = (count: number): ITableRow => {
  return {
    id: `${count}_tp_row`,
    cells: [
      {
        type: 'name-with-avatar',
        data: {
          name: company.name(),
        },
      },
      {
        type: 'scopes-list',
        data: {
          scopes: Array.from(
            Array(Math.floor(Math.random() * scopesCount) + 1).keys(),
          ).map(buildScope),
        },
      },
      {
        type: 'tags-list',
        data: {
          tags: Array.from(
            Array(Math.floor(Math.random() * tagCount) + 1).keys(),
          ).map(buildTag),
        },
      },
    ],
    order_value: 'AA',
    type: 'link-by-id',
  };
};

const rows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildRows);

const table = buildMockTable(headerItems, rows);

export const mock: APIResponseRiskBoardMatrixCell = {
  table,
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 125,
  page: 1,
  item_type: random.word(),
  impact: 'Impact',
  probability: 'Probability',
};
