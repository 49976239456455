import React from 'react';
import { Box, Divider } from '@mui/material';

export const FieldSeparator = () => {
  return (
    <Box m="1rem 0">
      <Divider />
    </Box>
  );
};
