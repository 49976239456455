import { styled } from '@mui/material/styles';
import { ButtonBase, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledInputContainer = styled('div')`
  width: 100%;
  max-width: ${({ theme }) => theme.layout.headerSearchBar.maxWidth};
  background: ${({ theme }) => theme.layout.headerSearchBar.background};
  border: ${({ theme }) => theme.layout.headerSearchBar.border};
  border-radius: 6px 2px 2px 6px;
  padding: 0 0.5rem 0 3rem;
  position: relative;

  input {
    width: 100%;
    padding: 8px 0;
    line-height: 1.3;
  }
`;

export const SearchButton = styled(ButtonBase)`
  background: ${({ theme }) => theme.layout.headerSearchBar.buttonBackground};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  border-radius: 4px 0 0 4px;
`;

interface ResultBodyProps {
  isOpen?: boolean;
}

export const StyledResultBody = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isOpen',
})<ResultBodyProps>`
  border-radius: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem 0;
  margin: 0.1rem 0 0 0;
  transition: 0.3s;

  ${({ isOpen }) =>
    isOpen
      ? `
      opacity: 1;
      transform: translate(0, 0);
      pointer-events: all;
  `
      : `
      opacity: 0;
      transform: translate(0, .2rem);
      pointer-events: none;
  `}
`;

export const AddThirdPartyLinkIconBox = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddThirdPartyLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.layout.headerSearchBar.addPartyLinkColor};
  cursor: pointer;
  padding: 0 0 0 3rem;

  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0 0.8rem 0 0;
      width: 13px;
      height: 13px;
      fill: ${({ theme }) => theme.layout.headerSearchBar.addPartyLinkColor};
    }
  }
`;

export const StyledItem = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  padding: 0.3rem 0 0.3rem 3rem;

  &:hover {
    background: #f0ecfe;
  }

  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
      margin: 0 0.8rem 0 0;
      width: 40px;
      height: 40px;
    }
  }
`;

export const StyledBodyBox = styled('div')`
  max-height: 420px;
  overflow: auto;
`;
