/**
 *
 * useDebouncedSearch
 *
 */

import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { BlockCrashError } from 'types/errors';

interface IUseDebouncedSearch<T> {
  loading?: boolean;
  error?: BlockCrashError;
  data?: T;
  doSearch: (query?: string) => void;
  doClearData: () => void;
}

export interface IUseDebouncedSearchOptions {
  disableEmptyQuery: boolean;
}

const pendingApiReq: any[] = [];

export function useDebouncedSearch<T>(
  searchAPI,
  options?: IUseDebouncedSearchOptions,
): IUseDebouncedSearch<T> {
  const [query, setQuery] = useState<string | undefined>('');
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<BlockCrashError>();

  const debouncedChangeHandler = useRef(debounce(setQuery, 600)).current;

  useEffect(() => {
    (async () => {
      const controller = new AbortController();
      const signal = controller.signal;

      pendingApiReq.forEach(c => {
        c.abort();
      });

      pendingApiReq.push(controller);

      setLoading(false);
      setData(undefined);
      setError(undefined);

      if (options?.disableEmptyQuery && !query?.length) {
        return;
      }

      try {
        setLoading(true);
        const req = searchAPI(query, signal);
        const res = await req;
        pendingApiReq.pop();
        setData(res);
        setLoading(false);
      } catch (e: any) {
        if (e.name === 'AbortError') {
          return;
        }
        setError({ type: 'block_crash', transactionId: e.transactionId });
        setData(undefined);
        setLoading(false);
      }
    })();
  }, [options?.disableEmptyQuery, query, searchAPI]);

  const doSearch = (query?: string) => {
    debouncedChangeHandler(query?.replace(/\s+/g, ' ').trim());
  };

  const doClearData = () => {
    setLoading(false);
    setData(undefined);
    setQuery('');
  };

  return {
    loading,
    error,
    data,
    doSearch,
    doClearData,
  };
}
