/**
 *
 * FilterTag
 *
 */
import React from 'react';
import { IPrimitiveFilter } from './types';
import { useFilter } from './FilterContext';
import { FilterTagView } from './FilterTagView';

export interface FilterTagProps {
  operator: string;
  item: IPrimitiveFilter;
}

export function FilterTag({ operator, item }: FilterTagProps) {
  const { filterData, onRemoveFilterItem, doHighlightField } = useFilter();
  return (
    <FilterTagView
      operator={operator}
      item={item}
      filterData={filterData}
      doHighlightField={doHighlightField}
      onRemoveFilterItem={onRemoveFilterItem}
    />
  );
}
