/**
 *
 * SecondaryActionCell
 *
 */

import React from 'react';
import { Box } from '@mui/material';

interface ISecondaryActionCell {
  children: React.ReactNode;
  disabled?: boolean;
}

export const SecondaryActionCell = ({
  children,
  disabled,
}: ISecondaryActionCell) => (
  <Box
    display="inline-block"
    onClick={event => {
      if (!disabled) {
        event.stopPropagation();
      }
    }}
  >
    {children}
  </Box>
);
