import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
  IconButton,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)`
  background: ${({ theme }) => theme.layout.pad.background};
  box-shadow: none;
  margin: 1rem 0;

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 1rem 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background: ${({ theme }) => theme.layout.pad.backgroundDark};
  border-bottom: 1px solid transparent;
  transition: 0.3s;

  &.Mui-expanded {
    min-height: 0;
    border-color: ${({ theme }) => theme.palette.divider};
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 1rem 1rem 1rem 3.5rem;
`;

export const StyledHeader = styled('div')`
  position: sticky;
  top: 0;
  padding: 0.5rem 2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

export const StyledModal = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0 0 4rem 0;

  background: ${({ theme }) => theme.palette.background.paper};
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translate(0, -50%);
`;

export const StyledActions = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;

  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[3]};

  & > button,
  a {
    margin: 0 0 0 0.5rem;
  }
`;

interface StyledMultiTagProps {
  separator?: boolean;
}

export const StyledMultiTag = styled('div', {
  shouldForwardProp: prop => prop !== 'separator',
})<StyledMultiTagProps>`
  display: flex;
  align-items: center;

  ${({ separator, theme }) =>
    separator &&
    `
          padding: 0 0 1rem 0;
          border-bottom: 1px solid ${theme.palette.divider}
          `};

  & > label {
    flex-basis: 650px;
    margin: 0 1rem 0 0;
  }
`;

interface StyledMultiTagItemProps {
  selected?: boolean;
}

export const StyledMultiTagItem = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'selected',
})<StyledMultiTagItemProps>`
  transition: 0.3s;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.primary.dark : theme.palette.background.paper};
  color: ${({ theme, selected }) =>
    selected
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary};

  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.primary.dark : theme.palette.divider};

  border-radius: 4px;

  padding: 0.1rem 1rem;
`;
