import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { apiDelay } from 'api/utils';
import { request } from 'utils/request';

export interface IPayloadDownloadSingleFile {
  file_id: string;
}

export interface IResponseDownloadSingleFile {
  url: string;
}

export const downloadSingleFile: RequestApi<
  IPayloadDownloadSingleFile,
  IResponseDownloadSingleFile
> = async ({ file_id }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          url: 'google.com',
        });
      }, apiDelay);
    });
  }

  return request(`/files/download_signed_url/${file_id}`, { signal });
};
