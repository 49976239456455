type DownloadBlob = (fileName: string, blob: Blob | File) => void;

export const downloadBlob: DownloadBlob = (fileName, blob) => {
  let a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
