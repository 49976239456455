import { usePermissions } from 'app/containers/Root/usePermissions';
import { useCurrentUserNav } from 'app/containers/Root/Header/CurrentUserNav/Context';
import { Box } from 'app/components/Box';
import { Typography } from 'app/components/Typography';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { Link } from 'app/components/Link';
import { Divider } from 'app/components/Divider';

export function ReportsSection() {
  const { reports } = usePermissions();

  const { handleClickReport } = useCurrentUserNav();

  if (!reports) {
    return null;
  }

  const handleClickControls = () => {
    handleClickReport('controls_benchmark');
  };

  const handleClickAttributes = () => {
    handleClickReport('attributes_benchmark');
  };

  const handleClickRejectEvidence = () => {
    handleClickReport('rejected_evidence');
  };

  return (
    <>
      <Box p=".5rem 1rem 0">
        <Typography
          color="textPrimary"
          variant="body2"
          style={{ margin: '0 0 .5rem 0' }}
        >
          <strong>Reports</strong>
        </Typography>
      </Box>
      <Box component="ul" p={0} m={0}>
        <MenuItem onClick={handleClickControls}>
          <Link underline="none" variant="body2" component="span">
            Controls benchmark
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClickAttributes}>
          <Link underline="none" variant="body2" component="span">
            Attributes benchmark
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClickRejectEvidence}>
          <Link underline="none" variant="body2" component="span">
            Rejected evidence
          </Link>
        </MenuItem>
      </Box>
      <Box m=".5rem 0">
        <Divider variant="middle" />
      </Box>
    </>
  );
}
