import { IAlertError } from './types';
import { AlertTitle } from './AlertTitle';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useTheme } from '@mui/material';
import { TextWithMods } from 'app/components/TextWithMods';
import { Dialog } from 'app/components/Dialog';
import { DialogContent } from 'app/components/Dialog/DialogContent';
import { Typography } from 'app/components/Typography';
import { Link } from 'app/components/Link';
import { DialogActions } from 'app/components/Dialog/DialogActions';

interface IAlertErrorProps extends IAlertError {}

export function AlertError(props: IAlertErrorProps) {
  const { t } = useTranslation();

  const { id, transactionId, text, markUpText, mods, title } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.removeAlert(props));
  };

  const handleSendFeedback = () => {
    dispatch(actions.sendFeedback({ transactionId }));
    handleClose();
  };

  const theme = useTheme();

  const paperProps = { style: { borderTop: theme.layout.alert.errorBorder } };

  return (
    <Dialog
      id={id}
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={paperProps}
    >
      <AlertTitle error onClose={handleClose}>
        {title}
      </AlertTitle>
      <DialogContent>
        {markUpText && text ? (
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
            <TextWithMods mods={mods || []}>
              {text || t(translations.alertError.title)}
            </TextWithMods>
          </Typography>
        )}
        <br />
        <Link
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={handleSendFeedback}
        >
          {t(translations.alertError.linkTitle)}
        </Link>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
