import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNotificationCenter } from '../Context';
import { translations } from '../../../../../../locales/translations';
import { useTranslation } from 'react-i18next';
import { EventBlock } from './EventBlock';

export const Events = () => {
  const { t } = useTranslation();

  const { settings } = useNotificationCenter();

  const { events_data } = settings;

  return (
    <>
      <Box m="0 0 1rem 0">
        <Typography variant="body2">
          {t(translations.notificationSettings.eventsTitle)}
        </Typography>
      </Box>
      <Box>
        {events_data?.map(block => (
          <EventBlock key={block.id} {...block} />
        ))}
      </Box>
    </>
  );
};
