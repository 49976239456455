/**
 *
 * TableTop
 *
 */
import { Box, Icon, Typography } from '@mui/material';
import React from 'react';
import { ITableLegend } from '../../containers/AbstractTable/types';
import { StyledIconButton } from './styled';

interface PaginationProps {
  limit: number;
  limitOptions?: number[];
  page: number;
  all: number;
  legend?: ITableLegend;
  onChangePage?: (e, page: number) => void;
  disable?: boolean;
  handleOpenDropDown: (e) => void;
}

export function Pagination({
  limit,
  page,
  all,
  onChangePage,
  handleOpenDropDown,
  limitOptions = [],
  disable = false,
}: PaginationProps) {
  const from = (page - 1) * limit + 1;
  const to = (page - 1) * limit + limit;
  const validTo = to < all ? to : all;
  const pages = Math.ceil(all / limit);
  const doPrev = e => onChangePage?.(e, page - 1);
  const doNext = e => onChangePage?.(e, page + 1);

  if (all <= 1) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      {Boolean(limitOptions?.length) && (
        <StyledIconButton
          size="small"
          onClick={handleOpenDropDown}
          disabled={disable}
        >
          <Icon>more_vert</Icon>
        </StyledIconButton>
      )}
      <Typography variant="caption">
        {`${from} - ${validTo} of ${all}`}
      </Typography>
      <Box display="flex">
        <StyledIconButton
          size="small"
          onClick={doPrev}
          disabled={page <= 1 || disable}
          data-test="button_pagination_prev"
        >
          <Icon>keyboard_arrow_left</Icon>
        </StyledIconButton>
        <StyledIconButton
          size="small"
          onClick={doNext}
          disabled={page >= pages || disable}
          data-test="button_pagination_next"
        >
          <Icon>keyboard_arrow_right</Icon>
        </StyledIconButton>
      </Box>
    </Box>
  );
}
