import { css } from '@emotion/react';

export const globalStyles = css`
  html,
  body {
    font-size: 16px;
  }

  body {
    font-family: Segoe UI Regular, Arial, sans-serif;
    min-width: 1300px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin: 0;
    padding: 0;
  }
  body::-webkit-scrollbar {
    display: none;
  }

  #root {
    min-height: 100vh;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input,
  select {
    font-family: inherit;
    font-size: inherit;
  }

  .sentry-error-embed-wrapper {
    z-index: 99999 !important;
    p,
    h2,
    h3,
    h4 {
      font-family: Segoe UI Regular, Arial, sans-serif;
      color: #1a1719;
    }
    .sentry-error-embed {
      max-width: 440px;
      border-top: 4px solid #3d4fe4;
      padding: 0;
      max-height: 90%;

      .form-field label {
        text-transform: none;
        font-size: 14px;
        color: #1a1719;
      }

      .form-field input,
      .sentry-error-embed .form-field textarea {
        background: #ffffff;
        border: 1px solid #dedede;
        box-sizing: border-box;
        border-radius: 2px;
      }

      .form-submit {
        display: flex;
        flex-direction: row-reverse;

        .btn {
          margin: 0 0 0 1rem;
          background: #001591;
          border-radius: 2px;
          height: 30px;
          min-width: 140px;
          line-height: 28px;
          padding: 0;
        }

        .close {
          margin: 0 0 0 1rem;
          color: #1a1719;
          background: #ebf0ee;
          border-radius: 2px;
          height: 30px;
          min-width: 140px;
          line-height: 28px;
          padding: 0;
        }
      }

      header {
        padding: 0;
        border-bottom: none;

        #sentry-error-embed-heading {
          color: #1a1719;
          padding: 1rem 0;
          font-size: 16px;
          font-weight: bold;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        }

        p {
          padding: 1rem 1.5rem 0;
          font-size: 14px;
          text-align: left;
          font-weight: bold;
          color: #1a1719;
          span {
            font-weight: normal;
            white-space: pre-line;
          }
        }
      }

      form {
        padding: 1rem 1.5rem;
      }
    }

    .powered-by {
      display: none;
    }
  }
`;
