/**
 *
 * ResultBody
 *
 */
import React from 'react';
import { useSearchBar } from './Context';
import { SearchItem } from './SearchItem';
import { ErrorBlock } from '../../../ErrorBlock';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { translations } from 'locales/translations';
import { StyledBodyBox } from './styled';

export const ResultBody = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useSearchBar();

  if (error) {
    return (
      <Box p="0 .5rem 0 3rem">
        <ErrorBlock error={error} />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box p="0 0 0 3rem">
        <Typography variant="body2" gutterBottom>
          {t(translations.searchBar.loading)}
        </Typography>
      </Box>
    );
  }

  if (data.length) {
    const thirdParties = data.filter(i => i.type === 'party');

    const scopes = data.filter(i => i.type === 'scope');

    return (
      <StyledBodyBox>
        {Boolean(thirdParties.length) && (
          <Box>
            <Box p="0 0 0 3rem" m="0 0 .5rem 0">
              <Typography variant="caption" gutterBottom>
                <strong>{t(translations.searchBar.party)}</strong>
              </Typography>
            </Box>
            {thirdParties.map(i => (
              <SearchItem item={i} key={i.id} />
            ))}
          </Box>
        )}
        {Boolean(thirdParties.length && scopes.length) && (
          <Box m="1rem 0">
            <Divider variant="middle" />
          </Box>
        )}
        {Boolean(scopes.length) && (
          <Box>
            <Box p="0 0 0 3rem" m="0 0 .5rem 0">
              <Typography variant="caption" gutterBottom>
                <strong>{t(translations.searchBar.scope)}</strong>
              </Typography>
            </Box>
            {scopes.map(i => (
              <SearchItem item={i} key={i.id} />
            ))}
          </Box>
        )}
      </StyledBodyBox>
    );
  }

  return (
    <Box p="0 0 0 3rem" m="0 0 .5rem 0">
      <Typography>{t(translations.searchBar.noResults)}</Typography>
    </Box>
  );
};
