/**
 *
 * AutocompleteCheckbox
 *
 */

import React from 'react';
import { SelectOptionType } from '../Select_old';
import { CheckBox } from '../CheckBox';
import { TextField } from '../TextField';
import { StyledAutocomplete } from '../Autocomplete';
import { styled } from '@mui/material/styles';
import { WithTestID } from 'types/common';

interface ICheckboxesTags extends WithTestID {
  values: SelectOptionType[];
  options: SelectOptionType[];
  handleOnChange: (e) => void;
  onClose?: (e) => void;
  onOpen?: (e) => void;
  placeholder?: string;
  limitTags?: number;
  size?: 'small' | 'medium';
}

export const StyledList = styled('ul')`
  & li[aria-selected='true'] {
    background-color: ${({ theme }) =>
      theme.layout.listItem.backgroundSelected} !important;
    color: ${({ theme }) => theme.layout.listItem.textSelected};
  }
  ,
  & li:hover {
    background-color: ${({ theme }) =>
      theme.layout.listItem.backgroundHover} !important;
  }
`;

export const AutocompleteCheckbox = ({
  values,
  options,
  handleOnChange,
  placeholder,
  onClose,
  onOpen,
  limitTags,
  size = 'small',
  test_id,
}: ICheckboxesTags) => (
  <StyledAutocomplete
    multiple
    options={options}
    onChange={(e, newValue: any) => {
      const event = {
        target: {
          name: 'value',
          value: newValue.map(i => i.value),
        },
      };
      handleOnChange(event);
    }}
    value={values}
    limitTags={limitTags || 1}
    size={size}
    disableCloseOnSelect
    isOptionEqualToValue={(option: any, value: any) =>
      option.value === value.value
    }
    getOptionLabel={(option: any) => option.label}
    ListboxComponent={StyledList}
    renderOption={(props, option: any, { selected }) => (
      <li {...props}>
        <CheckBox
          style={{ marginRight: 6, padding: 0 }}
          checked={selected}
          size="small"
        />
        {option.label}
      </li>
    )}
    renderInput={params => (
      <TextField {...params} variant="outlined" placeholder={placeholder} />
    )}
    onClose={onClose}
    onOpen={onOpen}
    data-test={test_id}
    fullWidth
  />
);
