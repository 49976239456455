import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { IconButton } from 'app/components/Buttons/IconButton';
import { WithTestID } from 'types/common';

export type ITextFieldProps = TextFieldProps &
  WithTestID & {
    readonly?: boolean;
  };

export const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: prop => prop !== 'readonly',
})<ITextFieldProps>`
  fieldset {
    top: 0;

    legend {
      display: none;
    }
  }

  label {
    position: relative;
    transform: none;
    margin: 0 0 0.5rem 0;
    color: ${({ theme }) => theme.palette.text.primary};

    &.Mui-disabled {
      color: ${({ theme, readonly }) => readonly && theme.palette.text.primary};
    }
  }

  .MuiOutlinedInput-root {
    background: ${({ theme, readonly }) =>
      readonly ? 'transparent' : theme.palette.background.default};

    .MuiOutlinedInput-input {
      height: auto;
      line-height: 1.3;

      padding-top: 7px;
      padding-bottom: 7px;

      &::placeholder {
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.palette.text.secondary};
        opacity: 1;
      }

      &.Mui-disabled {
        -webkit-text-fill-color: ${({ theme, readonly }) =>
          readonly && theme.palette.text.primary};
      }
    }

    .MuiSelect-select {
      height: 1.2876em;
      min-height: 1.2876em;

      padding-top: 6px;
      padding-bottom: 8px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.divider};
    }
  }
`;

export const TextField = ({
  margin = 'normal',
  size = 'small',
  InputLabelProps = {
    shrink: true,
  },
  readonly,
  disabled,
  test_id,
  id,
  ...rest
}: ITextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  if (rest.type === 'password') {
    return (
      <StyledTextField
        {...rest}
        type={showPassword ? 'text' : 'password'}
        margin={margin}
        size={size}
        InputLabelProps={InputLabelProps}
        disabled={disabled || readonly}
        readonly={readonly}
        InputProps={{
          ...rest.InputProps,
          endAdornment: (
            <IconButton onClick={toggleShowPassword}>
              <Icon name={showPassword ? 'eye' : 'closed_eye'} />
            </IconButton>
          ),
        }}
      />
    );
  }
  return (
    <StyledTextField
      id={test_id}
      {...rest}
      margin={margin}
      size={size}
      InputLabelProps={InputLabelProps}
      data-test={test_id || `input_${id}`}
      disabled={disabled || readonly}
      readonly={readonly}
    />
  );
};
