import { IFilterData } from 'app/containers/Filter/types';
import { faker } from '@faker-js/faker';

const { random } = faker;

const buildOption = (count: number) => {
  return {
    value: `option_${count}`,
    label: random.words(3),
  };
};

export const buildMockFilterData = (): IFilterData => {
  return {
    filtering_fields: [
      {
        id: 'list_filter',
        label: 'List Filter',
        compatible_operators: ['list_is', 'list_is_not', 'list_is_any'],
        options: Array.from(Array(10).keys()).map(buildOption),
      },
      {
        id: 'text_filter',
        label: 'Text Filter',
        compatible_operators: [
          'text_equals',
          'text_contains',
          'text_not_contains',
          'text_starts_with',
          'text_ends_with',
          'text_is_empty',
          'text_is_not_empty',
        ],
      },
      {
        id: 'time_filter',
        label: 'Time Filter',
        compatible_operators: [
          'date_today',
          'date_this_month',
          'date_this_quarter',
          'date_this_year',
          'date_last_30_days',
          'date_last_60_days',
          'date_last_3_months',
          'date_last_6_months',
          'date_last_12_months',
        ],
      },
    ],
    filtering_operators: [
      {
        id: 'list_is',
        label: 'Is',
        value_type: 'multiple_select',
      },
      {
        id: 'list_is_not',
        label: 'Is not',
        value_type: 'multiple_select',
      },
      {
        id: 'list_is_any',
        label: 'Is any',
        value_type: 'multiple_select',
      },
      {
        id: 'text_equals',
        label: 'Equals',
        value_type: 'text',
      },
      {
        id: 'text_contains',
        label: 'Contains',
        value_type: 'text',
      },
      {
        id: 'text_not_contains',
        label: 'Does not contain',
        value_type: 'text',
      },
      {
        id: 'text_starts_with',
        label: 'Starts with',
        value_type: 'text',
      },
      {
        id: 'text_ends_with',
        label: 'Ends with',
        value_type: 'text',
        default_value: '',
      },
      {
        id: 'text_is_empty',
        label: 'Is empty',
        value_type: 'none',
        default_value: '',
      },
      {
        id: 'text_is_not_empty',
        label: 'Is not empty',
        value_type: 'none',
        default_value: '',
      },
      {
        id: 'date_today',
        label: 'Today',
        value_type: 'none',
        default_value: 'at 2023-06-21',
      },
      {
        id: 'date_this_month',
        label: 'Current month',
        value_type: 'none',
        default_value: 'from 2023-06-01 till 2023-06-30',
      },
      {
        id: 'date_this_quarter',
        label: 'This quarter',
        value_type: 'none',
        default_value: 'from 2023-04-01 till 2023-06-30',
      },
      {
        id: 'date_this_year',
        label: 'This year',
        value_type: 'none',
        default_value: 'from 2023-01-01 till 2023-12-31',
      },
      {
        id: 'date_last_30_days',
        label: 'Last 30 days',
        value_type: 'none',
        default_value: 'after 2023-05-22',
      },
      {
        id: 'date_last_60_days',
        label: 'Last 60 days',
        value_type: 'none',
        default_value: 'after 2023-04-22',
      },
      {
        id: 'date_last_3_months',
        label: 'Last 3 months',
        value_type: 'none',
        default_value: 'after 2023-03-21',
      },
      {
        id: 'date_last_6_months',
        label: 'Last 6 months',
        value_type: 'none',
        default_value: 'after 2022-12-21',
      },
      {
        id: 'date_last_12_months',
        label: 'Last 12 months',
        value_type: 'none',
        default_value: 'after 2022-06-21',
      },
    ],
    filters: [],
    default_filter: null,
  };
};
