import { EventType } from 'app/containers/Root/Notifications/types';
import { Icon } from 'app/components/Icon';

interface Props {
  icon: EventType;
}

export function IconEventType(props: Props) {
  const { icon } = props;

  return <Icon width="1.5rem" height="1.5rem" name={`event_${icon}`} />;
}
