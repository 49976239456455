import React, { FC } from 'react';
import MUITypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { MuiProps } from 'types/common';

export type TypographyProps = MuiTypographyProps & MuiProps;

export const Typography: FC<TypographyProps> = props => {
  return <MUITypography {...props} />;
};
