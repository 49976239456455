import { Box } from 'app/components/Box';
import { Typography } from 'app/components/Typography';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { Link } from 'app/components/Link';
import { Divider } from 'app/components/Divider';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { usePermissions } from 'app/containers/Root/usePermissions';
import { useCurrentUserNav } from 'app/containers/Root/Header/CurrentUserNav/Context';

export function NotificationSection() {
  const { t } = useTranslation();

  const { notifications } = usePermissions();

  const { handleClickNotificationCenter } = useCurrentUserNav();

  if (!notifications) {
    return null;
  }

  return (
    <>
      <Box p=".5rem 1rem 0">
        <Typography
          color="textPrimary"
          variant="body2"
          style={{ margin: '0 0 .5rem 0' }}
        >
          <strong>{t(translations.mainNav.preferences)}</strong>
        </Typography>
      </Box>
      <Box component="ul" p={0} m={0}>
        <MenuItem onClick={handleClickNotificationCenter}>
          <Link underline="none" variant="body2" component="span">
            {t(translations.mainNav.notificationCenter)}
          </Link>
        </MenuItem>
      </Box>
      <Box m=".5rem 0">
        <Divider variant="middle" />
      </Box>
    </>
  );
}
