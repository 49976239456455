import { useEffect, useState } from 'react';
import { IFilterField } from './types';
import { StyledField } from './styled';
import { useFilter } from './FilterContext';
import { Select } from 'app/components/Inputs/Select_old';
import { HiddenInput } from 'app/components/Inputs/HiddenInput';
import { deepCompare } from 'utils/deepCompare';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Clear } from '@mui/icons-material';
import { ValueInput } from './ValueInput';
import { Box } from 'app/components/Box';
import { IconButton } from 'app/components/Buttons/IconButton';
import { Typography } from 'app/components/Typography';

interface Props {
  fieldItem: IFilterField;
  isLast?: boolean;
  index: number;
}

export function EditFilterField({ fieldItem, isLast, index }: Props) {
  const { t } = useTranslation();

  const { field, value, operator } = fieldItem;
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const {
    mainOperator,
    filterData,
    highlightedField,
    setHighlightedField,
    removeField,
    isEmptyFilter,
    doSaveToState,
  } = useFilter();

  useEffect(() => {
    if (deepCompare(fieldItem, highlightedField)) {
      setHighlighted(true);
    } else {
      setHighlighted(false);
    }
  }, [fieldItem, highlightedField]);

  const { filtering_fields, filtering_operators } = filterData;

  const handleOnChangeField = e => {
    const currentFilteringField = filtering_fields?.find(
      i => i.id === e.target.value,
    );
    const operatorDefaultOption =
      currentFilteringField?.compatible_operators[0];

    doSaveToState(
      {
        field: e.target.value,
        operator: operatorDefaultOption || '',
        value: '',
      },
      index,
    );
    setHighlightedField(undefined);
  };
  const handleOnChangeOperator = e => {
    doSaveToState(
      {
        field,
        operator: e.target.value,
        value: '',
      },
      index,
    );
    setHighlightedField(undefined);
  };
  const handleOnChangeValue = e => {
    doSaveToState({ field, operator, value: e.target.value }, index);
    setHighlightedField(undefined);
  };

  const fieldOptions = filtering_fields?.map(i => ({
    label: i.label,
    value: i.id,
  }));

  const currentFilteringField = filtering_fields?.find(i => i.id === field);

  const operatorOptions = currentFilteringField?.compatible_operators?.map(
    i => ({
      value: i,
      label: filtering_operators?.find(item => item.id === i)?.label || '',
    }),
  );

  const currentFilteringOperator = filtering_operators?.find(
    i => i.id === operator,
  );

  const handleRemoveField = () => removeField(index);

  return (
    <Box>
      <StyledField highlighted={highlighted}>
        <Box>
          <Select
            id="filter_field"
            name="field"
            placeholder={t(translations.components.filter.fieldPlaceholder)}
            value={field.length ? field : ' '}
            onChange={handleOnChangeField}
            options={fieldOptions}
            fullWidth
          />
          {!isEmptyFilter && (
            <HiddenInput
              type="select"
              value={field}
              onChange={handleOnChangeField}
            />
          )}
        </Box>
        <Box>
          <Select
            id="filter_operator"
            name="operator"
            className="operator"
            placeholder={t(translations.components.filter.operatorPlaceholder)}
            value={operator.length ? operator : ' '}
            onChange={handleOnChangeOperator}
            options={operatorOptions}
            fullWidth
            disabled={field === ' ' || field === ''}
          />
          {!isEmptyFilter && (
            <HiddenInput
              type="select"
              value={operator}
              onChange={handleOnChangeOperator}
            />
          )}
          {currentFilteringOperator?.value_type !== 'none' && (
            <Box className="hidden">
              {!isEmptyFilter && (
                <HiddenInput value={value} onChange={handleOnChangeValue} />
              )}
            </Box>
          )}
        </Box>
        <ValueInput
          id="filer_value"
          value={value}
          currentFilteringOperator={currentFilteringOperator}
          handleOnChange={handleOnChangeValue}
          options={currentFilteringField?.options || []}
        />
        <IconButton
          size="small"
          className="remove"
          onClick={handleRemoveField}
          data-test="button_filter_clear"
        >
          <Clear />
        </IconButton>
      </StyledField>
      {!isLast && (
        <Typography
          variant="body2"
          style={{ color: '#108596', margin: '0 0 -.5rem 0' }}
        >
          <i>{mainOperator}</i>
        </Typography>
      )}
    </Box>
  );
}
