/**
 *
 * UserInfoSection
 *
 */

import React from 'react';
import { Box, Typography } from '@mui/material';
import { useRoot } from 'app/containers/Root/useRoot';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';

export function UserInfoSection() {
  const { t } = useTranslation();

  const { current_user } = useRoot();

  if (!current_user) {
    return null;
  }

  const { name, user_metadata } = current_user;

  const { position = '', business_unit = '' } = user_metadata || {};

  return (
    <Box p=".5rem 1rem 1rem">
      <Typography
        color="textPrimary"
        variant="body2"
        style={{ margin: '0 0 1rem 0' }}
      >
        <strong>{t(translations.mainNav.userDetails)}</strong>
      </Typography>
      <Typography color="textPrimary" variant="body2" gutterBottom>
        {name && (
          <Typography
            color="textPrimary"
            variant="body2"
            gutterBottom
            component="span"
          >
            {name}
          </Typography>
        )}
        {business_unit && (
          <Typography
            color="textPrimary"
            variant="body2"
            gutterBottom
            component="span"
          >
            {`, ${business_unit}`}
          </Typography>
        )}
      </Typography>
      {position && (
        <Typography color="textSecondary" variant="body2">
          {position}
        </Typography>
      )}
    </Box>
  );
}
