import { PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'types/errors';
import { IAsideItem, ICurrentUser } from 'types/user';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { IsFullScreenCurrently } from 'utils/fullScreenFunctions';
import { IAlert } from './Alerts/types';
import {
  ContainerState,
  ISendFeedback,
  IWithTransactionId,
  PermissionsType,
} from './types';
import { APIResponseBootstrapEnv } from 'api/bootstrap';
import { generateRandomId } from 'utils/common';

// The initial state of the Root container
export const initialState: ContainerState = {
  isReady: false,
  loading: true,
  fullsScreenMode: IsFullScreenCurrently(),
  alerts: [],

  permissions: [],
};

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    doInit(state) {
      state.loading = true;
    },
    setReady(state) {
      state.isReady = true;
    },
    onError(state, action: PayloadAction<IError>) {
      state.loading = false;
      state.error = action.payload;
    },
    setAsideConfig(state, action: PayloadAction<IAsideItem[]>) {
      state.aside = action.payload;
    },
    getCustomerInfoOk(state, action: PayloadAction<ICurrentUser>) {
      state.loading = false;
      state.current_user = action.payload;
    },
    getBootstrapEnvOk(state, action: PayloadAction<APIResponseBootstrapEnv>) {
      state.loading = false;
      state.documentation_url = action.payload.documentation_url;
    },
    doLogout(state) {
      state.loading = true;
    },
    doToggleFullScreenMode(state, action: PayloadAction<boolean>) {
      state.fullsScreenMode = action.payload;
    },
    doChangeLanguage(state, _action: PayloadAction<string>) {
      state.loading = true;
      state.isReady = false;
    },
    doChangeLanguageOk(state) {
      state.loading = false;
      state.isReady = true;
    },
    addAlert(state, action: PayloadAction<IAlert>) {
      state.alerts.push(action.payload);
    },
    addDefaultErrorAlert(state, action: PayloadAction<IWithTransactionId>) {
      state.alerts.push({
        id: generateRandomId(),
        type: 'error',
        transactionId: action.payload.transactionId,
      });
    },
    removeAlert(state, action: PayloadAction<IAlert>) {
      state.alerts = state.alerts.filter(i => i.id !== action.payload.id);
    },
    sendFeedback(_state, _action: PayloadAction<ISendFeedback>) {},

    setPermissions(state, action: PayloadAction<PermissionsType[]>) {
      state.permissions = action.payload;
    },

    fetchAllUsers(_state) {},
    fetchAdminUsers(_state) {},
    fetchClientUsers(_state) {},
  },
});

export const { actions, reducer, name: sliceKey } = rootSlice;
