import React, { FocusEventHandler, MouseEventHandler } from 'react';
import { Checkbox as MUCheckBox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from 'app/components/Icon';
import { WithTestID } from 'types/common';

interface Props extends WithTestID {
  label?: string | number | React.ReactElement;
  name?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler;
  size?: 'small' | 'medium';
  indeterminate?: boolean;
  style?: any;
}

export const StyledCheckbox = styled(MUCheckBox, {
  shouldForwardProp: prop => prop !== 'readonly',
})<Props>`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
    ${({ size }) => size === 'small' && 'font-size: .9rem;'}
  }
`;

export const StyledFormControl = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'readonly',
})<Props>`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }
`;

export function CheckBox({
  label,
  name,
  checked,
  onChange,
  size,
  indeterminate,
  style,
  onClick,
  onFocus,
  test_id,
}: Props) {
  if (label) {
    return (
      <StyledFormControl
        onClick={onClick}
        onFocus={onFocus}
        data-test={test_id}
        control={
          <StyledCheckbox
            checked={checked}
            onChange={onChange}
            name={name}
            size={size}
            checkedIcon={<Icon name="check_box_icon" />}
            icon={<Icon name="check_box_blank" />}
            indeterminateIcon={<Icon name="check_box_interminate" />}
            indeterminate={indeterminate}
            style={style}
          />
        }
        label={label || ''}
      />
    );
  }

  return (
    <StyledCheckbox
      checked={checked}
      onChange={onChange}
      name={name}
      size={size}
      checkedIcon={<Icon name="check_box_icon" />}
      icon={<Icon name="check_box_blank" />}
      indeterminateIcon={<Icon name="check_box_interminate" />}
      indeterminate={indeterminate}
      style={style}
      data-test={test_id}
    />
  );
}
