import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockRiskDomains } from 'app/containers/RootInsights/types';
import { risk_domainsMock } from 'api/thirdParties/getRiskDomainsAPI/mock';

const { datatype } = faker;

export const insightsItemDataBlockRiskDomainsMock: () => IInsightsItemDataBlockRiskDomains =
  () => ({
    id: 'IInsightsItemDataBlockRiskDomains',
    type: 'risk-domains',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Risk Domains',
    risk_domains: risk_domainsMock,
  });
