type InputType = Record<string, any> | any[];

export function deepNormalizeData<T extends InputType>(input: T): T {
  const normalizeProperty = (value: any): any => {
    if (value === null) {
      // Replace null with undefined
      // return undefined;

      // OR Skip properties with null values
      return undefined;
    }

    if (Array.isArray(value)) {
      // Recursively normalize arrays of objects
      return value.map(item => deepNormalizeData(item));
    }

    if (typeof value === 'object') {
      // Recursively normalize nested objects
      return deepNormalizeData(value);
    }

    return value;
  };

  if (typeof input === 'object' && input !== null) {
    if (Array.isArray(input)) {
      // Normalize array of objects
      return input.map(item => deepNormalizeData(item)) as T;
    } else {
      // Normalize object
      const normalizedObject: Record<string, any> = { ...input };

      for (const key in normalizedObject) {
        if (normalizedObject.hasOwnProperty(key)) {
          normalizedObject[key] = normalizeProperty(normalizedObject[key]);
        }
      }

      return normalizedObject as T;
    }
  }

  return input;
}
