import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { LogoBox } from '../Header/styled';
import { MainLogo } from 'app/components/Icons/svg/MainLogo';
import { Box, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoot } from '../selectors';
import { actions } from '../slice';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Icon } from 'app/components/Icon';
import { IError } from 'types/errors';

type SelectIconProps = IError;
const SelectIcon = ({ type }: SelectIconProps) => {
  switch (type) {
    case 'no_access':
      return <Icon name="lock" color="#EB5757" width="3rem" height="3rem" />;
    case 'crash':
    default:
      return (
        <Icon name="sad_face" color="#EB5757" width="3rem" height="3rem" />
      );
  }
};

const SelectTitleIcon = ({ type }: SelectIconProps) => {
  switch (type) {
    case 'no_access':
      return (
        <Icon name="no_access" color="#EB5757" width="397px" height="75px" />
      );
    case 'crash':
    default:
      return <Icon name="oops" color="#EB5757" width="250px" height="97px" />;
  }
};

export function PageError() {
  const { t } = useTranslation();

  const { error } = useSelector(selectRoot);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    return history.listen(_location => {
      if (history.action === 'POP') {
        window.location.reload();
      }
    });
  }, [history]);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    dispatch(actions.doLogout());
  };

  if (!error) return null;

  const message =
    'message' in error ? error.message : t(translations.errorPage.pageTitle);

  return (
    <>
      <Helmet>
        <title>{t(translations.errorPage.pageTitle)}</title>
        <meta
          name="description"
          content={t(translations.errorPage.pageTitle)}
        />
      </Helmet>
      <Box p="4rem">
        <Box>
          <LogoBox href="/">
            <MainLogo />
          </LogoBox>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: 'calc(100vh - 12rem)' }}
        >
          <Box display="flex">
            <Box m="1rem 2rem 0 0">
              <SelectIcon {...error} />
            </Box>
            <Box>
              <SelectTitleIcon {...error} />
              <Box m="1rem 0 0 0">
                <Typography variant="h5" color="error" gutterBottom>
                  {message}
                </Typography>
                <Typography variant="body1">
                  <Link style={{ cursor: 'pointer' }} onClick={handleRefresh}>
                    {t(translations.errorPage.refresh)}
                  </Link>
                  {' | '}
                  <Link style={{ cursor: 'pointer' }} onClick={handleLogout}>
                    Logout
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
