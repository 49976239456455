import { useAlerts } from 'app/containers/Root/Alerts/useAlerts';
import { useState } from 'react';
import { downloadSingleFile } from 'api/files/downloadSingleFile';

export type UseDownloadSingleFile = {
  loading: boolean;
  handleDownloadFile: (file_id: string, file_name: string) => void;
};

export type UseDownloadSingleFileHook = () => UseDownloadSingleFile;

export const useDownloadSingleFile: UseDownloadSingleFileHook = () => {
  const { addDefaultErrorAlert } = useAlerts();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadFile = async (file_id: string, file_name: string) => {
    try {
      setLoading(true);

      const { url } = await downloadSingleFile({ file_id });

      if (url) {
        const link = document.createElement('a');
        link.download = file_name;
        link.href = url;
        link.click();
      }
    } catch (e: any) {
      addDefaultErrorAlert(e.transactionId);
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleDownloadFile };
};
