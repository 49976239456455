/**
 *
 * Tag
 *
 */
import React from 'react';
import { styled } from '@mui/material/styles';

interface TagProps {
  children: React.ReactNode;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

export const StyledTag = styled('span')`
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  border-radius: 4px;
  padding: 0 0.5rem;
  line-height: 1.1;
  color: ${({ theme }) => theme.palette.primary.dark};
  user-select: none;
  display: inline-block;
`;

export function Tag({ children, onMouseEnter, onMouseLeave }: TagProps) {
  return (
    <StyledTag onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </StyledTag>
  );
}
