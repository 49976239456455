import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';
import { AbstractDataType } from './types';

const selectDomain = (state: RootState) =>
  state.useGetAbstractData || initialState;

export const selectUseGetAbstractData = <T extends AbstractDataType>(id: T) =>
  createSelector(
    [selectDomain],
    useGetAbstractDataState => useGetAbstractDataState[id],
  );
