import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';

export type SystemReportType =
  | 'controls_benchmark'
  | 'attributes_benchmark'
  | 'rejected_evidence';

export interface APIPayloadReport {
  type: SystemReportType;
}

export type APIResponseReport = Blob | File;

export const getReportAPI: RequestApi<
  APIPayloadReport,
  APIResponseReport
> = async ({ type }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(new Blob());
      }, apiDelay);
    });
  }

  return request(`/system_reports/${type}`, { signal, file: true });
};
