import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export type IHighlightTag = 'mark' | 'strong';

interface Props {
  searchText: string;
  children: string;
  highlightTag: IHighlightTag;
  ignoreCase?: boolean;
}

export function Highlight({ searchText, children, highlightTag }: Props) {
  if (!searchText) {
    return <>{children}</>;
  }

  const matches = match(children, searchText, { insideWords: true });

  const parts = parse(children, matches);
  if (highlightTag === 'mark') {
    return (
      <>
        {parts.map((part, index) =>
          part.highlight ? (
            <mark key={index} style={{ fontWeight: 'bold' }}>
              {part.text}
            </mark>
          ) : (
            <span key={index}>{part.text}</span>
          ),
        )}
      </>
    );
  }

  return (
    <>
      {parts.map((part, index) =>
        part.highlight ? (
          <strong key={index}>{part.text}</strong>
        ) : (
          <span key={index}>{part.text}</span>
        ),
      )}
    </>
  );
}
