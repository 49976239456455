/**
 *
 * MainLogo
 *
 */

export function MainLogo() {
  return (
    <svg
      width="27"
      height="30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 30"
    >
      <circle cx="20" cy="7" r="7" fill="#653DF4" />
      <path d="M12 7L0 14V0l12 7z" fill="#FFC72B" />
      <path
        d="M13 17h14v4.875H13V17zm0 4.875h9.882v4.063H13v-4.063zm0 4.063h4.941V30H13v-4.062z"
        fill="#B2AB8E"
      />
      <path d="M0 17h4.5a6.5 6.5 0 110 13H0V17z" fill="#001591" />
    </svg>
  );
}
