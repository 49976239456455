import { useDispatch, useSelector } from 'react-redux';

import { useInjectSaga } from 'utils/redux-injectors';
import { actions, sliceKey } from './slice';
import { selectUseGetAbstractData } from './selectors';
import { useGetAbstractDataSaga } from './saga';
import { Cancelable, IAbstractDataState, IUseGetAbstractData } from './types';
import { useCallback, useEffect, useRef } from 'react';
import { debounce } from '@mui/material';

export const useGetAbstractData: IUseGetAbstractData = (
  { id, params },
  options,
) => {
  useInjectSaga({ key: sliceKey, saga: useGetAbstractDataSaga });

  const {
    preventFetch = false,
    clearOnUnmount = true,
    updateInterval,
  } = options || {};

  const state = useSelector(selectUseGetAbstractData(id)) as IAbstractDataState<
    typeof id
  >;

  const dispatch = useDispatch();

  const updateData = (updateParams: typeof params) => {
    getAbstractData(updateParams);
  };

  const debouncedUpdateDataHandler = useRef<
    (((params: any) => void) & Cancelable) | null
  >(null);

  useEffect(() => {
    // recreate the debounced function when updateInterval changes
    debouncedUpdateDataHandler.current = debounce(updateData, updateInterval);

    // cleanup function to cancel any pending operations when updateInterval changes or component unmounts
    return () => {
      if (debouncedUpdateDataHandler?.current?.clear) {
        debouncedUpdateDataHandler.current.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateInterval]);

  const getAbstractData = useCallback(
    (cbParams: typeof params) => {
      dispatch(actions.getAbstractData({ id, params: cbParams }));

      if (updateInterval && debouncedUpdateDataHandler.current) {
        debouncedUpdateDataHandler.current(cbParams);
      }
    },
    [debouncedUpdateDataHandler, dispatch, id, updateInterval],
  );

  const resetAbstractData = useCallback(
    () => dispatch(actions.resetAbstractData({ id })),
    [dispatch, id],
  );

  const depsList = params
    ? Object.values(params).map(i => JSON.stringify(i))
    : [];

  const depsNamesList = params ? Object.keys(params) : [];

  useEffect(() => {
    if (!preventFetch) {
      getAbstractData(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depsList, ...depsNamesList]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        resetAbstractData();
      }
    };
  }, [clearOnUnmount, resetAbstractData]);

  return { ...state, getAbstractData, resetAbstractData };
};
