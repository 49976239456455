import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { SelectOptionType } from 'app/components/Inputs/Select_old';
import { mock } from 'api/thirdParties/getScopeOptionsListAPI/mock';

export interface APIPayloadScopeOptionsList extends WithPartyId {}

export interface APIResponseScopeOptionsList {
  scopes: SelectOptionType[];
}

export const getScopeOptionsListAPI: RequestApi<
  APIPayloadScopeOptionsList,
  APIResponseScopeOptionsList
> = async ({ thirdPartyId, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/third_parties/${thirdPartyId}/scopes/options`, { signal });
};
