import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockLocation } from 'app/containers/RootInsights/types';

const { datatype, address } = faker;

export const insightsItemDataBlockLocationMock: () => IInsightsItemDataBlockLocation =
  () => ({
    id: 'IInsightsItemDataBlockLocation',
    type: 'location',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Location',
    address: address.streetAddress(),
    location_type: 'string',
    entity_id: datatype.uuid(),
  });
