import { IFilteringOperator, IFilteringValueOption } from './types';
import { Select } from 'app/components/Inputs/Select_old';
import { AutocompleteCheckbox } from 'app/components/Inputs/AutocompleteCheckbox';
import { useFilter } from './FilterContext';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { TextField } from 'app/components/Inputs/TextField';

interface Props {
  id: string;
  value: string | string[];
  currentFilteringOperator?: IFilteringOperator;
  handleOnChange: (e) => void;
  options?: IFilteringValueOption[];
}

export function ValueInput({
  id,
  value,
  currentFilteringOperator,
  handleOnChange,
  options,
}: Props) {
  const { t } = useTranslation();
  const { setIsSelectOpen } = useFilter();
  const onSelectOpen = () => setIsSelectOpen(true);
  const onSelectClose = () => setTimeout(() => setIsSelectOpen(false), 500);
  switch (currentFilteringOperator?.value_type) {
    case 'boolean':
      const booleanOptions = ['true', 'false'].map(value => ({
        label: t(translations.components.filter[value]()),
        value,
      }));
      return (
        <Select
          id={id}
          placeholder={t(translations.components.filter.valuePlaceholder)}
          value={value?.length ? value : ' '}
          onChange={handleOnChange}
          options={booleanOptions}
          fullWidth
          disablePlaceholder
          onClick={onSelectOpen}
          onBlur={onSelectClose}
        />
      );
    case 'select':
      return (
        <Select
          id={id}
          placeholder={t(translations.components.filter.valuePlaceholder)}
          value={value?.length ? value : ' '}
          onChange={handleOnChange}
          options={options}
          fullWidth
          required
        />
      );
    case 'multiple_select':
      const mappedValues = Array.isArray(value)
        ? value.map(i => {
            const currentOption = options?.find(o => o.value === i);
            return {
              value: i,
              label: currentOption ? currentOption.label : i,
            };
          })
        : [];
      return (
        <AutocompleteCheckbox
          values={mappedValues}
          options={currentFilteringOperator?.custom_options || options || []}
          handleOnChange={handleOnChange}
          placeholder="Values"
          onOpen={onSelectOpen}
          onClose={onSelectClose}
          test_id={`input_${id}`}
        />
      );
    case 'multiple_text':
    case 'text':
      return (
        <TextField
          id={id}
          name="value"
          placeholder={t(translations.components.filter.valuePlaceholder)}
          value={value}
          onChange={handleOnChange}
          required
          fullWidth
        />
      );
    case 'none':
    default:
      return (
        <TextField
          id={id}
          name="value"
          placeholder={t(translations.components.filter.valuePlaceholder)}
          value={value || currentFilteringOperator?.default_value || ''}
          disabled
          onChange={handleOnChange}
          fullWidth
        />
      );
  }
}
