import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { IRiskDomainTag } from 'app/containers/RootThirdParty/types';
import { mock } from 'api/thirdParties/getRiskDomainsAPI/mock';

export interface APIPayloadRiskDomains extends WithPartyId {}

export type APIResponseRiskDomains = {
  risk_domains: IRiskDomainTag[];
};

export const getRiskDomainsAPI: RequestApi<
  APIPayloadRiskDomains,
  APIResponseRiskDomains
> = async ({ thirdPartyId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/third_parties/${thirdPartyId}/risk_domains`, { signal });
};
