/**
 *
 * CellScopesList
 *
 */

import React from 'react';
import { IScopesListCell } from '../../types';
import { SecondaryActionCell } from '../SecondaryActionCell';
import { TableLinkList } from './TableLinkList';
import { useTableRow } from '../../TableRow/TableRowContext';
import { useHistory } from 'react-router-dom';

export const CellScopesList = ({ data }: IScopesListCell) => {
  const history = useHistory();

  const { row } = useTableRow();

  const { scopes } = data;

  const onScopeClick = e => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/third-party/${row.id}/scope/${e.currentTarget.id}`);
  };

  return (
    <SecondaryActionCell>
      <TableLinkList links={scopes || []} onScopeClick={onScopeClick} />
    </SecondaryActionCell>
  );
};
