import { PayloadAction } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { doLogoutSaga } from 'app/containers/Root/generators/doLogoutSaga';
import { getCurrentUser } from 'app/containers/Root/generators/getCurrentUser';
import { getLocalization } from 'app/containers/Root/generators/getLocalization';
import i18next from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IError } from 'types/errors';
import { NODE_ENV, USE_MOCK } from 'utils/constants';
import {
  GoInFullscreen,
  GoOutFullscreen,
  IsFullScreenCurrently,
} from 'utils/fullScreenFunctions';
import { request } from 'utils/request';
import { checkSession } from './checkSession';
import { actions as actionsRoot, actions } from './slice';
import { ISendFeedback, PermissionsType } from './types';
import { getBootstrapEnv } from './generators/getDocumentationLink';

export function* authentication() {
  if (USE_MOCK) {
    yield put(
      actions.setPermissions([
        'create:third_party',
        'read:rbm',
        'create:scope',
        'read:reports',
        'read:rh',
      ]),
    );

    return true;
  }

  const { pathname, search } = window.location;

  const token = yield checkSession();

  const t_pathname = sessionStorage.getItem('t_pathname');

  const t_search = sessionStorage.getItem('t_search');

  if (!token) {
    if (!t_pathname && !t_search) {
      sessionStorage.setItem('t_pathname', pathname);

      sessionStorage.setItem('t_search', search);
    }

    return false;
  }

  sessionStorage.setItem('t', token);

  const decoded = jwtDecode(token) as { permissions: PermissionsType[] };

  yield put(actions.setPermissions(decoded.permissions));

  return true;
}

export function* doInitSaga() {
  try {
    const token = yield authentication();

    if (!token) {
      return;
    }

    yield getCurrentUser();

    yield getLocalization();

    yield getBootstrapEnv();

    yield put(actions.setReady());
  } catch (e: any) {
    yield put(
      actions.onError({
        type: 'crash',
        transactionId: e.transactionId,
      }),
    );
  }
}

export function sendFeedbackSaga({ payload }: PayloadAction<ISendFeedback>) {
  const eventId = Sentry.captureMessage(payload.transactionId);
  Sentry.showReportDialog({
    eventId,
    title: 'Your Feedback',
    subtitle: 'It looks like we’re having some internal issues.',
    subtitle2: `\nYou can provide our team additional information about the issue you have encountered. \n \n [Request ID: ${payload.transactionId}]`,
    labelName: 'Your Name',
    labelEmail: 'Your Email',
    labelComments: 'Your Feedback',
    labelSubmit: 'Send',
    labelClose: 'Cancel',
  });
}

export function* doToggleFullScreenModeSaga(action: PayloadAction<boolean>) {
  try {
    if (action.payload) {
      GoInFullscreen(window.document.body);
    } else if (IsFullScreenCurrently()) {
      GoOutFullscreen();
    }
  } catch (e: any) {
    yield put(
      actionsRoot.addDefaultErrorAlert({
        transactionId: e.transactionId,
      }),
    );
  }
}

export function* doChangeLanguageSaga(action: PayloadAction<string>) {
  try {
    const translations = yield call(request, `/localization/${action.payload}`);

    const currentUser = yield call(request, `/users/current`, {
      method: 'POST',
      body: JSON.stringify({
        language: action.payload,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    yield put(actions.getCustomerInfoOk(currentUser));

    i18next.addResourceBundle('en', 'translation', translations);

    yield put(actions.doChangeLanguageOk());
  } catch (e: any) {
    yield put(
      actions.onError({ type: 'crash', transactionId: e.transactionId }),
    );
  }
}

export function* onErrorSaga(action: PayloadAction<IError>) {
  const { transactionId } = action.payload;

  if (NODE_ENV === 'development') {
    yield put(
      actionsRoot.addDefaultErrorAlert({
        transactionId,
      }),
    );
  }
}

export function* rootSaga() {
  yield takeLatest(actions.doInit.type, doInitSaga);
  yield takeLatest(actions.doLogout.type, doLogoutSaga);
  yield takeLatest(actions.onError.type, onErrorSaga);
  yield takeLatest(actions.sendFeedback.type, sendFeedbackSaga);
  yield takeLatest(actions.doChangeLanguage.type, doChangeLanguageSaga);
  yield takeLatest(
    actions.doToggleFullScreenMode.type,
    doToggleFullScreenModeSaga,
  );
}
