import { StyledCloseButton, StyledHeader } from './styled';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useNotificationCenter } from './Context';
import { Typography } from 'app/components/Typography';
import { Icon } from 'app/components/Icon';

export const Header = () => {
  const { t } = useTranslation();

  const { closeSettings } = useNotificationCenter();

  return (
    <StyledHeader>
      <Typography variant="body1" color="textPrimary" align="center">
        <strong>
          {t(translations.notificationSettings.notificationCenter)}
        </strong>
      </Typography>
      <StyledCloseButton onClick={closeSettings} size="large">
        <Icon name="close" />
      </StyledCloseButton>
    </StyledHeader>
  );
};
