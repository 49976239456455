import { useTable } from '../TableContext';
import { IHeaderItem } from '../types';
import { TableHeadItem } from './TableHeadItem';
import { StyledTableHeaderTh } from '../styled';
import { CheckBox } from 'app/components/Inputs/CheckBox';
import { checkIfRowsSelected } from '../utils';

export const TableHead = () => {
  const { table, hideCol, selectable, selected_ids, selectAllRows } =
    useTable();

  const { selected, all } = checkIfRowsSelected(table, selected_ids);

  const handleSelectAllRows = () => {
    if (!selectAllRows) {
      return;
    }

    selectAllRows(table.rows, !selected);
  };

  return (
    <thead>
      <tr>
        {selectable && (
          <StyledTableHeaderTh is_empty>
            <CheckBox
              indeterminate={selected && !all}
              checked={selected}
              onChange={handleSelectAllRows}
              size="small"
            />
          </StyledTableHeaderTh>
        )}
        {table.header.items
          .filter((item, n) => n !== hideCol)
          .map((item: IHeaderItem) => (
            <TableHeadItem key={item.id} {...item} />
          ))}
      </tr>
    </thead>
  );
};
