import React from 'react';
import { Box, Grow } from '@mui/material';
import { CheckBox } from 'app/components/Inputs/CheckBox';
import { Select } from 'app/components/Inputs/Select_old';
import { useNotificationCenter } from '../../Context';
import { IAbstractFieldCheckboxWithSelect } from 'app/containers/Root/Notifications/Form/types';
import { useSelector } from 'react-redux';
import { selectEventFormField } from 'app/containers/Root/Notifications/selectors';

interface FieldCheckboxWithSelectProps
  extends IAbstractFieldCheckboxWithSelect {}

export const FieldCheckboxWithSelect = ({
  id,
  label,
  options,
}: FieldCheckboxWithSelectProps) => {
  const { value } = useSelector(
    selectEventFormField(id),
  ) as IAbstractFieldCheckboxWithSelect;

  const { onChangeEventFieldValue } = useNotificationCenter();

  const handleChange = () => {
    onChangeEventFieldValue({
      field_id: id,
      value: value ? undefined : options[0].value,
    });
  };

  const handleChangeSelect = e => {
    onChangeEventFieldValue({
      field_id: id,
      value: e.target.value,
    });
  };

  return (
    <Box display="flex" gap=".5rem" alignItems="center" height="2.5rem">
      <CheckBox
        label={label}
        size="small"
        checked={!!value}
        onChange={handleChange}
      />
      <Grow in={!!value}>
        <Box width="10rem">
          {value && (
            <Select
              options={options}
              onChange={handleChangeSelect}
              value={value}
              margin="none"
            />
          )}
        </Box>
      </Grow>
    </Box>
  );
};
