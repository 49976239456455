/**
 *
 * CellStatusIcon
 *
 */

import React from 'react';
import { IStatusIconCell } from '../../types';
import { StyledTableIcon } from '../../styled';
import { IconStatus } from '../../../../components/Icons/IconStatus';

export const CellStatusIcon = ({ data }: IStatusIconCell) => {
  const { status } = data;

  return (
    <StyledTableIcon>
      <IconStatus icon={status} />
    </StyledTableIcon>
  );
};
