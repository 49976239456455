/**
 *
 * TextWithMods
 *
 */
import React from 'react';
import { TableCellDataMod } from 'app/containers/AbstractTable/types';
import { Link } from '@mui/material';
import { Typography } from 'app/components/Typography';

interface WithBoldProps {
  isBold: boolean;
  children: React.ReactNode;
}

const WithBold = ({ isBold, children }: WithBoldProps) => {
  if (isBold) {
    return <strong>{children}</strong>;
  }

  return <>{children}</>;
};

interface WithItalicProps {
  isItalic: boolean;
  children: React.ReactNode;
}

const WithItalic = ({ isItalic, children }: WithItalicProps) => {
  if (isItalic) {
    return <i>{children}</i>;
  }

  return <>{children}</>;
};

interface WithLinkProps {
  isLink: boolean;
  children: React.ReactNode;
}

const WithLink = ({ isLink, children }: WithLinkProps) => {
  if (isLink) {
    return <Link>{children}</Link>;
  }

  return <>{children}</>;
};

interface WithErrorProps {
  isError: boolean;
  children: React.ReactNode;
}

const WithError = ({ isError, children }: WithErrorProps) => {
  if (isError) {
    return (
      <Typography variant="inherit" color="error" component="span">
        {children}
      </Typography>
    );
  }

  return <>{children}</>;
};

interface Props {
  children: React.ReactNode;
  mods: TableCellDataMod[];
}

export const TextWithMods = ({ mods, children }: Props) => (
  <WithError isError={mods.includes('error')}>
    <WithBold isBold={mods.includes('bold')}>
      <WithItalic isItalic={mods.includes('italic')}>
        <WithLink isLink={mods.includes('link')}>{children}</WithLink>
      </WithItalic>
    </WithBold>
  </WithError>
);
