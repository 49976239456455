import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { APIPayloadRiskBoardMatrixCell } from 'api/riskBoard/getRiskBoardMatrixCellAPI';
import { mock } from 'api/riskBoard/getRiskBoardMatrixCellGroupsAPI/mock';

export interface APIPayloadRiskBoardMatrixCellGroups
  extends APIPayloadRiskBoardMatrixCell {}

export interface ITrendGroup {
  count: string;
  id: string;
  label: string;
}

export interface IAbstractMatrixTrendGroups {
  impact: string;
  probability: string;
  trend_label: string;
  groups: ITrendGroup[];
}

export interface APIResponseRiskBoardMatrixCellGroups
  extends IAbstractMatrixTrendGroups {}

export const getRiskBoardMatrixCellGroupsAPI: RequestApi<
  APIPayloadRiskBoardMatrixCellGroups,
  APIResponseRiskBoardMatrixCellGroups
> = async ({ cell_id, tab_id, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(
    `/risk_board/trends/${tab_id}/${cell_id}/groups?${stringifyParams(props)}`,
    {
      signal,
    },
  );
};
