import { Box } from 'app/components/Box';
import { Typography } from 'app/components/Typography';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { Link } from 'app/components/Link';
import { Divider } from 'app/components/Divider';
import { SUPPORT_LINK } from 'utils/constants';
import { useRoot } from '../../useRoot';

export function SupportSection() {
  const { documentation_url } = useRoot();

  const handleOpenDocumentation = () => {
    window.open(documentation_url, '_blank');
  };

  const handleSupportLink = () => {
    window.open(SUPPORT_LINK, '_blank');
  };

  return (
    <>
      <Box p=".5rem 1rem 0">
        <Typography color="textPrimary" variant="body2" m="0 0 .5rem 0">
          <strong>Support</strong>
        </Typography>
      </Box>
      <Box component="ul" p={0} m={0}>
        <MenuItem onClick={handleSupportLink}>
          <Link underline="none" variant="body2" component="span">
            Contact Us
          </Link>
        </MenuItem>
        <MenuItem onClick={handleOpenDocumentation}>
          <Link underline="none" variant="body2" component="span">
            Documentation
          </Link>
        </MenuItem>
      </Box>
      <Box m=".5rem 0">
        <Divider variant="middle" />
      </Box>
    </>
  );
}
