import { APIResponseNewPartyForm } from 'api/thirdParties/getNewPartyFormAPI/index';

export const mock: APIResponseNewPartyForm = {
  blocks: [
    {
      id: 'party_info_block',
      fields: [
        {
          id: 'external_id',
          label: 'ID',
          type: 'text',
          placeholder: 'Enter Party Id',
          required: true,
        },
        {
          id: 'name',
          label: 'Name',
          type: 'autocomplete',
          autocomplete_type: 'party_name',
          placeholder: 'Enter Name',
          required: true,
          // disable_edit: true,
        },
        {
          id: 'domain',
          label: 'Website',
          type: 'domain',
          placeholder: 'www.site.com',
          disable_edit: true,
        },
        {
          id: 'location',
          label: 'HQ location',
          type: 'autocomplete',
          autocomplete_type: 'location',
          placeholder: 'Enter location',
          required: true,
          disable_edit: true,
        },
      ],
    },
  ],
};
