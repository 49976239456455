import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockList } from 'app/containers/RootInsights/types';

const { datatype, lorem } = faker;

export const insightsItemDataBlockListMock: () => IInsightsItemDataBlockList =
  () => ({
    id: 'IInsightsItemDataBlockList',
    type: 'list',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block List',
    list: [lorem.text(), lorem.text(), lorem.text()],
  });
