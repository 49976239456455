/**
 *
 * InputSwitch
 *
 */
import React from 'react';
import { Grid } from '@mui/material';
import { BigSwitch, StyledLabel, StyledSwitchWrapper } from './styled';
import { InputSwitchProps } from './index';

export const StyleBig = ({
  on,
  off,
  value,
  name,
  handleChange,
  test_id,
}: InputSwitchProps) => {
  const handleSwitch = () => {
    handleChange(!value);
  };

  return (
    <StyledSwitchWrapper data-test={test_id}>
      <Grid component="label" container alignItems="center" spacing={1}>
        {off && (
          <Grid item>
            <StyledLabel isOn={!value}>{off}</StyledLabel>
          </Grid>
        )}
        <Grid item>
          <BigSwitch checked={value} onChange={handleSwitch} name={name} />
        </Grid>
        {on && (
          <Grid item>
            <StyledLabel isOn={value}>{on}</StyledLabel>
          </Grid>
        )}
      </Grid>
    </StyledSwitchWrapper>
  );
};
