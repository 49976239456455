import { configureStore, StoreEnhancer } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { NODE_ENV } from 'utils/constants';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  return configureStore({
    reducer: createReducer(),
    middleware: defaultMiddleware => [...defaultMiddleware(), ...middlewares],
    devTools:
      /* istanbul ignore next line */
      NODE_ENV !== 'production' || process.env.PUBLIC_URL.length > 0,
    enhancers,
  });
}
