/**
 *
 * CellExpandedState
 *
 */

import React from 'react';
import { StyledExpState } from '../../styled';
import { useTableRow } from '../../TableRow/TableRowContext';
import { KeyboardArrowDown } from '@mui/icons-material';

export const CellExpandedState = () => {
  const { isExpanded } = useTableRow();

  return (
    <StyledExpState isExpanded={isExpanded}>
      <KeyboardArrowDown color="inherit" />
    </StyledExpState>
  );
};
