import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import {
  IAbstractFilterData,
  IAbstractPageData,
  IAbstractTable,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { mock } from 'api/thirdParties/getPartyDocumentsAPI/mock';
import { FilesTabType } from 'api/files/getFilesConfig';

export interface APIPayloadPartyDocuments extends IPageParams, WithPartyId {
  filesTab: FilesTabType;
}

export interface APIResponsePartyDocuments
  extends IAbstractTable,
    IAbstractFilterData,
    IAbstractPageData {}

export const getPartyDocumentsAPI: RequestApi<
  APIPayloadPartyDocuments,
  APIResponsePartyDocuments
> = async ({ thirdPartyId, filesTab, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock(filesTab));
      }, apiDelay);
    });
  }

  return request(
    `/document_vault/${thirdPartyId}/list/${filesTab}?${stringifyParams(
      props,
    )}`,
    { signal },
  );
};
