import {
  IUseDebouncedSearchOptions,
  useDebouncedSearch,
} from 'app/hooks/useDebouncedSearch';
import { SearchResponse, UseSearch } from 'app/hooks/useSearch/types';
import { searchAPI } from 'api/searchAPI';
import { deepNormalizeData } from 'utils/normalizeData';

export function useSearch(options?: IUseDebouncedSearchOptions): UseSearch {
  const { data, doSearch, loading, error } = useDebouncedSearch<SearchResponse>(
    searchAPI,
    options,
  );

  const handleSearch = (query?: string) => {
    doSearch(query);
  };

  return {
    data: deepNormalizeData(data?.results || []),
    handleSearch,
    loading,
    error,
  };
}
