import { IEventBlock } from 'app/containers/Root/Notifications/types';
import { APIResponseNotificationSettings } from 'api/notifications/getNotificationSettingsAPI/index';

export const eventsMock: IEventBlock[] = [
  {
    id: '1',
    type: 'risk',
    label: 'Risk',
    fields_list: [
      {
        id: '12332132',
        type: 'group',
        fields_list: [
          {
            id: '12',
            type: 'multi_tag',
            label: 'Third party impact was changed to critical',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
            value: ['in_app'],
          },
          {
            id: '122',
            type: 'multi_tag',
            label:
              'Insight indicator is Non-compliant and affected third parties are greater than 2',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
            value: ['in_app', 'email'],
          },
          {
            id: '123',
            type: 'multi_tag',
            label: 'Insight tag is increase risk',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
            value: ['in_app'],
          },
        ],
      },
      {
        id: '124',
        type: 'separator',
      },
      {
        id: '125',
        type: 'checkbox_with_select',
        label: 'Digest email',
        options: [
          { label: 'Weekly', value: 'weekly' },
          { label: 'Daily', value: 'daily' },
        ],
      },
      {
        id: '126',
        type: 'checkbox',
        label: 'Monthly based emails showing trends',
        value: true,
      },
    ],
  },
  {
    id: '2',
    type: 'operational',
    label: 'Operational',
    fields_list: [
      {
        id: '2233213',
        type: 'group',
        fields_list: [
          {
            id: '22',
            type: 'multi_tag',
            label: 'Task assigned was changed to me',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
            value: ['in_app'],
          },
          {
            id: '222',
            type: 'multi_tag',
            label: 'Task status changed from Blocked, and assignee is me',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
          },
          {
            id: '223',
            type: 'multi_tag',
            label: 'Task is unassigned, and risk owner is me',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
          },
        ],
      },
      {
        id: '124',
        type: 'separator',
      },
      {
        id: '125',
        type: 'checkbox_with_select',
        label: 'Digest email',
        options: [
          { label: 'Weekly', value: 'weekly' },
          { label: 'Daily', value: 'daily' },
        ],
        value: 'weekly',
      },
    ],
  },
  {
    id: '3',
    type: 'reminder',
    label: 'Reminders',
    fields_list: [
      {
        id: '22332',
        type: 'group',
        fields_list: [
          {
            id: '32',
            type: 'multi_tag',
            label:
              'Relationship manager is me and due date is approcahing by 10 days',
            options: [
              { label: 'In-app', value: 'in_app' },
              { label: 'Email', value: 'email' },
            ],
          },
        ],
      },
    ],
  },
];

export const mock: APIResponseNotificationSettings = {
  events_data: eventsMock,
  selected_parties: [],
};
