import { APIResponseBootstrapEnv, getBootstrapEnvApi } from 'api/bootstrap';
import { actions } from 'app/containers/Root/slice';
import { call, put } from 'redux-saga/effects';

export function* getBootstrapEnv() {
  try {
    const bootstrap_env: APIResponseBootstrapEnv = yield call(
      getBootstrapEnvApi,
      {},
    );

    yield put(actions.getBootstrapEnvOk(bootstrap_env));
  } catch (e: any) {
    yield put(
      actions.onError({ type: 'crash', transactionId: e.transactionId }),
    );
  }
}
