/**
 *
 * LayoutDesktop
 *
 */

import { styled } from '@mui/material/styles';
import backgroundUrl from './background.png';

interface LayoutDesktopProps {
  fullsScreenMode?: boolean;
}

export const LayoutDesktop = styled('div', {
  shouldForwardProp: prop => prop !== 'fullsScreenMode',
})<LayoutDesktopProps>`
  padding: ${({ theme }) =>
    `${theme.layout.header.height} 0 ${theme.layout.paddingBottom} ${theme.layout.aside.width}`};
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: ${() => `url(${backgroundUrl})`};
  background-position: bottom left;
  background-repeat: no-repeat;
  min-height: 100vh;
  ${({ fullsScreenMode }) => fullsScreenMode && 'padding: 0;'}
`;

export const DesktopPageBox = styled('div')`
  padding: ${({ theme }) => theme.layout.desktopPageBoxPadding};
`;

interface PageContainerProps {
  pageWithSidePadding?: boolean;
}

export const PageContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'pageWithSidePadding',
})<PageContainerProps>`
  max-width: 1530px;
  margin: 0 auto;
  position: relative;
  ${({ pageWithSidePadding }) => pageWithSidePadding && 'padding: 0 1.5rem;'};

  @media all and (max-width: 1400px) {
    max-width: 1300px;
  }
`;
