import { PopupViewBlockTitle } from '../types';
import React from 'react';
import { Box, Link, Typography } from '@mui/material';

export const BlockTitle = ({ text, link }: PopupViewBlockTitle) => (
  <Box m={'1rem 0'}>
    <Typography gutterBottom={Boolean(link)} variant="body2">
      <strong>{text}</strong>
    </Typography>
    {Boolean(link) && (
      <Link href={link?.url} variant="body2" target="_blank">
        {link?.label}
      </Link>
    )}
  </Box>
);
