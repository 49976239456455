import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

interface ProgressProps {
  type: 'circular' | 'linear';
  size?: number | string;
}

export const Progress: FC<ProgressProps> = ({ type, size = 21 }) => {
  switch (type) {
    case 'circular':
      return <CircularProgress size={size} />;
    case 'linear':
      return <LinearProgress />;
    default:
      return null;
  }
};
