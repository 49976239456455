import { createContext, useContext } from 'react';
import { IUsePartyDocuments } from 'app/containers/RootThirdParty/PageThirdParty/ThirdPartyDocuments/usePartyDocuments';

export interface IPartyDocumentsContext extends IUsePartyDocuments {}

export const PartyDocumentsContext = createContext<
  IPartyDocumentsContext | undefined
>(undefined);

export const usePartyDocumentsContext = () => {
  const context = useContext(PartyDocumentsContext);
  if (context === undefined) {
    throw new Error(
      'usePartyDocumentsContext must be used within a PartyDocumentsContext',
    );
  }
  return context;
};
