import { Grid } from '@mui/material';
import { AntSwitch, StyledSwitchWrapper } from './styled';
import { InputSwitchProps } from './index';
import { Typography } from 'app/components/Typography';

export const StyleDefault = ({
  on,
  off,
  value,
  name,
  handleChange,
  test_id,
}: InputSwitchProps) => {
  const handleSwitch = () => {
    handleChange(!value);
  };

  return (
    <StyledSwitchWrapper data-test={test_id}>
      <Grid component="label" container alignItems="center" spacing={1}>
        {off && (
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              <i>{off}</i>
            </Typography>
          </Grid>
        )}
        <Grid item>
          <AntSwitch checked={value} onChange={handleSwitch} name={name} />
        </Grid>
        {on && (
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              <i>{on}</i>
            </Typography>
          </Grid>
        )}
      </Grid>
    </StyledSwitchWrapper>
  );
};
