import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useGetAbstractData } from 'app/hooks/useGetAbstractData';
import { useHistory } from 'react-router-dom';
import { usePageParams } from '../../../../../hooks/usePageParams';
import { Filter } from '../../../../Filter';
import { TableTop } from '../../../../../components/TableTop';
import { AbstractTable } from '../../../../AbstractTable';
import { LoadingBlock } from '../../../../../components/LoadingBlock';

export const PartiesTable = () => {
  const history = useHistory();

  const {
    pageParams,
    onChangeOrder,
    onChangeLimit,
    onChangePage,
    onChangeFilter,
    onSelectRow,
    selectAllRows,
    onChangeShowSelected,
  } = usePageParams();

  const { selected_ids, show_selected, ...restPageParams } = pageParams;
  const { page, limit, filter } = restPageParams;

  useEffect(() => {
    sessionStorage.setItem(
      'notification_center_parties_search',
      history.location.search,
    );
  }, [history.location.search]);

  const { data, loading } = useGetAbstractData({
    id: 'notification_center_parties_list',
    params: show_selected ? pageParams : restPageParams,
  });

  if (!data) {
    return <LoadingBlock />;
  }

  const { count, page_size, table, page_sizes, filter_data } = data;

  const onClickRow = (id: string) => history.push(`/third-party/${id}`);

  return (
    <>
      {filter_data && onChangeFilter && (
        <Box m=".5rem 0">
          <Filter
            filterData={filter_data}
            onChangeFilter={onChangeFilter}
            currentFilter={filter}
          />
        </Box>
      )}
      <Box m="0.2rem 0">
        <TableTop
          limitOptions={page_sizes}
          limit={limit || page_size || 0}
          page={page || 1}
          all={count || 0}
          onChangePage={onChangePage}
          onChangeLimit={onChangeLimit}
          disable={loading}
          onChangeShowSelected={onChangeShowSelected}
        />
      </Box>
      {table && (
        <AbstractTable
          loading={loading}
          table={table}
          onChangeOrder={onChangeOrder}
          onClickRow={onClickRow}
          selectable
          selected_ids={selected_ids}
          onSelectRow={onSelectRow}
          selectAllRows={selectAllRows}
          maxHeight="calc(100vh - 14rem)"
        />
      )}
    </>
  );
};
