import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  AbstractDataType,
  ContainerState,
  IGetAbstractData,
  IGetAbstractDataAbort,
  IGetAbstractDataError,
  IGetAbstractDataOk,
  IResetAbstractData,
} from './types';
import { abstractDataIdsArray } from './typesAbstractDataIdsArray';

// The initial state of the useGetAbstractData container
export const initialState: ContainerState = abstractDataIdsArray.reduce(
  (acc, key) => ({ ...acc, [key]: { loading: false } }),
  {},
) as ContainerState;

const useGetAbstractDataSlice = createSlice({
  name: 'useGetAbstractData',
  initialState,
  reducers: {
    resetAbstractData<T extends AbstractDataType>(
      state,
      action: PayloadAction<IResetAbstractData<T>>,
    ) {
      const { id } = action.payload;

      state[id].loading = false;
      state[id].error = false;
      state[id].data = undefined;
    },
    getAbstractData<T extends AbstractDataType>(
      state,
      action: PayloadAction<IGetAbstractData<T>>,
    ) {
      const { id } = action.payload;

      state[id].loading = true;
      state[id].error = false;
    },
    getAbstractDataAbort<T extends AbstractDataType>(
      state,
      action: PayloadAction<IGetAbstractDataAbort<T>>,
    ) {
      const { id } = action.payload;

      state[id].loading = false;
    },
    getAbstractDataOk<T extends AbstractDataType>(
      state,
      action: PayloadAction<IGetAbstractDataOk<T>>,
    ) {
      const { id, data, currentParams } = action.payload;

      state[id].loading = false;
      state[id].data = data;
      state[id].currentParams = currentParams;
    },
    getAbstractDataError<T extends AbstractDataType>(
      state,
      action: PayloadAction<IGetAbstractDataError<T>>,
    ) {
      const { id, error } = action.payload;

      state[id].loading = false;
      state[id].error = error;
    },
  },
});

export const { actions, reducer, name: sliceKey } = useGetAbstractDataSlice;
