import { faker } from '@faker-js/faker';
import { APIResponseParties } from 'api/thirdParties/getPartiesAPI';
import { buildMockTable, getRandomItemFromArray } from 'api/utils';
import { IHeaderItem, ITableRow } from 'app/containers/AbstractTable/types';
import { buildMockFilterData } from 'api/mockFilterData';
import { DocumentState } from 'app/containers/RootThirdParty/PageThirdParty/types';

const rowsCount = 30;

const headerItems: IHeaderItem[] = [
  {
    id: 'Relationship',
    label: 'Relationship',
    orderable: true,
  },
  {
    id: 'Name',
    label: 'Name',
    orderable: false,
  },
  {
    id: 'Upload date',
    label: 'Upload date',
    orderable: true,
  },
  {
    id: 'Source',
    label: 'Source',
    orderable: true,
  },
  {
    id: 'Status',
    label: 'Status',
    orderable: false,
  },
  {
    id: 'empty_1',
    orderable: false,
    mods: ['small'],
  },
];

const { datatype, company, random, system } = faker;

const buildRows = (count: number): ITableRow => {
  const docStatus = getRandomItemFromArray([
    'Uploaded',
    'Error',
    'Analyzing',
    'Analyzed',
    'PasswordRequired',
    'TargetEntityMismatch',
    'TargetEntityMismatchKeep',
    'TargetEntityMismatchOverrideAnalyzed',
  ]) as DocumentState;

  const purged = datatype.boolean();

  return {
    id: `${count}_doc_row`,
    cells: [
      {
        type: 'text',
        data: {
          text: company.name(),
        },
      },
      {
        type: 'text',
        data: {
          text: system.fileName(),
        },
      },
      {
        type: 'date',
        data: {
          date: datatype.datetime().toString(),
        },
      },
      {
        type: 'text',
        data: {
          text: random.word(),
        },
      },
      {
        type: 'document_status',
        data: {
          document_name: system.fileName(),
          document_id: datatype.uuid(),
          status: docStatus,
          document_business_name: random.words(),
          tp_name: random.words(),
          scope_name: random.words(),
          override_reason: random.words(),
          validation_error: random.words(),
          document_business_name_count: Number(random.numeric(1)),
          purged,
          possible_relations: [
            {
              id: '1',
              label: 'Override',
              description: 'override te',
            },
            {
              id: '2',
              label: 'Keep',
              description: 'keep te',
            },
          ],
        },
      },
      {
        type: 'download_document',
        data: {
          document_id: 'document_id',
          document_name: 'document_name.txt',
          purged,
        },
      },
    ],
    order_value: 'AA',
    type: 'row',
  };
};

const rows = (_tab: string) =>
  Array.from(Array(Math.floor(Math.random() * rowsCount) + 1).keys()).map(
    buildRows,
  );

export const mock: (tab: string) => APIResponseParties = tab => ({
  table: buildMockTable(headerItems, rows(tab)),
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 125,
  page: 1,
  filter_data: buildMockFilterData(),
  legend: {
    items: [],
  },
});
