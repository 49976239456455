import { IInsightGroupCountWithIndicatorCell } from 'app/containers/AbstractTable/types';
import { CircleCounter } from 'app/components/CircleCounter';
import { useTheme } from '@mui/material';
import { getInsightColorByType } from 'app/containers/RootInsights/utils';
import { Box } from 'app/components/Box';
import { Typography } from 'app/components/Typography';

export const CellInsightGroupCountWithIndicator = ({
  data,
}: IInsightGroupCountWithIndicatorCell) => {
  const theme = useTheme();
  const { text, counters } = data;

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" minWidth="5rem">
        {text}
      </Typography>
      {counters.length > 0 && (
        <>
          <Typography
            variant="body2"
            color="textSecondary"
            m="-.2rem .5rem 0 0"
          >
            {'|'}
          </Typography>
          {counters.map(counter => (
            <Typography key={counter.type} variant="body2" m="0 .2rem">
              <CircleCounter
                color={getInsightColorByType(counter.type, theme)}
                count={+counter.count}
              />
            </Typography>
          ))}
        </>
      )}
    </Box>
  );
};
