/**
 *
 * Asynchronously loads the component for RootRiskBoard
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RootRiskBoard = lazyLoad(
  () => import('./index'),
  module => module.RootRiskBoard,
);
