import React from 'react';
import { AddButton, AsideBox } from './styled';
import { useRoot } from 'app/containers/Root/useRoot';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AsidePlusIcon } from 'app/components/Icons/svg/AsidePlusIcon';
import { AsideItem } from './Item';
import { Tooltip } from 'app/components/Tooltip';
import { usePermissions } from 'app/containers/Root/usePermissions';

export function Aside() {
  const { t } = useTranslation();

  const { aside } = useRoot();

  const { createParty } = usePermissions();

  return (
    <AsideBox>
      <nav>
        {createParty && (
          <Tooltip
            title={t(translations.aside.addParty) || ''}
            aria-label={'Add'}
            placement="right"
          >
            <AddButton
              color="primary"
              aria-label="add"
              size="small"
              component={Link as any}
              to="/third-party/new"
              data-test="nav_add_tp"
            >
              <AsidePlusIcon />
            </AddButton>
          </Tooltip>
        )}
        {aside?.map(i => (
          <AsideItem key={i.id} {...i} />
        ))}
      </nav>
    </AsideBox>
  );
}
