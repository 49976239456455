import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockText } from 'app/containers/RootInsights/types';

const { datatype, lorem } = faker;

export const insightsItemDataBlockTextMock: () => IInsightsItemDataBlockText =
  () => ({
    id: 'InsightsItemDataBlockText',
    type: 'text',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Text',
    text: lorem.text(),
  });
