/**
 *
 * CellShowMore
 *
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { translations } from '../../../../../locales/translations';

export const CellShowMore = () => {
  const { t } = useTranslation();

  return (
    <Link variant="body2">
      {t(translations.components.abstractTable.showMore)}
    </Link>
  );
};
