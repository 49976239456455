import { InsightIndicatorType } from './types';
import { ThemeOptions } from '@mui/material';

export const getInsightColorByType = (
  type: InsightIndicatorType,
  theme: ThemeOptions,
): string => {
  if (!theme.layout) {
    return '#827F81';
  }

  return theme.layout.insightIndicatorColor[type];
};
