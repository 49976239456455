import { Box } from 'app/components/Box';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { Link } from 'app/components/Link';
import { Divider } from 'app/components/Divider';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useCurrentUserNav } from './Context';
import { usePermissions } from 'app/containers/Root/usePermissions';

export function AdminSection() {
  const { t } = useTranslation();

  const { closeMenu } = useCurrentUserNav();

  const { isAdmin } = usePermissions();

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <MenuItem
        component={RouterLink}
        to="/admin/logo-fetch"
        onClick={closeMenu}
      >
        <Link underline="none" variant="body2" component="span">
          {t(translations.mainNav.logoFetch)}
        </Link>
      </MenuItem>
      <Box m=".5rem 0">
        <Divider variant="middle" />
      </Box>
    </>
  );
}
