import React, { FC } from 'react';
import { iconsDictionary, IconsMapType } from 'app/components/Icon/Icons';

export type IconProps<T> = {
  name: keyof T;
  width?: string;
  height?: string;
  m?: string;
  color?: string;
};

export const Icon: FC<IconProps<IconsMapType>> = ({
  name,
  width = '1rem',
  height = '1rem',
  m,
  color,
}) => {
  const Component = iconsDictionary[name];

  return (
    <Component
      style={{
        width,
        height,
        margin: m,
        color,
        minHeight: height,
        minWidth: width,
      }}
    />
  );
};
