import { PaletteMode } from '@mui/material';

export const getPalette = (_mode: PaletteMode) => {
  return {
    primary: {
      main: '#001591',
      light: 'rgba(61, 79, 228, 0.2)',
      dark: '#3D4FE4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#EBF0EE',
      light: 'rgba(61, 79, 228, 0.2)',
      dark: 'rgba(234,236,245, 0.8)',
      contrastText: '#1A1719',
    },
    divider: '#ddd',
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#1A1719',
      secondary: '#827F81',
    },
  };
};
