import React, { FC } from 'react';
import MUIButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { MuiProps, WithTestID } from 'types/common';
import { Progress } from 'app/components/Progress';

export type ButtonProps = MuiButtonProps &
  WithTestID &
  MuiProps & {
    loading?: boolean;
    underline?: boolean;
  };

export const StyledButton = styled(MUIButton, {
  shouldForwardProp: prop => prop !== 'loading' && prop !== 'underline',
})<ButtonProps>`
  position: relative;
  text-transform: none;

  &.MuiButton-sizeSmall {
    min-width: 95px;
    font-size: 0.85rem;
  }

  &.MuiButton-sizeMedium {
    min-width: 105px;
    font-size: 1rem;
  }

  &.MuiButton-sizeLarge {
    min-width: 150px;
    font-size: 1rem;
  }

  &.MuiButton-contained {
    box-shadow: none;
  }

  &.MuiButton-containedPrimary {
    &:hover {
      box-shadow: 0 3px 6px ${({ theme }) => theme.palette.primary.light};
    }
  }

  &.MuiButton-containedSecondary {
    &:hover {
      box-shadow: 0 3px 6px ${({ theme }) => theme.palette.secondary.light};
    }
  }

  ${({ underline }) => underline && 'text-decoration: underline;'}

  &:hover {
    ${({ underline }) => underline && 'text-decoration: underline;'}
  }
`;

export const StyledLoadingOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button: FC<ButtonProps> = props => {
  const { loading, children, test_id } = props;
  if (loading) {
    return (
      <StyledButton {...props} disabled>
        {children}
        <StyledLoadingOverlay>
          <Progress type="circular" />
        </StyledLoadingOverlay>
      </StyledButton>
    );
  }
  return <StyledButton {...props} data-test={test_id} />;
};
