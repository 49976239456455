/**
 *
 * AggregatorViewButton
 *
 */
import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/Buttons/Button';
import { translations } from 'locales/translations';
import { AggregatorSmallIcon } from '../../Icons/svg/AggregatorSmallIcon';
import { styled } from '@mui/material/styles';
import { Typography } from 'app/components/Typography';
import { usePermissions } from 'app/containers/Root/usePermissions';

interface AggregatorViewButtonProps {
  to: string;
  noMargin?: boolean;
  label?: string;
  type: string;
}

export const StyledAggregatorViewButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'noMargin',
})<AggregatorViewButtonProps>`
  background: ${({ theme }) => theme.layout.aggregatorButton.background};
  margin: ${({ noMargin }) => (noMargin ? '0' : '0 0 0 1rem')};
`;

export function AggregatorViewButton({
  to,
  noMargin,
  label,
}: AggregatorViewButtonProps) {
  const { t } = useTranslation();

  const { aggregatorView } = usePermissions();

  const handleClick = () => {
    ReactGA.event({
      category: 'Risk Aggregator',
      action: 'Entity Link Launch',
      label,
    });
  };

  if (!aggregatorView) {
    return null;
  }

  return (
    <StyledAggregatorViewButton
      variant="contained"
      color="secondary"
      type="button"
      size="small"
      component={Link}
      to={`/aggregation-risk/${to}`}
      onClick={handleClick}
      noMargin={noMargin}
      test_id={`button_aggregation_view`}
    >
      <AggregatorSmallIcon />
      <Typography
        component="span"
        style={{ margin: '0 0 0 .5rem' }}
        variant="body2"
        noWrap
      >
        {t(translations.components.aggregatorViewButton.title)}
      </Typography>
    </StyledAggregatorViewButton>
  );
}
