import React from 'react';

export const AggregatorSmallIcon = () => (
  <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6797 4.5H13v-4H9v3.26631L8.23512 4.5H4v4.25218L3.27388 9.5H0v4h4v-3.3123L4.66772 9.5h3.66591L9 10.1871V13.5h4v-4H9.72668L9 8.75072V5.15199L9.6797 4.5zM12 1.5h-2v2h2v-2zm-9 9.0001V12.5H1v-2l2 .0001zM7.91581 8.5L8 8.41834V5.5H5v3h2.91581zM10 12.5v-1.9999l2-.0001v2h-2z"
      fill="#827F81"
    />
  </svg>
);
