import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import {
  IAbstractFilterData,
  IAbstractLegend,
  IAbstractPageData,
  IAbstractTable,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { mock } from 'api/thirdParties/getPartiesAPI/mock';

export interface APIPayloadParties extends IPageParams {}

export interface APIResponseParties
  extends IAbstractTable,
    IAbstractFilterData,
    IAbstractPageData,
    IAbstractLegend {}

export const getPartiesAPI: RequestApi<
  APIPayloadParties,
  APIResponseParties
> = async (props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/third_parties/?${stringifyParams(props)}`, { signal });
};
