/**
 *
 * Header
 *
 */

import { HeaderBox, LogoBox, Wrapper } from './styled';
import { MainLogo } from '../../../components/Icons/svg/MainLogo';
import { Box } from '@mui/material';
import { PageContainer } from '../../../components/LayoutDesktop';
import { CurrentUserNav } from './CurrentUserNav';
import { SearchBar } from './SearchBar';
import { useRoot } from '../useRoot';
import { NODE_ENV } from 'utils/constants';

export const Header = () => {
  const { current_user } = useRoot();

  const isRed =
    NODE_ENV === 'production' && current_user?.email?.endsWith('@mirato.com');

  return (
    <HeaderBox isRed={isRed}>
      <LogoBox href="/">
        <MainLogo />
      </LogoBox>
      <Box flexGrow="1" p="0 1.5rem">
        <PageContainer>
          <Wrapper>
            <SearchBar />
            <Box m="0 0 0 .5rem">
              <CurrentUserNav />
            </Box>
          </Wrapper>
        </PageContainer>
      </Box>
    </HeaderBox>
  );
};
