import React from 'react';
import { useTranslation } from 'react-i18next';
import { AsideItemBox, AsideItemLink } from './styled';
import { IconAside } from './IconAside';
import { IAsideItem } from 'types/user';
import { Tooltip } from '@mui/material';
import { translations } from 'locales/translations';

export function AsideItem({ id, route, disabled }: IAsideItem) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(translations.aside[id]) || ''}
      aria-label={t(translations.aside[id]) || ''}
      placement="right"
    >
      <AsideItemLink data-test={`nav_${id}`} to={route} disabled={disabled}>
        <AsideItemBox>
          <IconAside icon={id} />
        </AsideItemBox>
      </AsideItemLink>
    </Tooltip>
  );
}
