/**
 *
 * CurrentUserNav
 *
 */

import React from 'react';
import { CurrentUserNavContext } from './Context';
import { MainView } from './MainView';
import { useCurrentUserNavController } from './useCurrentUserNav';
import { useRoot } from 'app/containers/Root/useRoot';

export function CurrentUserNav() {
  const { current_user, aside } = useRoot();

  const controller = useCurrentUserNavController();

  if (!current_user || !aside) {
    return null;
  }

  return (
    <CurrentUserNavContext.Provider value={{ ...controller }}>
      <MainView />
    </CurrentUserNavContext.Provider>
  );
}
