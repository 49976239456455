/**
 *
 * CellTextList
 *
 */

import React from 'react';
import { ITextListCell } from '../../types';
import { TextWithMods } from '../../../../components/TextWithMods';
import { Typography } from '@mui/material';

export const CellTextList = ({ data }: ITextListCell) => {
  const { items, mods } = data;

  return (
    <>
      {items.map(i => (
        <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
          <TextWithMods mods={mods || []}>{i}</TextWithMods>
        </Typography>
      ))}
    </>
  );
};
