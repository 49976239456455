/**
 *
 * SearchBarContext
 *
 */

import React, { createContext } from 'react';
import { IUseSearchBarController } from './types';

export const SearchBarContext = createContext<
  IUseSearchBarController | undefined
>(undefined);

export const useSearchBar = () => {
  const context = React.useContext(SearchBarContext);
  if (context === undefined) {
    throw new Error('useSearchBar must be used within a SearchBarContext');
  }
  return context;
};
