import queryString from 'query-string';
import { IPageParams } from 'app/hooks/usePageParams/types';
import { abstractDataIdsArray } from 'app/hooks/useGetAbstractData/typesAbstractDataIdsArray';
import { IPendingApiReqState } from 'app/hooks/useGetAbstractData/types';

export const stringifyParams = (parsedSearch?: IPageParams): string => {
  if (!parsedSearch) {
    return '';
  }
  const {
    filter,
    order,
    page,
    trend,
    limit,
    selected_ids,
    timecap,
    list_type,
  } = parsedSearch;

  const marshalFilters = () => {
    if (filter === null) {
      return 'null';
    }
    if (filter) {
      return JSON.stringify({
        ...filter,
        items: filter.items.map(i => ({ ...i, options: undefined })),
      });
    }
    return undefined;
  };

  const marshalSelectedIds = () => {
    if (selected_ids) {
      return JSON.stringify(selected_ids);
    }
    return undefined;
  };

  return queryString.stringify({
    page,
    trend,
    page_size: limit,
    order: order?.column,
    direction: order?.direction,
    filter: marshalFilters(),
    selected_ids: marshalSelectedIds(),
    timecap,
    list_type,
  });
};

export const pendingApiReqState: IPendingApiReqState =
  abstractDataIdsArray.reduce<IPendingApiReqState>(
    (acc, item) => ({ ...acc, [item]: [] }),
    {},
  );
