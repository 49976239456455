/**
 *
 * Alerts
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectRoot } from '../selectors';
import { Alert } from './Alert';

export function Alerts() {
  const { alerts } = useSelector(selectRoot);

  return (
    <>
      {alerts.map(alert => (
        <Alert key={alert.id} alert={alert} />
      ))}
    </>
  );
}
