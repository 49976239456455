import { faker } from '@faker-js/faker';
import { APIResLogoFetch } from 'api/admin/getLogoFetchAPI/index';
import {
  IPartyDone,
  IPartyToAssign,
} from 'app/containers/RootAdmin/LogoFetch/types';

const rowsCount = 10;

const { internet, company, image, date } = faker;

const buildPartyDone = (count: number): IPartyDone => {
  return {
    party_id: `${count}_party`,
    party_name: company.name(),
    base64_logo: image.image(),
    date_added: date.recent().toDateString(),
    user_added: internet.email(),
  };
};

const buildPartyToAssign = (count: number): IPartyToAssign => {
  return {
    party_id: `${count}_party`,
    party_name: company.name(),
    date_added: date.recent().toDateString(),
    user_added: internet.email(),
  };
};

const parties_done = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildPartyDone);

const parties_to_assign = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildPartyToAssign);

export const mock: APIResLogoFetch = {
  parties_done,
  parties_to_assign,
};
