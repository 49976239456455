import { useFilter } from './FilterContext';
import { EditWindowPopover, StyledEditWindow } from './styled';
import { Popover } from '@mui/material';
import { EditFilterActions } from './EditFilterActions';
import { EditFilter } from './EditFilter';

export function EditFilterWindow() {
  const {
    editFilterAnchor,
    isEditFilterOpen,
    closeEditFilter,
    isSelectOpen,
    doApplyFilter,
  } = useFilter();

  const filterBoxId = isEditFilterOpen ? 'filter-popover' : undefined;

  const handleClose = () => !isSelectOpen && closeEditFilter();

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    doApplyFilter();
  };

  if (!isEditFilterOpen) {
    return null;
  }

  return (
    <Popover
      id={filterBoxId}
      open={isEditFilterOpen}
      anchorEl={editFilterAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      style={{ width: '100%' }}
    >
      <EditWindowPopover>
        <StyledEditWindow as="form" onSubmit={onSubmit}>
          <EditFilter />
          <EditFilterActions />
        </StyledEditWindow>
      </EditWindowPopover>
    </Popover>
  );
}
