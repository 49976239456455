import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase, Fab } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const AsideBox = styled('aside')`
  background: ${({ theme }) => theme.layout.aside.background};
  width: ${({ theme }) => theme.layout.aside.width};
  top: ${({ theme }) => theme.layout.header.height};
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 3rem 0 0 0;
  z-index: 99;
  text-align: center;
`;

export const AsideItemBox = styled(ButtonBase)`
  width: ${({ theme }) => theme.layout.aside.width};
  height: ${({ theme }) => theme.layout.aside.width};
  margin: 0.5rem 0;
`;

interface AddButtonProps {
  component: React.ReactNode;
  to: string;
}

export const AddButton = styled(Fab)<AddButtonProps>`
  margin: 0.5rem 0;
  opacity: 0.6;
  background: ${({ theme }) => theme.layout.aside.addButtonBackground};
  width: 2rem;
  height: 2rem;
  min-height: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;

  svg {
    width: 0.85rem;
    height: 0.85rem;
  }

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.layout.aside.addButtonBackgroundHover};
  }

  box-shadow: none;
`;

interface AsideItemLinkProps {
  disabled?: boolean;
}

export const AsideItemLink = styled(NavLink)<AsideItemLinkProps>`
  display: inline-block;
  transition: 0.3s;

  svg {
    width: 100%;
    path {
      fill: ${({ theme }) => theme.layout.asideItem.defaultColor};
      transition: 0.3s;
    }
    circle.colored {
      stroke: ${({ theme }) => theme.layout.asideItem.defaultColor};
    }
    .colored {
      fill: transparent;
      stroke: transparent;
    }
    .coloredBox {
      fill: transparent;
      stroke: ${({ theme }) => theme.layout.asideItem.defaultColor};
    }
  }
  &.active {
    background: ${({ theme }) => theme.layout.asideItem.activeBackground};

    path {
      fill: ${({ theme }) => theme.layout.asideItem.activeColor};
    }

    circle {
      stroke: ${({ theme }) => theme.layout.asideItem.activeColor};
    }

    .colored {
      fill: ${({ theme }) => theme.layout.asideItem.secondaryActiveColor};
      stroke: transparent;
    }

    .coloredBox {
      stroke: transparent;
      fill: ${({ theme }) => theme.layout.asideItem.secondaryActiveColor};
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: .6;
    pointer-events: none;
  `}
`;
