import { forwardRef, MouseEvent, useEffect, useState } from 'react';
import { ShowAllButton } from './styled';
import { useFilter } from './FilterContext';
import { FilterMenuItem } from './FilterMenuItem';
import { deepCompare } from 'utils/deepCompare';
import { useTranslation } from 'react-i18next';
import { Box, List, MenuItem, Popover } from '@mui/material';
import { translations } from 'locales/translations';
import { ArrowDropDown } from '@mui/icons-material';
import { Typography } from 'app/components/Typography';

export const FilterMenu = forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation();

  const { onChangeFilter, filterData, currentFilter } = useFilter();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [currentLabel, setLabel] = useState<string>(
    t(translations.components.filter.showAll),
  );

  useEffect(() => {
    const { filters } = filterData;

    if (!currentFilter) {
      setLabel(t(translations.components.filter.showAll));
      return undefined;
    }

    if (currentFilter) {
      const currentFilterItem = filters?.find(i =>
        deepCompare(i.definition, currentFilter),
      );
      setLabel(
        currentFilterItem
          ? currentFilterItem.label
          : t(translations.components.filter.custom),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, filterData]);

  const handleOpenDropDown = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowAll = () => {
    onChangeFilter(null);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const menuId = open ? 'menu-popover' : undefined;

  return (
    <Box {...({ ref } as any)}>
      <ShowAllButton onClick={handleOpenDropDown} data-test="button_show_all">
        <Typography variant="body2" className="current-filter">
          <Typography color="textSecondary" variant="body2" component="span">
            {t(translations.components.filter.view)}:
          </Typography>{' '}
          {currentLabel}
        </Typography>
        <ArrowDropDown />
      </ShowAllButton>
      <Popover
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box style={{ minWidth: 200 }}>
          <List>
            <MenuItem
              onClick={handleShowAll}
              selected={!Boolean(currentFilter)}
            >
              <Typography variant="body2" color="inherit">
                <strong>{t(translations.components.filter.showAll)}</strong>
              </Typography>
            </MenuItem>
            {filterData.filters?.map(item => (
              <FilterMenuItem
                key={item.id}
                item={item}
                handleClose={handleClose}
                data-test={`list_item_${item.id}`}
              />
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
});
