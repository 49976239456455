import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockTable } from 'app/containers/RootInsights/types';
import { buildMockTable } from 'api/utils';
import { IHeaderItem, ITableRow } from 'app/containers/AbstractTable/types';

const { datatype, random, lorem } = faker;

const rowsCount = 10;

const headerItems: IHeaderItem[] = [
  {
    id: datatype.uuid(),
    label: random.word(),
    orderable: true,
  },
  {
    id: datatype.uuid(),
    label: random.word(),
    sublabel: '',
  },
  {
    id: datatype.uuid(),
    label: random.word(),
    orderable: true,
  },
];

const buildRows = (count: number): ITableRow => {
  return {
    id: `${count}_tp_row`,
    cells: [
      {
        type: 'text',
        data: {
          text: lorem.words(5),
        },
      },
      {
        type: 'text',
        data: {
          text: lorem.text(),
        },
      },
      {
        type: 'text',
        data: {
          text: lorem.text(),
        },
      },
    ],
    order_value: 'AA',
    type: 'row',
  };
};

const rows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildRows);

const table_data = buildMockTable(headerItems, rows);

export const insightsItemDataBlockTableMock: () => IInsightsItemDataBlockTable =
  () => ({
    id: 'IInsightsItemDataBlockTable',
    type: 'table',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Table',
    table_data,
  });
