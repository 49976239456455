import { ICurrentUser, PageName } from 'types/user';

export function setRoute(id: PageName) {
  switch (id) {
    case 'aggregation_risk':
      return '/aggregation-risk';
    case 'risk_board':
      return '/risk-board';
    case 'third_party':
      return '/third-party';
    default:
      throw new Error('Wrong page id');
  }
}

export function getUserAsideConfig({ pages_list }: ICurrentUser) {
  const homepage: PageName = 'risk_board';
  return (
    pages_list?.map(i => ({
      ...i,
      is_homepage: i.id === homepage,
      route: setRoute(i.id),
    })) || []
  );
}
