import React, { useState } from 'react';
import { IHeaderItem, IUseTableHeadItem } from '../types';
import { useTable } from '../TableContext';

/**
 *
 * useTableHeadItem
 *
 */

export function useTableHeadItem({
  id,
  mods,
  orderable,
  is_small,
}: IHeaderItem): IUseTableHeadItem {
  const { table, onChangeOrder, setWidth, setColspan, hideCell } = useTable();

  const { order_direction, order_column } = table;

  const isCurrentOrder = id === order_column;

  const handleOrder = () => {
    if (onChangeOrder && orderable) {
      onChangeOrder({
        column: id,
        direction: isCurrentOrder && order_direction === 1 ? -1 : 1,
      });
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isPopupOpen = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const handleExplanation = e => {
    e.preventDefault();
    e.stopPropagation();
    handlePopoverOpen(e);
  };

  const width = setWidth(mods);

  const colSpan = setColspan(mods);

  const arrowUp = isCurrentOrder && order_direction === 1;

  return {
    width,
    colSpan,
    isCurrentOrder,
    arrowUp,
    is_small: Boolean(is_small || mods?.includes('small')),
    hideCell,
    handleOrder,
    handleExplanation,
    handlePopoverClose,
    isPopupOpen,
    anchorEl,
  };
}
