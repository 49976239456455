import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import { IInsights } from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { mock } from 'api/insights/getPartInsightsAPI/mock';

export interface APIPayloadPartyInsights extends WithPartyId, IPageParams {}

export interface APIResponsePartyInsights extends IInsights {}

export const getPartyInsightsAPI: RequestApi<
  APIPayloadPartyInsights,
  APIResponsePartyInsights
> = async ({ thirdPartyId, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(
    `/third_parties/${thirdPartyId}/insights/latest?${stringifyParams(props)}`,
    { signal },
  );
};
