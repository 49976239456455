import { call, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { actions as actionsRoot } from '../slice';
import { selectEventForm, selectNotifications } from './selectors';
import {
  getAbstractForm,
  getAbstractFormState,
} from 'app/containers/Root/Notifications/Form/utils';
import { NotificationField } from 'app/containers/Root/Notifications/Form/types';
import { getNotificationSettingsAPI } from 'api/notifications/getNotificationSettingsAPI';
import { saveNotificationSettingsAPI } from 'api/notifications/saveNotificationSettingsAPI';

export function* getSettingsSaga() {
  try {
    const settings = yield call(getNotificationSettingsAPI, {});

    yield put(actions.getSettingsOk(settings));

    const fields = settings.events_data
      .map(block => block.fields_list)
      .flat()
      .filter(fields => fields) as NotificationField[];

    yield put(actions.initEventFormState(getAbstractFormState(fields)));
  } catch (e: any) {
    yield put(
      actions.getSettingsError({
        type: 'block_crash',
        transactionId: e.transactionId,
      }),
    );
  }
}

export function* saveSettingsSaga() {
  try {
    const { settings } = yield select(selectNotifications);

    const { selected_parties } = settings;

    const form = yield select(selectEventForm);

    const events_data = getAbstractForm(form);

    yield call(saveNotificationSettingsAPI, { selected_parties, events_data });

    yield put(actions.saveSettingsOk());
  } catch (e: any) {
    yield put(actions.saveSettingsError());
    yield put(
      actionsRoot.addDefaultErrorAlert({ transactionId: e.transactionId }),
    );
  }
}

export function* notificationsSaga() {
  yield takeLatest(actions.getSettings.type, getSettingsSaga);
  yield takeLatest(actions.saveSettings.type, saveSettingsSaga);
}
