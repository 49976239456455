/**
 *
 * Select_old
 *
 */
import React from 'react';
import { styled } from '@mui/material/styles';
import { IFilteringValueOption } from 'app/containers/Filter/types';
import { Box, MenuItem, TextFieldProps, Typography } from '@mui/material';
import { TextField } from '../TextField';

export interface SelectOptionType {
  label: string;
  value: any;
  options?: IFilteringValueOption[];
  icon?: string;
}

export type ISelectProps = TextFieldProps & {
  IconComponent?: React.ComponentType<{ icon: string }> | undefined;
  options?: SelectOptionType[];
  disablePlaceholder?: boolean;
};

interface StyledTextProps {
  component?: string;
}

export const StyledText = styled(Typography)<StyledTextProps>`
  line-height: 1;
`;

// todo replace with new select
export function Select({
  placeholder,
  disablePlaceholder,
  IconComponent,
  options,
  ...rest
}: ISelectProps) {
  return (
    <TextField select fullWidth placeholder={placeholder} {...rest}>
      {placeholder && (
        <MenuItem value=" " disabled={disablePlaceholder}>
          <StyledText component="span" color="textSecondary">
            {placeholder}
          </StyledText>
        </MenuItem>
      )}
      {options?.map(i => (
        <MenuItem value={i.value} key={i.value}>
          <Box display="flex" alignItems="center" gap=".5rem">
            {!!IconComponent && i.icon && <IconComponent icon={i.icon} />}
            {i.label}
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
}
