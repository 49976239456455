/**
 *
 * CurrentUserNavContext
 *
 */

import React, { createContext } from 'react';
import { IUseCurrentUserNavController } from './types';

export const CurrentUserNavContext = createContext<
  IUseCurrentUserNavController | undefined
>(undefined);

export const useCurrentUserNav = () => {
  const context = React.useContext(CurrentUserNavContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentUserNav must be used within a CurrentUserNavContext',
    );
  }
  return context;
};
