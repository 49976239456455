import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  INotificationsSettingsResponse,
  IOnChangeEventFieldValue,
  IOnChangeSelectedParties,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { BlockCrashError } from 'types/errors';
import { NotificationFieldsStateType } from 'app/containers/Root/Notifications/Form/types';

// The initial state of the Root container
export const initialState: ContainerState = {
  settings: {},
};

const rootSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getSettings(state) {
      state.settings.loading = true;
    },
    getSettingsOk(
      state,
      action: PayloadAction<INotificationsSettingsResponse>,
    ) {
      state.settings.loading = false;
      state.settings.selected_parties = action.payload.selected_parties;
      state.settings.events_data = action.payload.events_data;
    },
    getSettingsError(state, action: PayloadAction<BlockCrashError>) {
      state.settings.loading = false;
      state.settings.error = action.payload;
    },
    saveSettings(state) {
      state.settings.loading = true;
    },
    saveSettingsOk(state) {
      state.settings.loading = false;
    },
    saveSettingsError(state) {
      state.settings.loading = false;
    },
    onChangeSelectedParties(
      state,
      action: PayloadAction<IOnChangeSelectedParties>,
    ) {
      state.settings.selected_parties = action.payload.selected_parties;
    },
    initEventFormState(
      state,
      action: PayloadAction<NotificationFieldsStateType>,
    ) {
      state.settings.eventsForm = action.payload;
    },
    onChangeEventFieldValue(
      state,
      action: PayloadAction<IOnChangeEventFieldValue>,
    ) {
      const { value, field_id } = action.payload;

      if (state.settings.eventsForm) {
        state.settings.eventsForm[field_id].value = value;
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = rootSlice;
