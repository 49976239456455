import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockSoftwarePackage } from 'app/containers/RootInsights/types';

const { datatype, lorem } = faker;

export const insightsItemDataBlockSoftwarePackageMock: () => IInsightsItemDataBlockSoftwarePackage =
  () => ({
    id: 'IInsightsItemDataBlockSoftwarePackage',
    type: 'software_package',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Software Package',
    name: lorem.words(2),
    entity_id: datatype.uuid(),
  });
