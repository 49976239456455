import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { faker } from '@faker-js/faker';

const { internet } = faker;

export interface APIPayloadBootstrapEnv {}

export type APIResponseBootstrapEnv = {
  documentation_url: string;
};

export const getBootstrapEnvApi: RequestApi<
  APIPayloadBootstrapEnv,
  APIResponseBootstrapEnv
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ documentation_url: internet.url() });
      }, apiDelay);
    });
  }

  return request(`/bootstrap/env`, { signal });
};
