import { styled } from '@mui/material/styles';
import { Avatar, ButtonBase } from '@mui/material';

export const StyledButton = styled(ButtonBase)`
  border-radius: 50%;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: #108596;
`;

export const StyledEditWindow = styled('div')`
  position: relative;
  .paddingConfig {
    padding: 0;
    max-height: calc(100vh - 33rem);
  }
`;
