import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';

export interface APIPayloadTotalsEvents {}

export interface APIResTotalsEvents {
  events_count: number;
}

const mock: APIResTotalsEvents = { events_count: 56 };

export const getTotalsEventsAPI: RequestApi<
  APIPayloadTotalsEvents,
  APIResTotalsEvents
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/totals/events`, { signal });
};
