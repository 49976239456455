/**
 *
 * LoadingBlock
 *
 */
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export function LoadingBlock() {
  return (
    <Box textAlign="center" p={6}>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <CircularProgress />
    </Box>
  );
}
