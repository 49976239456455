/**
 *
 * RiskDomainsScopesBox
 *
 */
import React from 'react';
import { useParams } from 'react-router-dom';
import { IScopeItem } from '../../containers/AbstractTable/types';
import { ThirdPartyMatchParams } from '../../containers/RootThirdParty/PageThirdParty';
import { useTranslation } from 'react-i18next';
import { translations } from '../../../locales/translations';
import { Box, Link, Popover, Typography } from '@mui/material';

interface Props {
  scopes: IScopeItem[];
}

export function RiskDomainsScopesBox({ scopes }: Props) {
  const { t } = useTranslation();

  const { scopeId } = useParams<ThirdPartyMatchParams>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const open = Boolean(anchorEl);

  const menuId = open ? 'menu-popover' : undefined;

  const handleOpenDropDown = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const count = scopes.length;

  if (count === 0) {
    return null;
  }

  const setLabel = () => {
    if (scopeId) {
      return t(
        translations.components.riskDomainsScopesBox.additionalScopeCount,
        {
          count,
        },
      );
    }

    return t(translations.components.riskDomainsScopesBox.scopeCount, {
      count,
    });
  };

  return (
    <>
      <Typography variant="body1">
        <Link style={{ cursor: 'pointer' }} onClick={handleOpenDropDown}>
          {setLabel()}
        </Link>
      </Typography>
      <Popover
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          {scopes.map(i => (
            <Typography variant="body1" gutterBottom key={i.id}>
              {i.label}
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
}
