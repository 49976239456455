/**
 *
 * AbstractTable
 *
 */

import React from 'react';
import { useTable } from './useTable';
import { TableContext } from './TableContext';
import { StyledTable, TableWrapper } from './styled';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { TableEmpty } from './TableEmpty';
import { AbstractTableProps } from './types';
import { Box } from '@mui/material';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';

export function AbstractTable(props: AbstractTableProps) {
  const tableController = useTable(props);

  const { maxHeight, stickyHeader, loading, styleType, size } = props;

  return (
    <TableContext.Provider value={{ ...tableController }}>
      <Box position="relative">
        <TableWrapper maxHeight={maxHeight}>
          <StyledTable
            stickyHeader={stickyHeader || '0'}
            styleType={styleType || 'default'}
            size={size || 'default'}
          >
            <TableHead />
            <TableBody />
          </StyledTable>
        </TableWrapper>
        <TableEmpty />
        <LoadingOverlay loading={Boolean(loading)} />
      </Box>
    </TableContext.Provider>
  );
}
