import React, { ChangeEventHandler, memo } from 'react';
import { TableRowProps } from '../types';
import { StyledTableCell, StyledTableRow } from '../styled';
import { TableCell } from '../TableCell';
import { TableRowContext } from './TableRowContext';
import { useTableRow } from './useTableRow';
import { TableRowGroup } from './TableRowGroup';
import { SecondaryActionCell } from '../TableCell/SecondaryActionCell';
import { CheckBox } from 'app/components/Inputs/CheckBox';

export const TableRow = memo((props: TableRowProps) => {
  const { row, isExpanded, collapseBody, lasOfGroup } = props;

  const { cells, type, id } = row;

  const tableRowController = useTableRow(props);

  const {
    onRowClick,
    hideCol,
    isHover,
    isActive,
    rowBackground,
    selectable,
    selected_ids,
    onSelectRow,
  } = tableRowController;

  if (type === 'group') {
    return (
      <TableRowContext.Provider value={{ ...tableRowController }}>
        <TableRowGroup />
      </TableRowContext.Provider>
    );
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = event =>
    onSelectRow && onSelectRow(row, event.target.checked);

  const isChecked = selected_ids?.includes(id);

  return (
    <TableRowContext.Provider value={{ ...tableRowController }}>
      <StyledTableRow
        onClick={onRowClick}
        isHover={isHover}
        isActive={isActive}
        rowBackground={rowBackground}
        isExpanded={isExpanded}
        collapseBody={collapseBody}
        lasOfGroup={lasOfGroup}
        data-test={`table_row_${id}`}
      >
        {selectable && (
          <StyledTableCell is_empty>
            <SecondaryActionCell>
              <CheckBox
                size="small"
                checked={isChecked}
                onChange={handleChange}
              />
            </SecondaryActionCell>
          </StyledTableCell>
        )}
        {cells
          .filter((_i, n) => n !== hideCol)
          .map((cell, index) => (
            <TableCell key={index} index={index} cell={cell} />
          ))}
      </StyledTableRow>
    </TableRowContext.Provider>
  );
});
