import { getCurrentUserApi } from 'api/users/getCurrentUserApi';
import { actions } from 'app/containers/Root/slice';
import { getUserAsideConfig } from 'app/containers/Root/utils';
import { call, put, select } from 'redux-saga/effects';
import { IAsideItem, IPageItem, IUser } from 'types/user';
import { selectRoot } from 'app/containers/Root/selectors';
import { IRootState } from 'app/containers/Root/types';

interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

declare global {
  interface Window {
    __insp: string[][];
    heap: Heap;
  }
}

export function* getCurrentUser() {
  try {
    const { permissions }: IRootState = yield select(selectRoot);

    const currentUser: IUser = yield call(getCurrentUserApi, {});

    sessionStorage.setItem('user_id', currentUser.user_id);

    yield put(actions.getCustomerInfoOk(currentUser));

    yield setHeapIdentity(currentUser.email);

    const preDefinedRagesList: IPageItem[] = [
      { id: 'risk_board' },
      { id: 'third_party' },
      { id: 'aggregation_risk' },
    ];

    const pages_list = preDefinedRagesList.filter(
      ({ id }) =>
        id === 'third_party' ||
        (id === 'risk_board' && permissions.includes('read:rbm')) ||
        (id === 'aggregation_risk' && permissions.includes('read:rh')),
    );

    const asideConfig: IAsideItem[] = getUserAsideConfig({
      ...currentUser,
      pages_list,
    });

    yield put(actions.setAsideConfig(asideConfig));
  } catch (e: any) {
    yield put(
      actions.onError({ type: 'crash', transactionId: e.transactionId }),
    );
  }
}

function setHeapIdentity(email?: string) {
  if (window.heap && email) {
    window.heap.identify(email);
  }
}
