import React, { createRef } from 'react';
import { FilterProps } from './types';
import { FilterContext } from './FilterContext';
import { useFilterController } from './useFilterController';
import { EditFilterButton } from './EditFilterButton';
import { FilterWrapper } from './styled';
import { FilterMenu } from './FilterMenu';
import { EditFilterWindow } from './EditFilterWindow';
import { FilterDefinition } from './FilterDefinition';

export function Filter(props: FilterProps) {
  const editBoxAnchor = createRef<HTMLDivElement>();

  const filterController = useFilterController(props, editBoxAnchor);

  return (
    <FilterContext.Provider value={{ ...filterController }}>
      <FilterWrapper disabled={props.disabled}>
        <FilterMenu ref={editBoxAnchor} />
        <FilterDefinition />
        <EditFilterButton />
      </FilterWrapper>
      <EditFilterWindow />
    </FilterContext.Provider>
  );
}
