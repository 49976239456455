import { PaletteMode, ThemeOptions } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const getComponents = (_mode: PaletteMode): Components<ThemeOptions> => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#001591',
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#F0ECFE',
          color: '#1A1719',
          fontSize: '.85rem',
          boxShadow: '0 0 2px 0 rgba(0,0,0,.1)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderTop: '3px solid #3D4FE4',
          borderRadius: '0',
          margin: '.1rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#3D4FE4',
          },
          '&:hover': {
            backgroundColor: '#F0ECFE',
          },
        },
        button: {
          '&:hover': {
            backgroundColor: '#F0ECFE',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#3D4FE4',
          },
          '&:hover': {
            backgroundColor: '#F0ECFE',
          },
        },
      },
    },
  };
};
