import React from 'react';
import { Box } from '@mui/material';
import { CheckBox } from 'app/components/Inputs/CheckBox';
import { useNotificationCenter } from '../../Context';
import { IAbstractFieldCheckbox } from 'app/containers/Root/Notifications/Form/types';
import { useSelector } from 'react-redux';
import { selectEventFormField } from 'app/containers/Root/Notifications/selectors';

interface FieldCheckboxProps extends IAbstractFieldCheckbox {}

export const FieldCheckbox = ({ id, label }: FieldCheckboxProps) => {
  const { value } = useSelector(
    selectEventFormField(id),
  ) as IAbstractFieldCheckbox;

  const { onChangeEventFieldValue } = useNotificationCenter();

  const handleChange = () => {
    onChangeEventFieldValue({ field_id: id, value: !value });
  };

  return (
    <Box>
      <CheckBox
        label={label}
        size="small"
        checked={!!value}
        onChange={handleChange}
      />
    </Box>
  );
};
