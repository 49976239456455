import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';

export const StyledTitle = styled(DialogTitle)<DialogTitleProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
