import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { IThirdParty } from 'app/containers/RootThirdParty/types';
import { getPartyMock } from 'api/thirdParties/getPartyAPI/mock';

export interface APIPayloadParty extends WithPartyId {}

export type APIResponseParty = IThirdParty;

export const getPartyAPI: RequestApi<
  APIPayloadParty,
  APIResponseParty
> = async ({ thirdPartyId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getPartyMock(thirdPartyId));
      }, apiDelay);
    });
  }

  return request(`/third_parties/${thirdPartyId}`, { signal });
};
