import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { mock } from 'api/thirdParties/getNewPartyFormAPI/mock';
import { NewPartyFormBlock } from 'app/containers/RootThirdParty/PageAddThirdParty/types';

export type APIPayloadNewPartyForm = {};

export type APIResponseNewPartyForm = {
  blocks: NewPartyFormBlock[];
};

export const getNewPartyFormAPI: RequestApi<
  APIPayloadNewPartyForm,
  APIResponseNewPartyForm
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/third_parties/new_party_form`, { signal });
};
