import React, { FC } from 'react';
import MUITooltip, {
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material';

type TypographyProps = MuiTooltipProps;

// trick to apply styles. see: https://stackoverflow.com/questions/36759985/how-to-style-mui-tooltip
const ToBeStyledTooltip: FC<TypographyProps> = ({
  className,
  children,
  ...props
}) => (
  <MUITooltip children={children} {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: '#F0ECFE',
  maxWidth: 'none',

  [`& .${tooltipClasses.arrow}`]: {
    color: '#1A1719',
  },
}));

export const Tooltip: FC<TypographyProps> = ({ ...props }) => {
  return <StyledTooltip {...props} />;
};
