import { APIResponseRiskBoardMatrix } from 'api/riskBoard/getRiskBoardMatrixAPI/index';
import { RiskBoardMatrixType } from 'app/containers/RootRiskBoard/types';
import { faker } from '@faker-js/faker';
import { ICell, IMatrix, ITrends } from 'types/matrix';
import { matrixBase } from 'api/riskBoard/getRiskBoardMatrixAPI/matrixBase';
import { buildMockFilterData } from 'api/mockFilterData';

export const trendsListMock: ITrends[] = [
  {
    value: '30days',
    label: 'Last 30 days',
  },
  {
    value: '60days',
    label: 'Last 60 days',
  },
  {
    value: '90days',
    label: 'Last 90 days',
  },
  {
    value: '6months',
    label: 'Last 6 months',
  },
];

const { datatype } = faker;

const cells = (
  delta: boolean,
  { row, col }: { row: number; col: number },
): ICell[] => {
  const cellsResult: ICell[] = [];

  for (let x = 0; x < row; x++) {
    for (let y = 0; y < col; y++) {
      if (datatype.boolean()) {
        cellsResult.push({
          x,
          y,
          value: datatype.number({ min: 10, max: 100 }),
          delta,
          id: `${x},${y}`,
        });
      }
    }
  }

  return cellsResult;
};

const generateMatrix = (delta: boolean): IMatrix => ({
  ...matrixBase,
  cells: [...cells(delta, { row: 4, col: 3 })],
});

const trendsMatrix = generateMatrix(true);

const totalsMatrix = generateMatrix(false);

export const matrixMock = (
  type: RiskBoardMatrixType,
): APIResponseRiskBoardMatrix => ({
  matrix: type === 'trends' ? trendsMatrix : totalsMatrix,
  trends: trendsListMock,
  filter_data: buildMockFilterData(),
  party_count: datatype.number({ max: 200 }),
  scope_count: datatype.number({ max: 200 }),
});
