import React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Parties } from './Parties/Parties';
import { Events } from './Events/Events';
import NavTabs from 'app/components/Tabs/NavTabs';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ErrorBlock } from 'app/containers/ErrorBlock';
import { LoadingBlock } from 'app/components/LoadingBlock';
import { LoadingPage } from 'app/components/LoadingPage';
import { Box } from 'app/components/Box';
import { useNotificationCenter } from './Context';

const tabs = ['parties', 'events'];

export type TabsType = typeof tabs[number];

const selectComponent: {
  [key in TabsType]:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | undefined;
} = {
  parties: Parties,
  events: Events,
};

export const Body = () => {
  const { path } = useRouteMatch();

  const { t } = useTranslation();

  const { settings } = useNotificationCenter();

  const { error, loading } = settings;

  const tabsSearch: {
    [key in TabsType]: string;
  } = {
    parties: sessionStorage.getItem('notification_center_parties_search') || '',
    events: '',
  };

  if (error) {
    return (
      <Box p="1rem 2rem">
        <ErrorBlock error={error} />
      </Box>
    );
  }

  if (!settings.selected_parties || !settings.events_data) {
    return (
      <Box p="1rem 2rem">
        <LoadingBlock />
      </Box>
    );
  }

  return (
    <Box p="1rem 2rem">
      <NavTabs
        tabs={tabs.map(id => ({
          id,
          label: t(translations.notificationSettings[id]),
          linkTo: `/notification-center/${id}${tabsSearch[id]}`,
        }))}
        centered
        removeDivider
      />
      <Box m="2rem 0 0 0">
        <Route
          path={path}
          exact
          render={() => <Redirect to={`/notification-center/${tabs[0]}`} />}
        />
        {tabs.map(id => (
          <Route
            key={id}
            path={`/notification-center/${id}`}
            component={selectComponent[id]}
          />
        ))}
      </Box>
      <LoadingPage isLoading={loading} fullPage transparent />
    </Box>
  );
};
