import { Box, Typography } from '@mui/material';
import React from 'react';
import { PopupViewBlockProperty } from '../types';

export const BlockProperty = ({ name, value }: PopupViewBlockProperty) => (
  <Box m="1rem 0">
    <Typography variant="body2">
      <strong>{name}</strong>: {value}
    </Typography>
  </Box>
);
