/**
 *
 * CellEventTitle
 *
 */

import React from 'react';
import { IEventTitleCell } from '../../types';
import { StyledTextWithAvatar } from '../../styled';
import { Typography } from '@mui/material';
import { DefaultAvatarIcon } from '../../../../components/Icons/svg/DefaultAvatarIcon';

export const CellEventTitle = ({ data }: IEventTitleCell) => {
  const { logo, title } = data;

  const BodyImg = () => {
    if (logo) {
      return <img className="cell-image" src={logo} alt="logo" />;
    }
    return <DefaultAvatarIcon />;
  };

  return (
    <StyledTextWithAvatar>
      <BodyImg />
      <Typography variant="body2">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Typography>
    </StyledTextWithAvatar>
  );
};
