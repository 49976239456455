export const abstractDataIdsArray = [
  'totals_parties_scopes',
  'totals_risk_board',
  'totals_events',
  'parties_list',
  'notification_center_parties_list',
  'party',
  'risk_domains',
  'scopes_list',
  'scope_options_list',
  'scope_data',
  'party_documents',
  'party_insights',
  'party_insight_item',
  'risk_board_matrix',
  'risk_board_matrix_cell',
  'risk_board_matrix_cell_groups',
  'logo_fetch',
  'logo_fetch_item',
  'files_config',
  'new_party_form',
  'new_scope_form',
  'risk_hunter',
] as const;
