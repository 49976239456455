/**
 *
 * CircleCounter
 *
 */
import React from 'react';
import { styled } from '@mui/material';

export interface IStyledCircleCounterProps {
  color: string;
  textColor?: string;
}

export const StyledCircleCounter = styled('div', {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'textColor',
})<IStyledCircleCounterProps>`
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  font-size: 0.65rem;
  color: ${({ textColor, theme }) =>
    textColor || theme.palette.primary.contrastText};
`;

export interface ICircleCounterProps {
  color: string;
  count: number;
}

export function CircleCounter({ color, count }: ICircleCounterProps) {
  return <StyledCircleCounter color={color}>{count}</StyledCircleCounter>;
}
