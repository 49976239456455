/**
 *
 * LayoutDesktop
 *
 */

import { styled } from '@mui/material/styles';
import { Switch, Typography } from '@mui/material';

interface StyledLabelProps {
  isOn?: boolean;
}

export const StyledLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isOn',
})<StyledLabelProps>`
  color: ${({ isOn }) => (isOn ? '#3D4FE4' : '#827f81')};
`;

export const StyledSwitchWrapper = styled('div')`
  margin: 1rem 0;
  display: inline-block;
`;

export const AntSwitch = styled(Switch)`
  width: 28px;
  height: 16px;
  padding: 0;
  display: flex;

  .MuiSwitch-switchBase {
    padding: 2px;
    color: ${({ theme }) => theme.palette.grey[500]};

    &.Mui-checked {
      transform: translateX(12px);
      color: ${({ theme }) => theme.palette.common.white};
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${({ theme }) => theme.palette.common.white};
      }
    }
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiSwitch-track {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const BigSwitch = styled(Switch)`
  width: 50px;
  height: 24px;
  padding: 0;
  display: flex;

  .MuiSwitch-switchBase {
    padding: 2px;
    color: ${({ theme }) => theme.palette.grey[500]};

    &.Mui-checked {
      transform: translateX(25px);
      color: ${({ theme }) => theme.palette.common.white};
      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${({ theme }) => theme.palette.common.white};
      }
    }
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    box-shadow: none;
    background-color: #3d4fe4;
  }

  .MuiSwitch-track {
    border: 1px solid #827f81;
    border-radius: 20px;
    opacity: 1;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;
