import { PaletteMode } from '@mui/material';
import { LayoutStyles } from '../types';
import { StyleConstants } from '../../StyleConstants';

export const getLayoutStyles = (mode: PaletteMode): LayoutStyles => {
  return {
    paddingBottom: StyleConstants.HEADER_PADDING_BOTTOM,
    desktopPageBoxPadding: StyleConstants.DESKTOP_PAGE_BOX_PADDING,

    alert: {
      errorBorder: '3px solid #BF0330',
    },

    notFoundPage: {
      color: '#3D4FE4',
    },

    header: {
      height: StyleConstants.HEADER_HEIGHT,
      background: '#fff',
      contrastText: 'rgba(0, 0, 0, 0.56)',
    },

    headerSearchBar: {
      background: '#F6F8F7',
      buttonBackground: '#653df4',
      border: '1px solid #bcbbbb',
      maxWidth: StyleConstants.HEADER_SEARCH_BAR_MAX_WIDTH,
      addPartyLinkColor: '#001591',
    },

    aside: {
      width: StyleConstants.ASIDE_WIDTH,
      background: '#F6F8F7',
      addButtonBackground: '#653df4',
      addButtonBackgroundHover: '#653df4',
    },

    asideItem: {
      activeBackground: '#fff',
      defaultColor: '#827F81',
      activeColor: '#653DF4',
      secondaryActiveColor: '#FFC72B',
    },

    loadingPage: {
      circle: '#ddd',
      borderColor: '#ddd',
      background: 'rgba(255,255,255,1)',
      backgroundTransparent: 'rgba(255,255,255,.5)',
    },

    table: {
      background: {
        default: '#F6F8F7',
        dashed: '#F6F8F7',
        contrast: '#FFFFFF',
      },
      tableBorder: {
        default: 'none',
        dashed: 'none',
        contrast: '1px solid rgba(130, 127, 129, 0.6)',
      },
      backgroundSecondary: {
        default: '#F6F8F7',
        dashed: '#F6F8F7',
        contrast: '#F6F8F7',
      },
      headBackground: {
        default: '#EFF3F1',
        dashed: '#EFF3F1',
        contrast: '#F6F8F7',
      },
      headerBorder: {
        default: '8px solid #fff',
        dashed: '1px dashed rgba(130, 127, 129, 0.6)',
        contrast: '1px solid rgba(130, 127, 129, 0.6)',
      },
      rowHover: {
        default: 'rgba(101, 61, 244, .1)',
        dashed: 'rgba(101, 61, 244, .1)',
        contrast: 'rgba(101, 61, 244, .1)',
      },
      rowBorder: {
        default: '8px solid #fff',
        dashed: '1px dashed rgba(130, 127, 129, 0.6)',
        contrast: 'none',
      },
      rowPaddingTopBottom: {
        default: '0.8rem',
        small: '0.4rem',
      },
      rowPaddingLeftRight: {
        default: '0.4rem',
        small: '0.3rem',
      },
      rowPaddingCorner: {
        default: '1.2rem',
        small: '1rem',
      },
      rowBackgroundSelected: '#fff',
    },

    pagination: {
      buttonColor: '#bcbbbb',
      buttonColorHover: '#1a1719',
    },

    filter: {
      filterButtonBackground: `#fff`,
      filterButtonBorder: '1px solid #dedede',
    },

    pad: {
      background: '#F6F8F7',
      backgroundDark: '#EFF3F1',
    },

    listItem: {
      textSelected: '#3d4fe4',
      backgroundSelected: 'transparent',
      backgroundHover: '#f0ecfe',
    },

    aggregatorButton: {
      background: '#e8eafc',
    },

    insightIndicatorColor: {
      compliant: '#158A6D',
      non_compliant: '#BF0330',
      insight: '#827F81',
    },

    pdfViewer: {
      backgroundColor: '#d3d3d3',
    },
  };
};
