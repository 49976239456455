/**
 *
 * TableTop
 *
 */
import { List, MenuItem, Popover, Typography } from '@mui/material';
import React from 'react';
import { DropdownMenuItem } from './DropdownMenuItem';
import { useTranslation } from 'react-i18next';
import { translations } from '../../../locales/translations';

interface DropdownMenuProps {
  limit: number;
  limitOptions?: number[];
  onChangeLimit?: (limit: number) => void;
  anchorEl?: HTMLButtonElement | null;
  handleClose: () => void;
}

export function DropdownMenu({
  limit,
  onChangeLimit,
  limitOptions = [],
  anchorEl,
  handleClose,
}: DropdownMenuProps) {
  const { t } = useTranslation();

  const handleChangeLimit = (limit: number) => {
    onChangeLimit?.(limit);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const menuId = open ? 'menu-popover' : undefined;

  return (
    <Popover
      id={menuId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
        <MenuItem disabled>
          <Typography variant="body2">
            {t(translations.components.tableTop.resultsPerPage)}:
          </Typography>
        </MenuItem>
        {limitOptions.map(option => (
          <DropdownMenuItem
            key={option}
            option={option}
            limit={limit}
            onChangeLimit={handleChangeLimit}
          />
        ))}
      </List>
    </Popover>
  );
}
