import { faker } from '@faker-js/faker';
import {
  IInsightsItemDataBlockOnlineAsset,
  SeverityType,
} from 'app/containers/RootInsights/types';
import { getRandomItemFromArray } from 'api/utils';

const { datatype, internet } = faker;

export const insightsItemDataBlockOnlineAssetMock: () => IInsightsItemDataBlockOnlineAsset =
  () => ({
    id: 'IInsightsItemDataBlockOnlineAsset',
    type: 'online-asset',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Online Asset',
    domain: internet.url(),
    severity: getRandomItemFromArray([
      'critical',
      'high',
      'medium',
      'low',
      'info',
    ]) as SeverityType,
  });
