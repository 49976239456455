import React from 'react';
import { useNotifications } from '../index';
import { Modal } from '@mui/material';
import { StyledModal } from './styled';
import { Header } from './Header';
import { Body } from './Body';
import { Actions } from './Actions';
import { useEffectOnMount } from '../../../../../utils/useEffectOnMount';
import { NotificationCenterContext } from './Context';

export const NotificationCenter = () => {
  const controller = useNotifications();

  const { getSettings } = controller;

  useEffectOnMount(() => {
    getSettings();
  });

  return (
    <NotificationCenterContext.Provider value={controller}>
      <Modal open>
        <StyledModal>
          <Header />
          <Body />
          <Actions />
        </StyledModal>
      </Modal>
    </NotificationCenterContext.Provider>
  );
};
