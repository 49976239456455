import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockDate } from 'app/containers/RootInsights/types';

const { datatype } = faker;

export const insightsItemDataBlockDateMock: () => IInsightsItemDataBlockDate =
  () => ({
    id: 'IInsightsItemDataBlockDate',
    type: 'date',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Date',
    date: datatype.datetime().toDateString(),
  });
