/**
 *
 * LoadingPage
 *
 */
import React from 'react';
import { styled } from '@mui/material/styles';
import { MainLogo } from '../Icons/svg/MainLogo';

interface LoadingPageProps {
  isLoading?: boolean;
  fullPage?: boolean;
  transparent?: boolean;
}

const Wrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isLoading' && prop !== 'fullPage' && prop !== 'transparent',
  name: 'Wrapper',
  slot: 'Root',
})<LoadingPageProps>`
  ${({ theme }) =>
    `height: calc(100vh - ${theme.layout.header.height} - 1.5rem - 1.5rem - 1rem - 5rem);`};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  & > div {
    position: relative;
    transition: 0.3s;
  }

  ${({ fullPage, theme, transparent }) =>
    fullPage &&
    `
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background: ${
      transparent
        ? theme.layout.loadingPage.backgroundTransparent
        : theme.layout.loadingPage.background
    };
    z-index: 999;
  `}

  ${({ isLoading }) =>
    isLoading
      ? `
    pointer-events: all;
    opacity: 1;
      & > div {
        transform: scale(1);
      }
  `
      : `
    pointer-events: none;
    opacity: 0;
      & > div {
        transform: scale(0);
      }
  `}
  
  svg {
    position: relative;
    z-index: 9;
  }
  i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.8);
    transition: transform 0.3s;
    svg {
      width: 3rem;
      height: 3rem;
      animation: pulseM 2s ease infinite;
    }
    &:before,
    &:after {
      background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 0) 20%,
        ${({ theme }) => theme.layout.loadingPage.circle} 100%
      );
      border: 1px solid ${({ theme }) => theme.layout.loadingPage.borderColor};
    }
    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: -130%;
      top: -130%;
      right: -130%;
      bottom: -130%;
      opacity: 0.2;
      animation: pulse 2s ease infinite;
    }
    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
      opacity: 0.2;
      animation: pulse 2s 1s ease infinite;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.2);
      opacity: 0.5;
    }
    60% {
      transform: scale(1);
      opacity: 0.2;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes pulseM {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export function LoadingPage(props: LoadingPageProps) {
  return (
    <Wrapper {...props}>
      <div>
        <i>
          <MainLogo />
        </i>
      </div>
    </Wrapper>
  );
}
