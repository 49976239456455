/**
 *
 * TableBody
 *
 */

import React from 'react';
import { useTable } from '../TableContext';
import { TableRow } from '../TableRow';

export const TableBody = () => {
  const { table } = useTable();

  return (
    <tbody>
      {table.rows.map((row, n) => (
        <TableRow key={`${n}_${row.id}`} row={row} />
      ))}
    </tbody>
  );
};
