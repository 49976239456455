import { IAbstractTableData, ITableRow } from './types';

interface ICheckIfSelected {
  selected: boolean;
  all: boolean;
}
export const checkIfRowsSelected = (
  table: IAbstractTableData,
  selected_ids?: string[],
): ICheckIfSelected => {
  let selected = false;

  let all = true;

  const isSelected = (rows: ITableRow[]) => {
    rows.forEach(row => {
      if (row.type === 'group') {
        isSelected(row.table.rows);
      } else if (selected_ids?.includes(row.id)) {
        selected = true;
      } else {
        all = false;
      }
    });
  };

  isSelected(table.rows);

  return { selected, all };
};
