import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { IPartiesLogo } from 'app/containers/RootAdmin/LogoFetch/types';
import { mock } from 'api/admin/getLogoFetchAPI/mock';

export interface APIPayloadLogoFetch {}

export interface APIResLogoFetch extends IPartiesLogo {}

export const getLogoFetchAPI: RequestApi<
  APIPayloadLogoFetch,
  APIResLogoFetch
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/logo_fetch/all`, { signal });
};
