import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { NotificationField } from 'app/containers/Root/Notifications/Form/types';

export interface APIPayloadSaveNotificationSettings {
  selected_parties: string[];
  events_data: NotificationField[];
}

export type APIResponseSaveNotificationSettings = void;

export const saveNotificationSettingsAPI: RequestApi<
  APIPayloadSaveNotificationSettings,
  APIResponseSaveNotificationSettings
> = async ({ selected_parties, events_data }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, apiDelay);
    });
  }

  return request(`/notifications/settings`, {
    signal,
    method: 'POST',
    body: JSON.stringify({
      selected_parties,
      events_data,
    }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
};
