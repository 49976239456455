import { IInsightGroupIndicatorCell } from 'app/containers/AbstractTable/types';
import { StyledInsightGroupIndicator } from 'app/containers/AbstractTable/styled';
import { useTableRow } from 'app/containers/AbstractTable/TableRow/TableRowContext';
import { ArrowRightDown } from 'app/components/Icons/svg/ArrowRightDown';
import { Icon } from 'app/components/Icon';
import React from 'react';

export const CellInsightGroupIndicator = ({
  data,
}: IInsightGroupIndicatorCell) => {
  const { isExpanded } = useTableRow();

  const { icon } = data;

  return (
    <StyledInsightGroupIndicator isExpanded={isExpanded}>
      <Icon name={`group_${icon}`} width="1.8rem" height="1.8rem" />
      <ArrowRightDown />
    </StyledInsightGroupIndicator>
  );
};
