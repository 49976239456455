/**
 *
 * ErrorFallbackPage
 *
 */

import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledErrorWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
`;

export const StyledErrorText = styled(Typography)`
  background: #f9e6eb;
  margin: 2rem 0;
  padding: 1rem;
  border: 1px solid #eb5757;
  border-radius: 4px;
  min-width: 300px;
`;

export function ErrorFallbackPage() {
  return (
    <StyledErrorWrapper>
      <StyledErrorText textAlign="center">Error!</StyledErrorText>
    </StyledErrorWrapper>
  );
}
