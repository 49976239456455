import { IUseTableRow, UseTableRowProps } from '../types';
import { useState } from 'react';
import { useTable } from '../TableContext';

export function useTableRow({
  row,
  groupItem,
}: UseTableRowProps): IUseTableRow {
  const {
    onClickRow,
    activeId,
    hideCol,
    hideCell,
    selectable,
    selected_ids,
    onSelectRow,
  } = useTable();

  const { type, id } = row;

  const onRowClick = () => {
    if (onClickRow) {
      switch (type) {
        case 'insight':
        case 'link-by-id':
          onClickRow(id, groupItem);
          break;
        case 'link-to-scope':
          if ('scope_id' in row) {
            onClickRow(`${id}/scope/${row.scope_id}`, groupItem);
          }
          break;
        case 'link-to-insight':
          if ('insight_id' in row) {
            onClickRow(
              `/third-party/${id}/insights/${row.insight_id}`,
              groupItem,
            );
          }
          break;
      }
    }
  };

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpanded = () => setIsExpanded(prev => !prev);

  const setRowBackground = () => {
    switch (row.highlight) {
      case 'alert':
        return 'rgba(191,3,48, .2)';
      default:
        return undefined;
    }
  };

  const isActive = activeId === id;

  const isHover =
    type === 'link-by-id' ||
    type === 'link-to-insight' ||
    type === 'link-to-scope' ||
    type === 'insight';

  const rowBackground = setRowBackground();

  return {
    row,
    isExpanded,
    isActive,
    isHover,
    rowBackground,
    hideCol,
    hideCell,
    selectable,
    selected_ids,
    onSelectRow,
    onRowClick,
    handleExpanded,
  };
}
