import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';
import { IPageParams } from 'app/hooks/usePageParams/types';
import {
  IAbstractFilterData,
  IAbstractLegend,
  IAbstractPageData,
  IAbstractTable,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { mock } from 'api/scopes/getScopeListAPI/mock';

export interface APIPayloadScopeList extends IPageParams, WithPartyId {}

export interface APIResponseScopeList
  extends IAbstractTable,
    IAbstractFilterData,
    IAbstractPageData,
    IAbstractLegend {}

export const getScopeListAPI: RequestApi<
  APIPayloadScopeList,
  APIResponseScopeList
> = async ({ thirdPartyId, ...props }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(
    `/third_parties/${thirdPartyId}/scopes/?${stringifyParams(props)}`,
    { signal },
  );
};
