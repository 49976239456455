/**
 *
 * FilterDefinition
 *
 */
import React from 'react';
import { useFilter } from './FilterContext';
import { FilterTag } from './FilterTag';

export function FilterDefinition() {
  const { currentFilter } = useFilter();

  if (!currentFilter) {
    return null;
  }

  return (
    <>
      {currentFilter.items.map((i, n) => (
        <FilterTag key={n} operator={currentFilter?.operator} item={i} />
      ))}
    </>
  );
}
