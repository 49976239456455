import { IInsightTypeIconCell } from 'app/containers/AbstractTable/types';
import { StyledInsightTypeIcon } from 'app/containers/AbstractTable/styled';
import { Icon } from 'app/components/Icon';

export const CellInsightTypeIcon = ({ data }: IInsightTypeIconCell) => {
  const { icon } = data;

  return (
    <StyledInsightTypeIcon>
      <Icon name={`insight_type_${icon}`} width="1.5rem" height="1.5rem" />
    </StyledInsightTypeIcon>
  );
};
