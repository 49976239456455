import { useCurrentUserNav } from './Context';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { AdminSection } from './AdminSection';
import { UserInfoSection } from './UserInfoSection';
import { NotificationSection } from 'app/containers/Root/Header/CurrentUserNav/NotificationSection';
import { ReportsSection } from 'app/containers/Root/Header/CurrentUserNav/ReportsSection';
import { SupportSection } from './SupportSection';
import { Popover } from 'app/components/Popover';
import { Box } from 'app/components/Box';
import { Divider } from 'app/components/Divider';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { Link } from 'app/components/Link';

export function DropMenu() {
  const { t } = useTranslation();

  const { anchorEl, closeMenu, handleLogoutClick } = useCurrentUserNav();

  const open = Boolean(anchorEl);
  const id = open ? 'customer-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: { minWidth: '14rem' },
      }}
    >
      <Box p="1rem 0">
        <UserInfoSection />
        <Box m=".5rem 0">
          <Divider variant="middle" />
        </Box>
        <Box component="ul" p={0} m={0}>
          <AdminSection />
          <NotificationSection />
          <ReportsSection />
          <SupportSection />
          <MenuItem onClick={handleLogoutClick}>
            <Link underline="none" variant="body2" component="span">
              {t(translations.mainNav.logout)}
            </Link>
          </MenuItem>
        </Box>
      </Box>
    </Popover>
  );
}
