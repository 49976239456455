import React from 'react';

export const AsidePlusIcon = () => (
  <svg
    width="13"
    height="13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
  >
    <path d="M6 0h1v13H6V0z" fill="#fff" />
    <path d="M12.9996 6L13 7 .00038 7.00348l-.00038-1L12.9996 6z" fill="#fff" />
  </svg>
);
