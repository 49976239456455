import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockQuestion } from 'app/containers/RootInsights/types';

const { datatype, lorem } = faker;

export const insightsItemDataBlockQuestionMock: () => IInsightsItemDataBlockQuestion =
  () => ({
    id: 'IInsightsItemDataBlockQuestion',
    type: 'question',
    highlighted: datatype.boolean(),
    title: 'Insights Item Data Block Question',

    question: {
      question_id: datatype.uuid(),
      question_value: `${lorem.text()}?`,
    },
    answers: [
      { answer_id: datatype.uuid(), answer_value: lorem.words(100) },
      { answer_id: datatype.uuid(), answer_value: lorem.words(50) },
    ],
  });
