import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { IEventBlock } from 'app/containers/Root/Notifications/types';
import { mock } from 'api/notifications/getNotificationSettingsAPI/mock';

export interface APIPayloadNotificationSettings {}

export type APIResponseNotificationSettings = {
  selected_parties: string[];
  events_data: IEventBlock[];
};

export const getNotificationSettingsAPI: RequestApi<
  APIPayloadNotificationSettings,
  APIResponseNotificationSettings
> = async (props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/notifications/settings`, {
    signal,
  });
};
