import { faker } from '@faker-js/faker';
import { IInsightsItemDataBlockAccordionList } from 'app/containers/RootInsights/types';
import { insightsItemDataBlockViewerMock } from 'api/insights/getPartyInsightItemAPI/insightsItemDataBlockViewerMock';

const { datatype, company } = faker;

export const insightsItemDataBlockAccordionListMock: () => IInsightsItemDataBlockAccordionList =
  () => ({
    id: 'IInsightsItemDataBlockAccordionList',
    type: 'accordion-list',
    title: '8 evidence (2 relationship)',
    items: [
      {
        id: datatype.uuid(),
        title: company.name(),
        subtitle: `${datatype.number({ max: 10 })} evidence`,
        blocks: [
          insightsItemDataBlockViewerMock(),
          insightsItemDataBlockViewerMock(),
          insightsItemDataBlockViewerMock(),
        ],
      },
      {
        id: datatype.uuid(),
        blocks: [
          {
            id: datatype.uuid(),
            type: 'text',
            title: `${company.name()} (${datatype.number({
              max: 10,
            })} evidence)`,
            text: '',
            mods: ['nomargin'],
          },
          insightsItemDataBlockViewerMock(),
          insightsItemDataBlockViewerMock(),
          insightsItemDataBlockViewerMock(),
        ],
      },
    ],
  });
