import { ITextCell } from 'app/containers/AbstractTable/types';
import { TextWithMods } from 'app/components/TextWithMods';
import { Typography } from 'app/components/Typography';

export const CellText = ({ data }: ITextCell) => {
  const { text, mods } = data;

  return (
    <Typography variant="body2" noWrap={mods?.includes('nowrap')}>
      <TextWithMods mods={mods || []}>{text}</TextWithMods>
    </Typography>
  );
};
