/**
 *
 * CellEventTypeIcon
 *
 */

import React from 'react';
import { IEventTypeIconCell } from '../../types';
import { StyledTableIcon } from '../../styled';
import { IconEventType } from '../../../Root/Notifications/IconEventType';

export const CellEventTypeIcon = ({ data }: IEventTypeIconCell) => {
  const { icon } = data;

  return (
    <StyledTableIcon>
      <IconEventType icon={icon} />
    </StyledTableIcon>
  );
};
