/**
 *
 * InputSwitch
 *
 */
import React from 'react';
import { StyleBig } from './StyleBig';
import { StyleDefault } from './StyleDefault';
import { WithTestID } from 'types/common';

export interface InputSwitchProps extends WithTestID {
  on?: string;
  off?: string;
  name?: string;
  value: boolean;
  handleChange: (value: boolean) => void;
  inputStyle?: 'big' | 'default';
}

export const InputSwitch = (props: InputSwitchProps) => {
  if (props.inputStyle === 'big') {
    return <StyleBig {...props} />;
  }

  return <StyleDefault {...props} />;
};
