import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.root || initialState;

export const selectRoot = createSelector(
  [selectDomain],
  rootState => rootState,
);

export const selectAlerts = createSelector(
  [selectDomain],
  rootState => rootState.alerts,
);
