import React, { useState } from 'react';
import {
  IPopupLocationAlertStatusId,
  IUseTableCell,
  TableCellProps,
} from 'app/containers/AbstractTable/types';
import { useTable } from 'app/containers/AbstractTable/TableContext';

export const setPopupBorder = (status_id?: IPopupLocationAlertStatusId) => {
  switch (status_id) {
    case 'alert':
      return '3px solid rgb(191, 3, 48)';
    case 'info':
      return '3px solid #3D4FE4';
    default:
      return undefined;
  }
};

export function useTableCell({ cell }: TableCellProps): IUseTableCell {
  const { setColspan } = useTable();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl);

  const { mods, popup } = cell;

  const is_small = Boolean(mods?.includes('small'));

  const colSpan = setColspan(mods);

  const popupBorderTop = setPopupBorder(popup?.status_id);

  return {
    is_small,
    colSpan,
    isPopoverOpen,
    anchorEl,
    popupBorderTop,
    handlePopoverOpen,
    handlePopoverClose,
  };
}
