/**
 *
 * FilterContext
 *
 */
import React, { createContext } from 'react';
import { IUseFilterController } from './types';

export const FilterContext = createContext<IUseFilterController | undefined>(
  undefined,
);

export const useFilter = () => {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterContextProvider');
  }
  return context;
};
