import { ITableLegend } from 'app/containers/AbstractTable/types';
import { LegendWrapper } from './styled';
import { Icon } from 'app/components/Icon';
import { Typography } from 'app/components/Typography';

interface LegendProps {
  legend?: ITableLegend;
}

export function Legend({ legend }: LegendProps) {
  if (!legend) {
    return <div />;
  }

  return (
    <LegendWrapper>
      {legend.items.map(i => (
        <li key={i.id}>
          <Icon name={i.icon} />
          <Typography variant="caption">{i.label}</Typography>
        </li>
      ))}
    </LegendWrapper>
  );
}
