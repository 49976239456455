import React from 'react';

export const ArrowRightDown = () => (
  <svg
    width="12"
    height="11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 11"
  >
    <path d="M7.20711.222656h1V10.2227l-.5.5-.5-.5V.222656z" fill="#1A1719" />
    <path
      d="M10.7071 6.22266l.7071.7071-3.20709 3.29294-.5-1.00004 2.99999-3zM4.70711 6.22266L4 6.92976l3.20711 3.29294.5-1.00004-3-3zM0 .222656h8V1.22266H0V.222656z"
      fill="#1A1719"
    />
  </svg>
);
