import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { APIResTotalsPartiesScopes } from 'api/totals/getTotalsPartiesScopesAPI';

export interface APIPayloadTotalsRiskBoard {}

export interface APIResTotalsRiskBoard extends APIResTotalsPartiesScopes {}

const mock: APIResTotalsPartiesScopes = { party_count: 25, scope_count: 135 };

export const getTotalsRiskBoardAPI: RequestApi<
  APIPayloadTotalsRiskBoard,
  APIResTotalsRiskBoard
> = async (_props, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/totals/risk_board/parties_scopes`, { signal });
};
