/**
 *
 * TableContext
 *
 */

import React, { createContext } from 'react';
import { IUseTable } from './useTable';
import { AbstractTableProps } from './types';

interface ITableContext extends AbstractTableProps, IUseTable {}

export const TableContext = createContext<ITableContext | undefined>(undefined);

export const useTable = () => {
  const context = React.useContext(TableContext);
  if (context === undefined) {
    throw new Error('useTable must be used within a TableContextProvider');
  }
  return context;
};
