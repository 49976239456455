import React from 'react';

export const AsideAggregationRisk = () => (
  <svg
    width="50"
    height="50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 11v5.2929L29.2929 18H20v9.2929L18.2929 29H13v6h6v-5.2929L20.7071 28h8.5858L31 29.7071V35h6v-6h-5.2929L30 27.2929v-8.5858L31.7071 17H37v-6h-6zm5 1h-4v4h4v-4zM21 27h8v-8h-8v8zm-3 3h-4v4h4v-4zm14 0h4v4h-4v-4z"
    />
    <path className="colored" d="M20 18h10v10H20z" />
  </svg>
);
